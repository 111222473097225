export default {
    methods: {
        async setupOutlet(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post('/outlet/setup', params)
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async outletDeleteCard(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post(
                    '/outlet/delete_card',
                    params
                )
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async outletChangePrintingStatus(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post(
                    '/outlet/change_printing_status',
                    params
                )
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async outletManageCardProviders() {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post(
                    '/outlet/manage_card_providers',
                    {
                        outlet_id: this.$route.query.outletId
                    }
                )
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async outletChangeCardProviderStatus(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post(
                    '/outlet/change_card_provider_status',
                    params
                )
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async outletManagePaymentGateway(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post(
                    '/outlet/manage_payment_gateway',
                    params
                )
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async outletChangePaymentMethodStatus(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post(
                    '/outlet/change_payment_method_status',
                    params
                )
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async outletDeletePaymentMethod(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post(
                    '/outlet/delete_payment_method',
                    params
                )
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async outletManagePrinters(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post(
                    '/outlet/manage_printers',
                    params
                )
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async outletChangePrinterStatus(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post(
                    '/outlet/change_printer_status',
                    params
                )
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async outletDeletePrinter(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post(
                    '/outlet/delete_printer',
                    params
                )
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async outletEdit(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post('/outlet/edit', params)
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async outletGetOutletFromId(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post(
                    '/outlet/get_outlet_from_id',
                    params
                )
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async outletGetPopupStoreDetails(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post(
                    '/outlet/get_popup_store_details',
                    params
                )
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async outletGetExistingAndNewVendorsOfPopupStore(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post(
                    '/outlet/get_existing_and_new_vendors_of_popup_store',
                    params
                )
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async outletGetPopupsAvailable(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post(
                    '/outlet/get_popups_available',
                    params
                )
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async outletSendRequestToPopupStore(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post(
                    '/outlet/send_request_to_popup_store',
                    params
                )
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async outletDeclineVendorRequestToPopup(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post(
                    '/outlet/decline_vendor_request_to_popup',
                    params
                )
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async outletAcceptVendorRequestToPopup(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post(
                    '/outlet/accept_vendor_request_to_popup',
                    params
                )
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async outletUnregisterVendorFromPopupStore(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post(
                    '/outlet/unregister_vendor_from_popup_store',
                    params
                )
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async networkSettings(params) {            
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post(
                    '/outlet/get_all_network_settings',
                    params
                )
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async getDeliveryByOrder(params) {            
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post(
                    '/order_type/get_all_delivery_companies_for_dropdown',
                    params
                )
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
    }
}
