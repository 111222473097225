import global from '../global.js'
export default {
    methods: {
        async getProductListing(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post(
                    '/mobile/get_products',
                    params
                )
                if (response.data.status_id == 1) {
                    resolve(response.data)
                    setTimeout(() => {
                        this.loadCategories = false
                    }, 500)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async orderHistory(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post(
                    'orders/order_history',
                    params
                )
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async orderHistoryDetails(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post(
                    'orders/order_history_details',
                    params
                )
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async getVendors() {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.get('orders/get_vendors')
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async getCheckout(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.get('orders/checkout', {
                        params
                    })
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error){
                    if(this.summary_loading){
                        this.$toasted.global.error("No Item Added")
                        this.summary_loading = false
                    }
                }
            })
        },
        async getItems(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.get(
                    'orders/get_items_for_order',
                    {
                        params
                    }
                )
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async getCart(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post('orders/cart', params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch (error) {
                    reject(error.response.data.reason)
                    // this.$toasted.global.error(sessionStorage.getItem("error"))
                }
            })
        },
        async getTables(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.get('orders/tables', {
                    params
                })
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async addItemToOrder(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post(
                    '/mobile/orders/items',
                    params
                )
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async addItemToOrderCustomize(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post(
                    '/mobile/orders/items/edit',
                    params
                )
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async orderCustomer(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('orders/customer', params)
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async orderRemoveTables(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post(
                    'orders/remove_tables',
                    params
                )
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async orderRemoveCustomer(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post(
                    'orders/remove_customer',
                    params
                )
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async orderDetails(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.get('orders', {
                    params
                })
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async orderUpdateTable(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post(
                    'orders/update_table',
                    params
                )
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async orderUpdateType(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('orders/type', params)
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async orderUpdateCustomer(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post(
                    'orders/update_customer_in_an_order',
                    params
                )
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async orderPax(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('orders/pax', params)
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async orderRemoveItem(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post(
                    'orders/remove_item',
                    params
                )
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async orderAddItemNote(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post(
                    'orders/add_item_note',
                    params
                )
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async orderAdditionalCharges(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post(
                    'orders/additional_charges',
                    params
                )
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async orderDiscount(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('orders/discount', params)
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async orderNote(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('orders/note', params)
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async orderPlace(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('orders/place', params)
                try {
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(err) {
                    this.$toasted.global.error(err.response.reason)
                }

            })
        },
        async orderMobilePlace(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post(global.POS_API + '/mobile/orders/place', params)
                try {
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(err) {
                    this.$toasted.global.error(err.response.reason)
                }

            })
        },
        async orderDiscard(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('orders/discard', params)
                try {
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(err) {
                    this.$toasted.global.error(err.response.reason)
                }
            })
        },
        async orderUpdateOrder(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post(
                    'orders/update_order',
                    params
                )
                try {
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(err) {
                    this.$toasted.global.error(err.response.reason)
                }
            })
        },
        async orderDiscardItems(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post(
                    'orders/discard_items',
                    params
                )
                try {
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(err) {
                    this.$toasted.global.error(err.response.reason)
                }
            })
        },
        async orderItemDiscount(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post(
                    'orders/items/discount',
                    params
                )
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async orderRoundOff(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('orders/round_off', params)
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async orderUpdateCustomerTaxCode(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post(
                    'orders/update_customer_tax_code',
                    params
                )
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async mobileGetOrderDetails(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post(
                    'mobile/_get_order_details',
                    params
                )
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async orderGetAllTables(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post(
                    '/orders/get_all_tables',
                    params
                )
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async orderTables(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('/orders/tables', params)
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async sendAllTogetherApiCall(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post(
                    '/orders/send_together',
                    params
                )
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async fireApiCall(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('/orders/fire', params)
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async orderOtherCharges(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post(
                    '/orders/other_charge',
                    params
                )
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async loyaltyDetailsApi(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post(
                    '/promotions/get_loyalty_details_for_customer',
                    params
                )
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async applyLoyaltyApi(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post(
                    '/promotions/apply_loyalty',
                    params
                )
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async applyDeposit(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post(
                    '/orders/customers/deposit',
                    params
                )
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async removeLoyaltyApi(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post(
                    '/promotions/remove_loyalty',
                    params
                )
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async getDeliveryCo(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('/orders/get_available_delivery_companies',params)
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async getInProgressOrder(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('/orders/get_inprogress_orders',params)
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async discardOrder(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('/orders/discard',params)
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async getDetailsById(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('/mobile/get_product_details',params)
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async getPendingOrder(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('/b2c/orders/pending/get',params)
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async getPendingSummary(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.get(global.POS_API +`/orders?orderId=${params.orderId}`, {
                    params
                })
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async orderTypeDropdowns(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post(global.POS_API_INVENTORY + '/order_type/get_all_order_types_for_dropdown',params)
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async orderDeliveryCompanies(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post(global.POS_API_INVENTORY + '/order_type/get_all_delivery_companies_for_dropdown',params)
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async updateOrderTypeDetails(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post(global.POS_API + '/orders/type/update',params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                        this.modal_msg = response.data.reason
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        async withdrawItemAndOrder(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post(global.POS_API + '/orders/request/withdraw',params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        async withdrawItemDelete(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post(global.POS_API + '/orders/request/withdraw',params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        async creditCardUsageActive(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post(global.POS_API + '/orders/payment/credit_card/update',params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        async HoldApiCall(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post(global.POS_API + '/orders/hold',params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        async getRefundDetails(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post(global.POS_API + '/orders/refund/details',params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        async saveRefundData(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post(global.POS_API + '/orders/refund',params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        async calculateRefund(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post(global.POS_API + '/orders/refund/calculate',params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        async getSecondaryMod(params){
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post("/orders/smodifiers", params)
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async getSelectedModifiersData(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post(global.POS_API + '/orders/smodifiers',params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        // New apis
        async getNewProductListing(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post(
                        '/mobile/get_product_hierarchy',
                        params
                    )
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                        setTimeout(() => {
                            this.loadCategories = false
                        }, 500)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(err) {
                    reject(err.response.data.reason)
                }
            })
        },
    }
}
