import global from "../global.js"
import axios from "axios"
export default {
    data(){
        return{
            customer_list: []
        }
    },
    methods: {
        async getProductsPurchasedAndNotPurchased(id) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.get(global.ADMIN_API + '/company/products/get?company_id='+id)
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data)
                }
            })
        }
    }
}