import global from "../global"
export default {
     methods: {
          async getApprovalList(params){
               return new Promise(async (resolve, reject) => {
                    let response = await this.$http.post('/orders/request/approval/list', params)
                    try{
                         if(response.data.status_id == 1){
                              resolve(response.data)
                         } else {
                              reject(response.data.reason)
                         }
                    } catch(error) {
                         console.log(error.response.data.reason)
                    }
               })
          },
          async approveRequest(params){
               return new Promise(async (resolve, reject) => {
                    let response = await this.$http.post('/orders/request/approve',params)
                    try{
                         if(response.data.status_id == 1){
                              resolve(response.data)
                         } else {
                              reject(response.data.reason)
                         }
                    } catch(error){
                         console.log(error.response.data.reason)
                    }
               })
          },
          async rejectRequest(params){
               return new Promise(async (resolve, reject) => {
                    let response = await this.$http.post('/orders/request/reject',params)
                    try{
                         if(response.data.status_id == 1){
                              resolve(response.data)
                         } else {
                              reject(response.data.reason)
                         }
                    } catch(error){
                         console.log(error.response.data.reason)
                    }
               })
          },
          async withdrawApprovalApi(params) {
               return new Promise(async (resolve, reject) => {
                    try{
                         let response = await this.$http.post(global.POS_API + '/orders/request/withdraw',params)
                         if (response.data.status_id == 1) {
                              resolve(response.data)
                         } else {
                              reject(response.data.reason)
                              this.warning_msg = response.data.reason
                              this.$refs.warning_modal.open();
                              setTimeout(() => {
                                   this.$refs.warning_modal.close();
                              }, 3000);
                         }
                    }
                    catch(error){
                         console.log(error)
                         this.warning_msg = error.response.data.reason
                         this.$refs.warning_modal.open();
                         setTimeout(() => {
                              this.$refs.warning_modal.close();
                         }, 3000);
                    }
               })
          }
     }
}