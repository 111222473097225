export default {
    methods:{
        async orderMoveItemsSummary(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('orders/items/move/summary',params)
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async orderMoveItems(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('orders/items/move', params)
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                    this.$toasted.global.error(response.data.reason);
                }
            })
        }
    }
}