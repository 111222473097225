<template>
      <div class="profile-left pr-1 pl-2">
        <div class="profile-image pl-3">
            <my-upload field="img"
                @crop-success="cropSuccess"
                v-model="show"
                :width="100"
                langType="en"
                :height="100"
                :params="params"
                :headers="headers"
                img-format="png"></my-upload>
        </div>
        <div class="mb-4 mt-4 profile-button">
            <a class="btn btn-secondary btn-smm" @click="toggleShow">
                Upload Picture
            </a>
        </div>
    </div>
</template>
<script>
import myUpload from 'vue-image-crop-upload';
import { EventBus } from './eventBus/nav-bar-event.js'
import axios from 'axios';

export default {
    data(){
        return{
            show: false,
            params: {
                token: '123456798',
                name: 'avatar'
            },
            headers: {
                smail: '*_~'
            },
            imgData: '', // the datebase64 url of created image
            isUploadingInProgress: false,
            attachment_file:"",
            check_attachments: false,
            attachments: {}
        }
    },
     components: {
        'my-upload': myUpload
    },
    methods: {
        toggleShow() {
            this.show = !this.show;
        },
        uploadAttachment(newFile) {
            try {
                var file = newFile;
                if (file) {
                    const CancelToken = axios.CancelToken;
                    const source = CancelToken.source();
                    let attachment = {
                        // id: uuid(),
                        name: file.name,
                        size: JSON.stringify(file.size),
                        source: source,
                        progress: 0,
                        created_time: new Date(),
                        error: ""
                    }
                    this.attachments = attachment;
                    this.getResumableURL(file, this.attachments);
                }
            } catch (error) {
                console.log(error);
            }
        },
        getResumableURL(file, attachment) {
            try {
                this.isUploadingInProgress = true;
                attachment.uploading = true;
                let data = {
                    file: attachment.name
                }
                data = new FormData()
                data.append('file',file)
                this.$http.post('https://cloudapis.digicollect.com/external/upload_file', data,{
                    onUploadProgress: function (progressEvent) {
                        let progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                        if (progress < 100) {
                            attachment.progress = progress;
                        }
                    }.bind(this)
                })
                .then(response => {
                    let resumable_url = response.data.url;
                    attachment.url = resumable_url;
                    attachment.progress = 100;
                    attachment.uploading = false;
                    this.isUploadingInProgress = false;
                    delete attachment['source'];
                    this.$emit('uploadImage', resumable_url);
                })
                .catch(error => {
                    console.log(error.data.reason)
                })
            } catch (error) {
                console.log(error);
            }
        },
        blobToFile(theBlob, fileName){
            //A Blob() is almost a File() - it's just missing the two properties below which we will add
            theBlob.lastModifiedDate = new Date();
            theBlob.name = fileName;
            const myFile = new File([theBlob], 'captured_image' + (new Date().getHours()  + '' + new Date().getMinutes()  + '' + new Date().getSeconds()) +'.png', {
                type: theBlob.type,
            });
            return myFile;
        },
        async cropSuccess(imgData, field){
            // this.imgData = imgData;
            const base64 = await fetch(imgData);
            const blob = await base64.blob();
            let fill = this.blobToFile(blob, 'captured_image' + (new Date().getHours()  + '' + new Date().getMinutes()  + '' + new Date().getSeconds()) +'.png');
            this.uploadAttachment(fill);
        },
        cropUploadSuccess(jsonData, field){
            // TODO:
        },
        cropUploadFail(status, field){
            // TODO:
        }
    }
}
</script>
