export default {
    methods: {
        async getDashboard(params) {
            return new Promise(async(resolve, reject) => {
                let response = await this.$http.post(
                    'dashboard/get_home_screen_data',
                    params
                )
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data)
                }
                // setTimeout(this.getDashboard, 60000)
            })
        },
        // ready() {
        //     this.getDashboard()
        // }
    }
}