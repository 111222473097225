<template>
    <div class="page-layout carded left-sidebar" style="overflow: auto !important;">
        <div class="page-content-wrapper">
            <div class="page-content">
                <el-container class="outlet-details-c-el-container pt-5" style="min-width: 768px;">
                    <el-aside class="contactSidebar-printer d-flex justify-content-center align-items-center">
                        <!-- <div class="header pt-4"> -->
                        <div class="d-flex flex-column">
                            <img src="/static/images/Digicollect-logo.svg" alt="">
                            <span class="digicollect-logo pt-3">Digicollect</span>
                        </div>
                        <!-- </div> -->
                    </el-aside>
                    <el-main>
                        <div class="pl-3 content-right pr-3">
                             <ul class="nav nav-tabs nav-device" id="myTab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" data-toggle="tab" href="#manage-printers" role="tab"
                                        aria-controls="manage-printers" aria-expanded="true">Manage Printer</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#payment-device" role="tab"
                                        aria-controls="payment-device">Payment Device</a>
                                </li>

                            </ul>
                            <div class="tab-content mt-6">
                                <div role="tabpanel" class="tab-pane fade active show bg-white py-3" id="manage-printers" style="margin: 0 1px;border-radius: 6px;"
                                    aria-labelledby="manage-printers">
                                    <table class="table border-collapse">
                                        <thead style="background-color: #00448b!important">
                                            <tr>
                                                <th class="text-center text-white">S.No</th>
                                                <th class="text-center text-white">Printer Name</th>
                                                <th class="text-center text-white">Category</th>
                                                <th class="text-center text-white">Receipts</th>
                                                <th class="text-center text-white">Invoice</th>
                                                <th class="text-center text-white">No. of Copies</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="ajaxCallInProgress">
                                            <tr>
                                                <td colspan="6" class="text-center justify-content-center">
                                                    <div class="digi-table-loader">
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody v-if="ajaxCallInProgress == false">
                                            <tr v-for="(printer, indx) in AllPrinters" :key="indx">
                                                <td class="text-center">{{indx+1}}</td>
                                                <td class="text-center">{{printer.printer_name}}</td>
                                                <td class="text-center" style="width:400px">
                                                    <div v-for="(group, group_index) in printer.category_ids" :key="group_index">
                                                        <div class="d-flex align-items-end">
                                                            <multiselect v-model="printer.category_ids[group_index]"
                                                                class="digi-multiselect category-tag category-multiple-addon mt-1"
                                                                open-direction="bottom" :max-height="200" label="name"
                                                                :loading="category_loading" placeholder="Search Printer Categories"
                                                                track-by="category_id" @open="fetchCategoryDropdown"
                                                                :options="FilteredCategories" :multiple="true"
                                                                @search-change="searchPrinterCategory">
                                                                <template slot="noOptions">
                                                                    <div class="text-secondary text-center fw-600">Printer
                                                                        Categories Not Found</div>
                                                                </template>
                                                                <template slot="noResult">
                                                                    <div class="text-secondary text-center fw-600">No Results Found
                                                                    </div>
                                                                </template>
                                                                <template slot="afterList">
                                                                    <div v-observe-visibility="reachedEndOfCategoryOptions" />
                                                                </template>
                                                            </multiselect>
                                                            <i class="icon icon-minus-circle color-danger pointer"
                                                                v-if="printer.category_ids.length > 1"
                                                                @click="RemoveGroup(printer.category_ids, printer.no_of_copies, group_index)"></i>
                                                            <i class="icon icon-plus-circle color-success pointer"
                                                                v-if="printer.category_ids.length == group_index+1"
                                                                @click="AddGroup(printer.category_ids, printer.no_of_copies)"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <el-checkbox class="printer-checkbox" v-model="printer.receipt" @change="SelectReceiptInvoice('receipt',indx)">
                                                    </el-checkbox>
                                                </td>
                                                <td class="text-center">
                                                    <el-checkbox class="printer-checkbox" v-model="printer.invoice" @change="SelectReceiptInvoice('invoice',indx)">
                                                    </el-checkbox>
                                                </td>
                                                <td class="text-center">
                                                    <div style="height:38px" class="d-flex justify-content-center align-items-end" v-for="(copy, copy_index) in printer.no_of_copies" :key="copy_index">
                                                        <input type="number" onkeypress='return event.charCode >= 48 && event.charCode <= 57' v-model="printer.no_of_copies[copy_index]"
                                                            placeholder="Enter No. of Copies" class="copies-field">
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="d-flex justify-content-center align-items-center pb-2 mt-3">
                                        <button class="btn btn-success text-white text-capitalize pl-10 pr-10"
                                            style="border-radius: 20px !important" :disabled="update_printer_config_api_call" @click="UpdatePrinterConfig">
                                            Save
                                            <half-circle-spinner :animation-duration="1000" :size="20" v-if="update_printer_config_api_call" color="#00448b" style="position: absolute;top:8px;right:5px" />
                                        </button>
                                    </div>
                                </div>
                                <div role="tabpanel" class="tab-pane fade" id="payment-device" aria-labelledby="payment-device">
                                    <div class="panel panel-inverse">
                                        <div class="panel-heading d-flex align-items-center justify-content-between py-1 flex-wrap" v-if="current_url == 'https://pos.digicollect.com'">
                                            <div class="py-2">
                                                Configure Payment Device
                                                <el-switch class="switchcolor pl-3" :disabled="true" id="configure" 
                                                    @change="configure_payment()" v-model="configure_pay" active-color="#fff">
                                                </el-switch>
                                            </div>
                                            <div class="py-2">
                                                Request for Configuration
                                                <button class="btn btn-success btn-smm ml-2 text-white" type="button" @click="configureRequest()">
                                                    Request
                                                </button>
                                            </div>
                                        </div>
                                        <div class="panel-heading" v-else>
                                            Configure Payment Device
                                            <el-switch class="switchcolor pl-3" id="configure" 
                                                @change="configure_payment()" v-model="configure_pay" active-color="#fff">
                                            </el-switch>
                                        </div>
                                        <div class="panel-body mt-3" v-if="merchantId">
                                            <div class="row mb-5px mt-3">
                                                <label class="form-label col-form-label-new col-md-2 text-right">Merchant ID</label>
                                                <div class="col-md-5">
                                                    <input type="text" class="form-control-border mb-5px" placeholder="Enter Merchant ID" v-model="merchant_id" name="merchant_id" v-validate="'required'" @input="showdata(merchant_id)">
                                                    <!-- <small class="fs-12px text-gray-500-darker">Validation</small> -->
                                                </div>

                                                <div class="col-md-5 pr-10">
                                                    <button class="btn btn-success btn-normal float-right" :disabled="manage_printer_save_api" @click="merchantData">
                                                        Save
                                                        <half-circle-spinner :animation-duration="1000" :size="20" v-if="manage_printer_save_api" color="#00448b" style="position: absolute;top:6px;right:2px" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="col-md-7">
                                                <span class="float-right text-danger mb-3"  v-show="errors.has('merchant_id')">Merchant id is required</span>
                                            </div> 
                                        </div>
                                        <div class="panel-body mt-3" v-if="payList">
                                            <div class="row">
                                                <div class="col-md-12 mt-3 mr-3">
                                                    <div class="fs-16 text-black mb-3">Merchant ID: {{merchant_id}}</div>
                                                    <table class="table border-collapse" v-if="payList">
                                                        <thead style="background-color: #00448b!important">
                                                            <tr>
                                                                <th class="text-center text-white">S.No</th>
                                                                <th class="text-center text-white">Device Name</th>
                                                                <th class="text-center text-white">Device ID</th>
                                                                <th class="text-center text-white">Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody v-if="ajaxCallInProgress">
                                                            <tr>
                                                                <td colspan="4" class="text-center justify-content-center">
                                                                    <div class="digi-table-loader">
                                                                        <div></div>
                                                                        <div></div>
                                                                        <div></div>
                                                                        <div></div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        <tbody v-if="AllDevices.length==0" style="height:100%;">
                                                            <tr>
                                                                <td colspan="4" class="text-center justify-content-center">
                                                                    <strong>No Devices</strong>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        <tbody v-else>
                                                            <tr v-for="(device, index) in AllDevices" :key="index">
                                                                <td class="text-center">{{index+1}}</td>
                                                                <td class="text-center">{{device.device_name}}</td>
                                                                <td class="text-center">{{device.device_id}}</td>
                                                                <td class="text-center">{{device.status}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-main>
                </el-container>
            </div>
        </div>
        <sweet-modal ref="success_modal" icon="success">{{ modal_msg }}</sweet-modal>
        <sweet-modal ref="warning_modal" icon="warning">{{ modal_msg }}</sweet-modal>
    </div>
</template>

<script>
    import {
        SweetModal
    } from 'sweet-modal-vue'
    import printerAPI from './mixins/printerAPI'
    import paymentDeviceAPI from './mixins/paymentDeviceAPI'
    import { EventBus } from '../components/eventBus/nav-bar-event.js'
    import global from './global';
    import { HalfCircleSpinner } from "epic-spinners";
    export default {
        mixins: [printerAPI,paymentDeviceAPI],
        data() {
            return {
                category_options: [],
                AllPrinters: [],
                gathered_ids: [],
                modal_msg: '',
                ajaxCallInProgress: false,
                counter: 0,
                category_reached: false,
                category_scrollable: false,
                category_loading: false,
                category_count: 0,
                temp_category: [],
                search_printer_key: '',
                configure_payment_device: false,
                merchantId: false,
                AllDevices: [],
                payList:false,
                merchant_data:false,
                merchant_id:'',
                configure_pay:false,
                current_url: '',
                manage_printer_save_api: false,
                update_printer_config_api_call: false
            }
        },
        components: {
            SweetModal,
            HalfCircleSpinner
        },
        methods:{
            showdata(val){
                console.log(val)
                if(val == ''){
                    this.payList = false
                }
            },
            configure_payment() {
                if (this.configure_pay) {
                    this.merchantId =  true
                    this.payList = false
                } else {
                    this.merchantId =  false;
                    this.payList = false;
                    this.merchant_id =''
                    this.merchantData()
                }
            },
            configureRequest() {
                this.$http.get(global.POS_API+"/orders/payment/configuration").then(res =>{
                    this.modal_msg = res.data.response;
                    this.$refs.success_modal.open();
                    setTimeout(() => {
                        this.$refs.success_modal.close();
                    }, 1500);
                    if(res.data.status_id) {
                        
                    } else {
                        
                    }
                }).catch((error) => {
                    console.log(error.response.data.reason)
                    this.modal_msg = error.response.data.reason;
                    this.$refs.warning_modal.open();
                    setTimeout(() => this.$refs.warning_modal.close(), 2000);
                });
            },
            SelectReceiptInvoice(type, index){
                if(type == 'receipt'){
                    this.AllPrinters.forEach((el, indx) => {
                        if(index != indx){
                            el.receipt = false
                        }
                    })
                } else if(type == 'invoice'){
                    this.AllPrinters.forEach((el, indx) => {
                        if(index != indx){
                            el.invoice = false
                        }
                    })
                }
            },
            async searchPrinterCategory(category) {
                this.search_printer_key = category
                let params = {
                    search_key: this.search_printer_key,
                    skip: 0,
                    limit: 50,
                    outlet_id: this.loggedInUser.outlet_id
                }
                let response = await this.PrinterDropdown(params)
                this.category_options = response.response
                this.category_options = this.category_options.filter(v => !(this.gathered_ids.some(e => e
                    .category_id === v.category_id)))
                const find_dup_categories = this.category_options.map(o => o.name)
                const filteredupcategory = this.category_options.filter(({name}, index) => !find_dup_categories.includes(name, index + 1))
                this.category_options = filteredupcategory
                this.category_count = this.category_options.length
                this.category_scrollable = false,
                    this.category_loading = false
            },
            async reachedEndOfCategoryOptions(reached) {
                console.log(reached)
                // this.category_reached = reached
                if (reached) {
                    if (this.category_scrollable == false) {
                        this.category_loading = true
                        let response = await this.PrinterDropdown({
                            skip: this.category_count,
                            limit: 50,
                            search_key: this.search_printer_key,
                            outlet_id: this.loggedInUser.outlet_id
                        })
                        this.temp_category = response.response.length
                        if (response.skip == this.category_count) {
                            if (this.temp_category > 0) {
                                let tempFiles = response.response;
                                tempFiles = tempFiles.filter(v => !(this.gathered_ids.some(e => e
                                    .category_id === v.category_id)))
                                tempFiles.forEach(el => {
                                    this.category_options.push(el)
                                });
                                const find_dup_categories = this.category_options.map(o => o.name)
                                const filteredupcategory = this.category_options.filter(({name}, index) => !find_dup_categories.includes(name, index + 1))
                                this.category_options = filteredupcategory
                                this.category_count += response.response.length;
                                this.category_loading = false
                            } else {
                                this.category_scrollable = true
                                this.category_loading = false
                            }
                        }
                        this.category_loading = false
                    }
                }
            },
            async printersList() {
                this.ajaxCallInProgress = true
                try {
                    let params = {
                        outlet_id: this.loggedInUser.outlet_id,
                        printer_selection: true
                    }
                    let response = await this.managePrinters(params)
                    this.AllPrinters = response.response.all_printers
                    this.ajaxCallInProgress = false
                    this.AllPrinters.forEach((p, i) => {
                        if (p.category_ids.length == 0) {
                            p.category_ids.push([])
                        }
                        if (p.no_of_copies.length == 0) {
                             p.no_of_copies.push(0)
                        }
                    })
                } catch (error) {
                    console.log(error)
                }
            },
            async merchantData() {
                this.$validator.validateAll().then(async result => {
                    if (result == true && this.configure_pay) {
                        this.manage_printer_save_api = true;
                        let params = {
                            merchant_id: this.merchant_id,
                            configure_payment_device: this.configure_pay
                        }
                        let response;
                        try {
                            response = await this.getMerchantData(params)
                            if(response.status_id == 1){
                                this.modal_msg = response.message;
                                this.$refs.success_modal.open();
                                setTimeout(() => {
                                    this.$refs.success_modal.close();
                                }, 1500);
                                setTimeout(() => {
                                    this.deviceList();
                                }, 1800);   
                                EventBus.$emit('update_user_pin_settings')
                            }
                            this.manage_printer_save_api = false;
                        } catch (error) {
                            console.log(error)
                            this.modal_msg = response.reason;
                            setTimeout(() => {
                                this.$refs.warning_modal.open();
                            }, 1000);
                            this.manage_printer_save_api = false;
                        }
                    } else {
                        this.manage_printer_save_api = true;
                        let params = {
                            merchant_id: '',
                            configure_payment_device: this.configure_pay
                        }
                        let response;
                        try {
                            response = await this.getMerchantData(params)
                            if(response.status_id == 1){
                                this.modal_msg = response.message;
                                this.$refs.success_modal.open();
                                setTimeout(() => {
                                    this.$refs.success_modal.close();
                                }, 1500); 
                                EventBus.$emit('update_user_pin_settings') 
                            }
                            this.manage_printer_save_api = false;
                        } catch (error) {
                            console.log(error)
                            this.modal_msg = response.reason;
                            setTimeout(() => {
                                this.$refs.warning_modal.open();
                            }, 1000);
                            this.manage_printer_save_api = false;
                        }
                    
                    }
                });
            },
            async deviceList() {
                this.payList = true;
                this.ajaxCallInProgress = true
                try {
                    let response = await this.managePaymentDevice()
                    this.merchant_id = response.merchant_id
                    if(this.merchant_id){
                        this.configure_pay = true
                        this.AllDevices = response.devices_list
                    } else {
                        this.configure_pay = false
                        this.merchantId =  false;
                        this.payList = false;
                    }   
                    this.ajaxCallInProgress = false
                } catch (error) {
                    console.log(error)
                    this.ajaxCallInProgress = false
                }
            },
            async UpdatePrinterConfig() {
                localStorage.setItem('printer_data', JSON.stringify(this.createJson));
                var printer_data = JSON.parse(localStorage.getItem('printer_data'));
                printer_data.all_printers.forEach((print, print_index) => {
                    print.category_ids.forEach((category, category_index) => {
                        category.forEach((c, ci) => {
                            category.push(c.category_id)
                        })
                    })
                    delete print.printer_name
                })
                printer_data.all_printers.forEach((print, print_index) => {
                    print.category_ids.forEach((category, category_index) => {
                        category.splice(0, Math.abs(category.length) / 2)
                    })
                })

                // Removal of duplicate fields

                // printer_data.all_printers.forEach((print, print_index) => {
                //     this.counter = 0
                //     print.category_ids.forEach((category, category_index) => {
                //         if(category.length == 0) {
                //             print.no_of_copies.splice(category_index-this.counter, 1)
                //             this.counter += 1
                //         }
                //     })
                // })
                // printer_data.all_printers.forEach((print, print_index) => {
                //     print.category_ids = print.category_ids.filter((el) => el != el.length)
                // })
                // console.log(printer_data.all_printers)
                this.callUpdatePrinterConfigAPI(printer_data)
            },
            async callUpdatePrinterConfigAPI(printer_data) {
                this.update_printer_config_api_call = true;
                var response = await this.UpdatePrinter(printer_data)
                if(response.status_id == 1){
                    this.modal_msg = response.message
                    this.$refs.success_modal.open();
                    setTimeout(() => {
                        this.$refs.success_modal.close();
                        this.printersList()
                        this.update_printer_config_api_call = false;
                    }, 2000)
                } else if(response.status_id == 0) {
                    this.modal_msg = 'Please Enter All The Fields'
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                        this.printersList()
                        this.update_printer_config_api_call = false;
                    }, 2000)
                }
            },
            async fetchCategoryDropdown() {
                this.gathered_ids = []
                this.category_options = []
                try {
                    let params = {
                        skip: 0,
                        limit: 50,
                        search_key: "",
                        outlet_id: this.loggedInUser.outlet_id
                    }
                    let response = await this.PrinterDropdown(params)
                    this.category_options = response.response
                    const find_dup_categories = this.category_options.map(o => o.name)
                    const filteredupcategory = this.category_options.filter(({name}, index) => !find_dup_categories.includes(name, index + 1))
                    this.category_options = filteredupcategory
                    this.category_count = response.response.length
                    // this.AllPrinters.forEach((p, i) => {
                    //     p.category_ids.forEach((c, j) => {
                    //         c.forEach((g, k) => {
                    //             this.gathered_ids.push(g)
                    //         })
                    //     })
                    // })
                    // this.category_options = this.category_options.filter(v => !(this.gathered_ids.some(
                    //     e => e.category_id === v.category_id)))
                } catch (error) {
                    console.log(error)
                }
            },
            AddGroup(arr, copies) {
                arr.push([])
                copies.push([])
            },
            RemoveGroup(arr, copies, index) {
                arr.splice(index, 1)
                copies.splice(index, 1)
            }
        },
        computed: {
            loggedInUser() {
                return this.$store.getters.loggedInUser;
            },
            createJson() {
                return {
                    all_printers: this.AllPrinters
                }
            },
            FilteredCategories: function () {
                this.AllPrinters.forEach((p, i) => {
                    p.category_ids.forEach((c, j) => {
                        c.forEach((g, k) => {
                            this.gathered_ids.push(g)
                        })
                    })
                })
                const find_dup_categories = this.category_options.map(o => o.name)
                const filteredupcategory = this.category_options.filter(({name}, index) => !find_dup_categories.includes(name, index + 1))
                this.category_options = filteredupcategory
                return this.category_options = this.category_options.filter(v => !(this.gathered_ids.some(
                        e => e.category_id === v.category_id)))
            }
        },
        mounted() {
            this.printersList()
            this.deviceList()
            this.current_url = global.POS_URL
            console.log(this.current_url)
        }
    }
</script>

<style scoped>
    @media (max-width: 780px) {
        .outlet-details-c-el-container .el-aside.contactSidebar {
            width: 250px !important;
            overflow: hidden !important;
        }

        .other-settings-other .res-settings {
            overflow: hidden !important;
        }
    }

    .contactSidebar-printer {
        background-image: linear-gradient(to bottom, #bad2f5, #fefeff 64%, #cee1f5);
        background-size: cover;
        background-repeat: no-repeat;
        height: 500px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
    }

    .digicollect-logo {
        font-family: "Lato", "Helvetica Neue", sans-serif !important;
        font-size: 20px !important;
    }

    .content-right {
        min-height: 500px;
        max-height: 500px;
        overflow-y: auto !important;
    }

    .page-layout.carded {
        border-radius: 0px !important;
    }

    table tr:first-child th:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    table tr:first-child th:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .table thead th {
        border-bottom: none !important;
    }


    .color-success{
        color: #008a63;
    }
    .color-danger{
        color: #d6363d;
    }
    .copies-field {
        width: 130px;
        border: 1px solid #c6c7c9 !important
    }

    .copies-field::placeholder {
        color: #4d4f5c !important;
    }

    .category-tag::placeholder {
        color: #4d4f5c !important;
    }

    @media screen and (max-width: 720px) {
        .copies-field {
            width: 60px
        }
    }
    .nav-tabs.nav-device {
        border-bottom: 1px solid #7eaef5;
        padding-left: 14px;
        padding-right: 14px;
    }

    .nav-tabs.nav-device .nav-item {
        margin-bottom: -2px;
    }

    .nav-tabs.nav-device .nav-link.active {
        box-shadow: 1px -1px 2px 0 rgba(0, 0, 0, 0.16) !important;
        background: #00448b !important;
        color: #fff !important;
        border-bottom: 1px solid transparent;
        font-weight: 600;
    }

    .nav-tabs.nav-device .nav-link {
        height: 35px !important;
        border: 1px solid #08448b;
        background-color: #fff;
        color: #00448b;
        margin-right: 10px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        margin-bottom: 1px;
        border-bottom-color: #7eaef4;
    }
    .nav-tabs.nav-device .nav-link.active:not(.dropdown-toggle):after,
    .nav-tabs.nav-device .nav-item.show .nav-link:not(.dropdown-toggle):after {
        content: '';
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        height: 2px;
        background-color: transparent;
    }
    .panel {
        background-color: transparent;
        border: none;
        margin-bottom: 1.246875rem;
        box-shadow: none;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
    }
    .panel .panel-heading {
        padding: 1.3rem 0.9375rem;
        border: none;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        border-radius: 4px;
    }
    .panel.panel-inverse>.panel-heading {
        background: #005dad;
        color: #fff;
        font-size: 15px;
        font-weight: 600;
        padding-left: 30px !important;
    }
    .panel .panel-heading .panel-title {
        font-size: 15px;
        margin: 0;
        line-height: 1.35;
        flex: 1;
        font-weight: 600;
    }
    .panel .panel-body {
        padding: 0.9375rem 0.9375rem;
        flex: 1 1 auto;
        background-color: #fff;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
    }
    .panel .panel-body:after, .panel .panel-body:before {
        content: "";
        display: table;
        clear: both;
    }
    .bg-white-color {
        width: 225px;
        text-align: center;
        align-items: center;
        justify-content: center;
        background: #fff;
        border-radius: 50%;
        height: 225px;
    }
    .bg-white-color img {
        width: 125px;
    }
    .el-switch.switchcolor.is-checked .el-switch__core {
        border-color: #ffffff !important;
        background-color: #ffffff !important;
    }

    .el-switch.switchcolor .el-switch__core:after {
        background-color: #fff !important;
    }
    .el-switch.switchcolor.is-checked .el-switch__core::after {
        background-color: #005dad !important;
    }

    .col-form-label-new {
        padding-top: calc(0.4375rem + 1px);
        padding-bottom: calc(0.4375rem + 1px);
        margin-bottom: 0;
        font-size: 15px;
        font-weight: 500 !important;
        line-height: 1.5;
        display: inline-block;
        color: #303031;
        padding-left: 35px;
    }
    .form-control-border {
        display: block;
        width: 100%;
        padding: 0.4375rem 0.75rem;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.5;
        color: #303031;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 4px;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    .mb-5px {
        margin-bottom: 5px!important;
    }
    .mb-15px {
        margin-bottom: 15px!important;
    }
    .btn-normal {
        height: 32px !important;
        padding: 0 22px !important;
        border-radius: 18px !important;
        font-size: 14px !important;
        line-height: 32px !important;
        text-transform: none;
        font-weight: 600 !important;
        color: #fff !important;
    }
</style>