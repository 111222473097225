<template>
    <div>
        <modal :name="modal_name" class="final-modal popup-modal-all-width-height" transition="nice-modal-fade" :class="{'modalZindex':safariTrue}">
            <div class="v-modal-content">
                <div class="v-modal-header">
                    <span class="v-modal-dialog-title v-modal-title">PAYMENT METHOD</span>
                    <span @click="hidePaymentMethod()">
                        <i class="icon icon-close pointer text-white"></i>
                    </span>
                </div>
                <div class="general-section" style="padding: 15px 0px;">
                <div class="pl-0 pr-0 pt-0">
                    <div class="d-form">
                        <div class="d-flex">
                        <div class="w-100 row no-gutters justify-content-between align-items-end grandTotal p-2 font-weight-bold">
                            <div class="pl-2">Grand Total</div>
                            <div class="mr-2 bg-white text-secondary grandTotalValue">
                            {{ summary.grand_total_after_deposit }}
                            </div>
                        </div>
                        <div v-if="splitBill" class="row no-gutters justify-content-between align-items-end grandRemanining p-2 font-weight-bold w-100">
                            <div class="pl-3">Remaining</div>
                            <!-- <div class="pr-3 bg-white text-secondary grandTotalValue" v-if="splitEqually == false">{{summary.grand_total}}</div> -->
                            <div class="pr-3 bg-white text-secondary grandTotalValue">{{summary.currency.symbol}}{{ (change_remaining || 0).toFixed(2) }}</div>
                        </div>
                        </div>
                        <div >
                        <div class="d-flex flex-wrap mt-2">
                            <div class="col-lg-12 col-md-12 colm-sm-12">
                            <div class="d-flex justify-content-between borderBottom pb-2 mt-2">
                                <span class="font-600">Customer</span>
                                <span class="text-secondary">{{summary.customer_name}}</span>
                            </div>
                            </div>
                            <!-- <div class="col-lg-6 col-md-6 colm-sm-6">
                            <div class="d-flex justify-content-between borderBottom pb-2 mt-2">
                                <span class>Table Number</span>
                                <span class="text-secondary">{{summary.tables[0]}}</span>
                            </div>
                            </div> -->
                        </div>
                        <!-- <div class="d-flex flex-wrap mt-2">
                            <div class="col-lg-6 col-md-6 colm-sm-6">
                            <div class="d-flex justify-content-between borderBottom pb-2 mt-2">
                                <span>Order Time</span>
                                <span class="text-secondary">17:00</span>
                            </div>
                            </div>
                            <div class="col-lg-6 col-md-6 colm-sm-6">
                            <div class="d-flex justify-content-between borderBottom pb-2 mt-2">
                                <span>Order ID</span>
                                <span class="text-secondary">{{summary.order_number}}</span>
                            </div>
                            </div>
                        </div> -->
                        <div v-if="!splitBill">
                            <div class="d-flex flex-wrap" v-if="Number(summary.grand_total_after_deposit_unformatted) > 0">
                            <div class="pt-3 col-lg-8 col-md-8 col-sm-6">
                                <span class="text-secondary mt-1 fs-14 font-600">Payment Mode</span>
                                <div class="mt-3">
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label">
                                    <input class="form-check-input" @change="changePaymentMode()" :disabled="payment_type_by_credit_card" v-model="summary.payments[0].mode" type="radio" value="CASH" required />
                                    <span class="radio-icon"></span>
                                    <span class="form-check-description font-600">Cash</span>
                                    </label>
                                </div>
                                <div class="form-check form-check-inline ml-2">
                                    <label class="form-check-label">
                                    <input class="form-check-input" @change="changePaymentMode()" v-model="summary.payments[0].mode" type="radio" value="CARD" required />
                                    <span class="radio-icon"></span>
                                    <span class="form-check-description font-600">Card</span>
                                    </label>
                                </div>
                                <!-- <div class="form-check form-check-inline ml-2">
                                    <label class="form-check-label">
                                    <input class="form-check-input" @change="changePaymentMode()" v-model="summary.payments[0].mode" type="radio" value="ACH" required />
                                    <span class="radio-icon"></span>
                                    <span class="form-check-description font-600">ACH</span>
                                    </label>
                                </div> -->
                                <div class="form-check form-check-inline ml-2">
                                    <label class="form-check-label">
                                    <input class="form-check-input" @change="changePaymentMode()" :disabled="payment_type_by_credit_card" v-model="summary.payments[0].mode" type="radio" value="OTHERS" required />
                                    <span class="radio-icon"></span>
                                    <span class="form-check-description font-600">Others</span>
                                    </label>
                                </div>
                                <div v-if="loggedInUser.configure_payment_device == true" class="form-check form-check-inline ml-2">
                                    <label class="form-check-label">
                                        <input class="form-check-input" @change="changePaymentMode()" :disabled="payment_type_by_credit_card" v-model="summary.payments[0].mode" type="radio" value="SCANPAY" required />
                                        <span class="radio-icon"></span>
                                        <span class="form-check-description font-600">Scan & Pay</span>
                                    </label>
                                    <button @click="openQrCode" v-if="summary.payments[0].mode == 'SCANPAY'" class="btn btn-secondary btn-smm ml-3">
                                        Open QR Code
                                    </button>
                                </div>
                                </div>
                            </div>
                            <div v-if="Number(summary.grand_total_after_deposit_unformatted) > 0" class="p-5 col-lg-4 col-md-4 col-sm-6" >
                                <img @click="showSplitbill()" class="pointer" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/splitbill.svg" :class="{'disable-state' :  (summary.payments[0].mode == 'CARD' && loggedInUser.configure_payment_device == true) || summary.payments[0].mode == 'SCANPAY'}"/>
                            </div>
                            </div>
                            <hr class="horizontalLine mt-2">
                            <div v-if="Number(summary.grand_total_after_deposit_unformatted) > 0">
                            <div v-if="summary.payments[0].mode == 'CASH'">
                                <span class="text-secondary mt-3 d-block ml-4 fs-14 font-600">Cash Tendered Details</span>
                                <div class="d-flex flex-wrap">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <div class="mt-4 contactGroup">
                                    <span class="fs-14 font-600">Total Cash Tendered</span>
                                    <input id="cash_tendered" maxlength="15" oncopy="return false" onpaste="return false" v-on:keypress="isNumber($event)" class="inputContact pt-3" v-model.number="cash_tendered" type="text" />
                                    <span class="highlightContact"></span>
                                    <span class="barContact"></span>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <div class="mt-4 contactGroup">
                                    <span class="fs-14 font-600">Change Remaining</span>
                                    <input id="change_remaining" class="inputContact pt-3" :value="formatPrice(cash_remaining)" readonly type="text" />
                                    <span class="highlightContact"></span>
                                    <span class="barContact"></span>
                                    </div>
                                </div>
                                </div>
                            </div>
                            
                            <div v-if="summary.payments[0].mode == 'CARD' && !loggedInUser.configure_payment_device">
                                <span class="text-secondary mt-3 d-block ml-4 fs-14 font-600">Card Details</span>
                                <div class="mt-2" v-for="(payment, index) in summary.payments" :key="index">
                                <div>
                                    <div class="d-flex flex-wrap">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="form-group pt-1 contactGroup">
                                        <span class="fs-14 font-600">Issuing Bank<sup class="text-danger">★</sup></span>
                                        <input class="inputContact pt-2" :name="'bank_name'+index.toString()" data-vv-as="Bank Name" v-validate="{required:true,regex:/^[A-Za-z0-9_ ]+$/}" type="text" v-model="payment.bank_name" required />
                                        <span class="highlightContact"></span>
                                        <span class="barContact"></span>
                                        </div>
                                        <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('bank_name'+index.toString())">{{ errors.first("bank_name"+index.toString())}}</span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="fs-14">Card Provider<sup class="text-danger">★</sup></label>
                                        <v-select v-validate="'required'" label="name" placeholder="Please Select Card Provider" class="select-width" :name="'card_provider'+index.toString()" v-model="payment.card_provider" :filterable="true" :options="cardDetails">
                                        <span slot="no-options">
                                            No matches Found. Please Create New Card
                                            <!-- <a class="text-secondary p-0 m-0 w-0" style="text-decoration:none;text-transform: none;min-width: 0px;height: 2rem;line-height: 2rem;" @click="showbrandproduct()">Here</a> -->
                                        </span>
                                        </v-select>
                                        <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('card_provider'+index.toString())">Card Provider is Required</span>
                                    </div>
                                    </div>
                                    <div class="d-flex flex-wrap mt-3">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <span class="fs-14 font-600">Card Type<sup class="text-danger">★</sup></span>
                                        <div class="mt-3">
                                        <div class="form-check form-check-inline">
                                            <label class="form-check-label">
                                            <input class="form-check-input" type="radio" v-validate="'required'" :name="'card_type'+index.toString()" v-model="payment.card_type" value="CREDIT CARD" required />
                                            <span class="radio-icon"></span>
                                            <span class="form-check-description">Credit Card</span>
                                            </label>
                                        </div>
                                        <div class="form-check form-check-inline ml-2">
                                            <label class="form-check-label">
                                            <input class="form-check-input" type="radio" :disabled="payment_type_by_credit_card" v-validate="'required'" :name="'card_type'+index.toString()" v-model="payment.card_type" value="DEBIT CARD" required />
                                            <span class="radio-icon"></span>
                                            <span class="form-check-description font-600">Debit Card</span>
                                            </label>
                                        </div>
                                        <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('card_type'+index.toString())">Card Type is Required</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="form-group pt-1 contactGroup">
                                        <span class="fs-14 font-600">Name On Card<sup class="text-danger">★</sup></span>
                                        <input class="inputContact pt-2" v-validate="{required:true,regex:/^[A-Za-z0-9_ ]+$/}" type="text" data-vv-as="Name on Card" :name="'name_on_card'+index.toString()" v-model="payment.name_on_card" required />
                                        <span class="highlightContact"></span>
                                        <span class="barContact"></span>
                                        </div>
                                        <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('name_on_card'+index.toString())">{{ errors.first("name_on_card"+index.toString())}}</span>
                                    </div>
                                    </div>
                                    <div class="d-flex flex-wrap mt-4">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <span class="fs-14 font-600">Card Number<sup class="text-danger">★</sup></span>
                                        <div class="row">
                                            <div class="col-md-8">
                                                <div class="contactGroup">
                                                    <input class="inputContact" value="XXXX XXXX XXXX" required  readonly/>
                                                    <span class="highlightContact"></span>
                                                    <span class="barContact"></span>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="contactGroup">
                                                    <input class="inputContact" autocomplete="off" :name="'card_number'+index.toString()" data-vv-as="Card Number" maxlength="4" type="number" v-validate="{required:true,max:4,min:4,regex:/^[0-9]*$/}" v-model="payment.card_number" required />
                                                    <span class="highlightContact"></span>
                                                    <span class="barContact"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('card_number'+index.toString())">{{ errors.first("card_number"+index.toString())}}</span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="contactGroup">
                                        <span class="fs-14 font-600">Transaction ID</span>
                                        <input class="inputContact pt-4" v-model="payment.transaction_id" type="text" required />
                                        <span class="highlightContact"></span>
                                        <span class="barContact"></span>
                                        </div>
                                    </div>
                                    </div>
                                    <div class="d-flex flex-wrap mt-3">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <div class="mt-2 contactGroup">
                                                <span class="fs-14 font-600">Approval Code</span>
                                                <input class="inputContact pt-3" v-model="payment.approval_code" type="text" />
                                                <span class="highlightContact"></span>
                                                <span class="barContact"></span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                </div>
                            </div>
                            <div v-else-if="summary.payments[0].mode == 'CARD' && loggedInUser.configure_payment_device">
                               
                                <div class="mt-2" v-for="(payment, index) in summary.payments" :key="index">
                                    <div class="d-flex flex-wrap mt-3">
                                        <div class="col-lg-6 col-md-6 col-sm-6" >
                                            <span class="fs-14 font-600">Card Type<sup class="text-danger">★</sup></span>
                                            <div class="mt-3">
                                            <div class="form-check form-check-inline">
                                                <label class="form-check-label">
                                                <input class="form-check-input" type="radio" v-validate="'required'" @change="changeCardType($event, payment.card_type)" :name="'card_type'+index.toString()" v-model="payment.card_type" value="CREDIT CARD" required />
                                                <span class="radio-icon"></span>
                                                <span class="form-check-description">Credit Card</span>
                                                </label>
                                            </div>
                                            <div class="form-check form-check-inline ml-2">
                                                <label class="form-check-label">
                                                <input class="form-check-input" type="radio" :disabled="payment_type_by_credit_card" @change="changeCardType($event, payment.card_type)" v-validate="'required'" :name="'card_type'+index.toString()" v-model="payment.card_type" value="DEBIT CARD" required />
                                                <span class="radio-icon"></span>
                                                <span class="form-check-description font-600">Debit Card</span>
                                                </label>
                                            </div>
                                            <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('card_type'+index.toString())">Card Type is Required</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6" >
                                            <span class="fs-14 font-600">Payment Device Name</span>
                                            <div class="mt-1">
                                                <multiselect v-model="pay_device" v-validate="'required'" name="pay_device"
                                                    class="digi-multiselect category-tag category-multiple-addon mt-1"
                                                    :disabled="payment.card_type == '' || payment.card_type == null || payment.card_type == undefined"
                                                    open-direction="bottom" :max-height="200" label="device_name"
                                                    :loading="pay_device_loading" placeholder="Select Payment Device Name"
                                                    track-by="device_id" @open="fetchPayDevices(payment.card_type)"
                                                    :options="pay_devices_options" :multiple="false">
                                                    <template slot="singleLabel" slot-scope="props">
                                                        <span class="option__desc">
                                                            <span class="option__title">{{ props.option.device_name + '(' + props.option.device_id.slice(-4) + ')' }}</span>
                                                        </span>
                                                    </template>
                                                    <template slot="option" slot-scope="props">
                                                        <div class="option__desc">
                                                            <span class="option__title">{{ props.option.device_name + '(' + props.option.device_id.slice(-4) + ')' }}</span>
                                                        </div>
                                                    </template>
                                                    <template slot="noOptions">
                                                        <div class="text-secondary text-center fw-600">Payment Devices Not Found</div>
                                                    </template>
                                                    <template slot="noResult">
                                                        <div class="text-secondary text-center fw-600">No Results Found
                                                        </div>
                                                    </template>
                                                    
                                                </multiselect>
                                            </div>
                                             <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('pay_device')">Payment Device is Required</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="summary.payments[0].mode == 'OTHERS'">
                                <span class="text-secondary mt-3 d-block ml-4 fs-14">Other Details</span>
                                <div class="d-flex flex-wrap">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <div class="form-group">
                                        <span class="fs-14">Reference ID<sup class="text-danger">★</sup></span>
                                        <input class="inputContact pt-2" v-model="summary.payments[0].reference_id" type="text"  name="reference_id" data-vv-as="Reference ID" v-validate="{required:true,regex:/^[A-Za-z0-9_ ]+$/}" />
                                        <span class="highlightContact"></span>
                                        <span class="barContact"></span>
                                        <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('reference_id')">{{ errors.first("reference_id")}}</span>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-s-6">
                                    <div class="mt-3 pl-5 pr-5">
                                        <label class="fs-14 text-black">Other Payment Mode<sup class="text-danger">★</sup></label>
                                        <v-select label="name" v-validate="'required'" name="payment_method" placeholder="Please Select Payment Mode" index="name" v-model="summary.payments[0].payment_method" class="select-width optionsHeight clearNone" :filterable="true" :options="payment_methods"/>
                                        <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('payment_method')">Payment Mode Is Required</span>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div class="form-group mt-2 contactGroup pl-5 pr-5" v-if="!loggedInUser.configure_payment_device">
                                <span class="fs-14 font-600">Amount to be Paid</span>
                                <input class="inputContact pt-2" type="number" :value="summary.grand_total_after_deposit_unformatted" readonly />
                                <span class="highlightContact"></span>
                                <span class="barContact"></span>
                                <!-- <label class="labelContact">Amount</label> -->
                            </div>
                            <hr class="mt-4" style="border-top: dashed 2px #94bef0;" />
                            </div>
                            <span class="cash-email text-secondary font-weight-bold ml-5 mt-4 d-block">Send Order Receipt</span>
                            <div class="mt-4 contactGroup pl-5 pr-5 pb-5">
                            <span class="text-secondary fs-14">Email<sup class="text-danger">★</sup></span>
                            <input id="email" class="inputContact pt-3" v-model="summary.customer_email" v-validate="'required|email'" name="recieptEmail" type="text" placeholder="Please Enter Email Id" />
                            <span class="highlightContact"></span>
                            <span class="barContact"></span>
                            <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('recieptEmail')">{{ errors.first("recieptEmail") }}</span>
                            </div>
                            <hr class="mt-4" style="border-top: dashed 2px #94bef0;" />
                        </div>
                        <div v-else>
                            <div class="d-flex justify-content-between">
                            <div class="pt-4 pointer">
                                <span class="text-secondary pl-4 fs-14"  @click="splitAmount()">
                                <button class="btn btn-secondary btn-smm splitEquallyButton">
                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/exclusion.svg" />
                                    <span class="text-black pl-2 font-weight-bold">SPLIT EQUALLY</span>
                                </button>
                                </span>
                                <span class="text-secondary pl-4 fs-14" @click="resetSplitting()">
                                <button class="btn btn-secondary btn-smm resetSettingButton">
                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/subtraction.svg" />
                                    <span class="text-white pl-2 font-weight-bold">RESET SPLITTING</span>
                                </button>
                                </span>
                            </div>
                            <div class="d-flex mr-4">
                                <span class="text-secondary d-flex align-items-center mt-4">Split By</span>
                                <div class="pointer pl-4 pt-4">
                                    <a class="DollarTab" @click="setAmountTab()" :class="{ activeClassAmount: splitType == 'AMOUNT' }">{{summary.currency.symbol}}</a>
                                    <a class="PerTab" @click="setPercentTab()" :class="{activeClassAmount: splitType == 'PERCENT'}">%</a>
                                </div>
                            </div>
                            </div>
                            <hr class="mt-4" style="border-top: dashed 2px #94bef0;" />
                            <span class="cash-email text-secondary font-weight-bold ml-5 mt-4 d-block">Send Order Receipt</span>
                            <div class="mt-4 contactGroup pl-5 pr-5 pb-5">
                            <span class="text-secondary fs-14">Email<sup class="text-danger">★</sup></span>
                            <input id="email" class="inputContact pt-3" v-model="summary.customer_email" v-validate="'required|email'" name="recieptEmail" type="text" placeholder="Please Enter Email Id" />
                            <span class="highlightContact"></span>
                            <span class="barContact"></span>
                            <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('recieptEmail')">{{ errors.first("recieptEmail") }}</span>
                            </div>
                            <hr class="mt-4" style="border-top: dashed 2px #94bef0;" />
                            <div v-for="(value, index) in payment" :key="index">
                            <span class="d-block pull-right pointer" v-if="payment.length > 2 && index !=0 && index !=1" @click="removePayment(index)">
                                <i class="icon icon-trash colorRed"></i>
                            </span>
                            <div class="d-flex flex-wrap">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="form-group pt-2 contactGroup pr-5" v-if="index == 0">
                                    <span class="fs-14 text-secondary">Customer {{index+1}}</span>
                                    <input class="inputContact pt-2" type="text" readonly :value="value.customer.full_name" />
                                    <span class="highlightContact"></span>
                                    <span class="barContact"></span>
                                </div>
                                <div class="d-flex" v-if="index != 0">
                                    <div class="mt-2 w-100">
                                    <span class="text-secondary fs-14">Customer {{index+1}}</span>
                                    <v-select :close-on-select="true" @search="getSearchCustomer" @search:blur="getSearchCustomer" class="mt-2 payCustomer" placeholder="Search By Customer Name" :options="customers" label="full_name" v-model="value.customer" v-validate="'required'" :name="'first_name'+index.toString()"></v-select>
                                    <span class="invalid-feedback-form" v-show="errors.has('first_name'+index.toString())">Please Select Customer</span>
                                    </div>
                                </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="pt-2">
                                    <span class="text-secondary mt-1 pl-5 fs-14">Payment Mode</span>
                                    <span class="text-primary mt-1 pl-5 fs-14" v-if="value.type == 'PERCENT'">
                                    Amount
                                    <span class="text-secondary">: {{ formatPrice(value.amount) }}</span>
                                    </span>
                                    <div class="ml-5 mt-3">
                                    <div class="form-check form-check-inline">
                                        <label class="form-check-label">
                                        <input class="form-check-input" type="radio" v-model="value.mode" :disabled="payment_type_by_credit_card" v-validate="'required'" :name="'card_mode'+index.toString()" value="CASH" required />
                                        <span class="radio-icon"></span>
                                        <span class="form-check-description">Cash</span>
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline ml-2" :class="{'disable-state' : loggedInUser.configure_payment_device}">
                                        <label class="form-check-label">
                                        <input class="form-check-input" type="radio" @change="cardSelect(index)" v-model="value.mode" v-validate="'required'" :name="'card_mode'+index.toString()" value="CARD" required />
                                        <span class="radio-icon"></span>
                                        <span class="form-check-description">Card</span>
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline ml-2">
                                        <label class="form-check-label">
                                        <input class="form-check-input" type="radio" @change="cardSelect(index)" :disabled="payment_type_by_credit_card" v-model="value.mode" v-validate="'required'" :name="'card_mode'+index.toString()" value="OTHERS" required />
                                        <span class="radio-icon"></span>
                                        <span class="form-check-description">Others</span>
                                        </label>
                                    </div>
                                    </div>
                                    <span class="invalid-feedback-form text-danger fs-13 ml-5" v-show="errors.has('card_mode'+index.toString())">Payment Mode is Required</span>
                                </div>
                                </div>
                            </div>
                                <hr class="horizontalLine mt-2">
                                <div v-if="value.mode === 'CASH'">
                                <span class="text-secondary mt-3 d-block ml-4 fs-14">Cash Tendered Details</span>
                                <div class="d-flex flex-wrap">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                    <div class="mt-4 contactGroup">
                                        <span class="fs-14">Cash Tendered</span>
                                        <input id="cash_tendered" oncopy="return false" onpaste="return false" v-on:keypress="isNumber($event,index)" class="inputContact pt-3" v-model.number="value.cash_received" type="text" @input="computeCashReturned(index)"/>
                                        <span class="highlightContact"></span>
                                        <span class="barContact"></span>
                                    </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                    <div class="mt-4 contactGroup">
                                        <span class="fs-14">Change Remaining</span>
                                        <input id="change_remaining" class="inputContact pt-3" :value="formatPrice(value.cash_returned)" readonly type="text" />
                                        <span class="highlightContact"></span>
                                        <span class="barContact"></span>
                                    </div>
                                    </div>
                                </div>
                                </div>
                                <div v-if="value.mode === 'CARD'">
                                <span class="text-secondary mt-3 d-block ml-4 fs-14">Card Details</span>
                                <div class="mt-2">
                                    <div>
                                    <div class="d-flex flex-wrap">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="form-group pt-1 contactGroup">
                                            <span class="fs-14">Issuing Bank<sup class="text-danger">★</sup></span>
                                            <input class="inputContact pt-2" type="text" :name="'bank_name'+index.toString()" data-vv-as="Bank Name" v-validate="{required:true,regex:/^[A-Za-z0-9_ ]+$/}" v-model="value.bank_name" required />
                                            <span class="highlightContact"></span>
                                            <span class="barContact"></span>
                                        </div>
                                        <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('bank_name'+index.toString())">{{ errors.first("bank_name"+index.toString())}}</span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                        <label class="fs-14">Card Provider<sup class="text-danger">★</sup></label>
                                        <v-select label="name" placeholder="Please Select Card Provider" v-validate="'required'" class="select-width" :name="'card_provider'+index.toString()" v-model="value.card_provider" :filterable="true" :options="cardDetails">
                                            <span slot="no-options">
                                            No matches Found. Please Create New Card
                                            </span>
                                        </v-select>
                                        <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('card_provider'+index.toString())">Card Provider is Required</span>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-wrap mt-3">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                        <span class="fs-14">Card Type<sup class="text-danger">★</sup></span>
                                        <div class="mt-3">
                                            <div class="form-check form-check-inline">
                                            <label class="form-check-label">
                                                <input class="form-check-input" type="radio" v-validate="'required'" :name="'card_type'+index.toString()" v-model="value.card_type" value="CREDIT CARD" required />
                                                <span class="radio-icon"></span>
                                                <span class="form-check-description">Credit Card</span>
                                            </label>
                                            </div>
                                            <div class="form-check form-check-inline ml-2">
                                            <label class="form-check-label">
                                                <input class="form-check-input" type="radio" :disabled="payment_type_by_credit_card" v-validate="'required'" :name="'card_type'+index.toString()" v-model="value.card_type" value="DEBIT CARD" required />
                                                <span class="radio-icon"></span>
                                                <span class="form-check-description">Debit Card</span>
                                            </label>
                                            </div>
                                            <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('card_type'+index.toString())">Card Type is Required</span>
                                        </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="form-group pt-1 contactGroup">
                                            <span class="fs-14">Name On Card<sup class="text-danger">★</sup></span>
                                            <input class="inputContact pt-2" type="text" v-validate="{required:true,regex:/^[A-Za-z0-9_ ]+$/}" data-vv-as="Name on Card" :name="'name_on_card'+index.toString()" v-model="value.name_on_card" required />
                                            <span class="highlightContact"></span>
                                            <span class="barContact"></span>
                                        </div>
                                        <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('name_on_card'+index.toString())">{{ errors.first("name_on_card"+index.toString())}}</span>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-wrap mt-4">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                        <span class="fs-14">Card Number<sup class="text-danger">★</sup></span>
                                        <div class="row">
                                            <div class="col-md-8">
                                                <div class="contactGroup">
                                                    <input class="inputContact" value="XXXX XXXX XXXX" required  readonly/>
                                                    <span class="highlightContact"></span>
                                                    <span class="barContact"></span>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="contactGroup">
                                                    <input class="inputContact" autocomplete="off" :name="'card_number'+index.toString()" data-vv-as="Card Number" maxlength="4" type="number" v-validate="{required:true,max:4,min:4,regex:/^[0-9]*$/}" v-model="value.card_number" required />
                                                    <span class="highlightContact"></span>
                                                    <span class="barContact"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('card_number'+index.toString())">{{errors.first("card_number"+index.toString())}}</span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="contactGroup">
                                            <span class="fs-14">Transaction ID</span>
                                            <input class="inputContact pt-4" v-model="value.transaction_id" type="text" required />
                                            <span class="highlightContact"></span>
                                            <span class="barContact"></span>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-wrap mt-3">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="mt-2 contactGroup">
                                            <span class="fs-14">Approval Code</span>
                                            <input class="inputContact pt-3" v-model="value.approval_code" type="text" />
                                            <span class="highlightContact"></span>
                                            <span class="barContact"></span>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                                <div v-if="value.mode === 'OTHERS'" class="form-group">
                                    <div class="d-flex flex-wrap">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="form-group">
                                            <span class="fs-14">Reference ID<sup class="text-danger">★</sup></span>
                                            <input class="inputContact pt-3" v-model="value.reference_id" type="text"  :name="'reference_id'+index.toString()" data-vv-as="Reference ID" v-validate="{required:true,regex:/^[A-Za-z0-9_ ]+$/}" />
                                            <span class="highlightContact"></span>
                                            <span class="barContact"></span>
                                            <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('reference_id'+index.toString())">{{ errors.first('reference_id'+index.toString())}}</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-s-6">
                                        <div class="mt-3">
                                            <label class="fs-14 text-black">Other Payment Mode<sup class="text-danger">★</sup></label>
                                            <v-select  label="name" index="name" v-model="value.payment_method" :name="'payment_method'+index.toString()"  placeholder="Please Select Payment Mode" v-validate="'required'" class="select-width clearNone" :filterable="true" :options="payment_methods"></v-select>
                                            <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('payment_method'+index.toString())">Payment Mode Is Required</span>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div class="pt-3">
                                <div class="col-md-12">
                                    <div class="pr-1 pl-1">
                                    <span class="fs-14" v-if="value.type == 'PERCENT'">Amount to be Paid(%)</span>
                                    <span class="fs-14" v-else>Amount to be Paid</span>
                                    <input oncopy="return false" onpaste="return false" v-if="value.type == 'PERCENT'" min="0" @input="cashResetPercent(index)" class="inputContact" placeholder="%" type="number" onwheel="this.blur()" v-model="value.percent_value"/>
                                    <input oncopy="return false" onpaste="return false" v-else class="inputContact" min="0" @input="cashReset(index)" placeholder="$" type="number" v-model="value.share_total" onwheel="this.blur()"/>
                                    <span class="highlightContact"></span>
                                    <span class="barContact"></span>
                                    </div>
                                </div>
                                </div>
                                <hr class="mt-3" style="border-top: dashed 2px #94bef0;" />
                            </div>
                            <div class="pt-2 pointer" @click="addPayment()">
                                <span class="text-secondary pl-4 fs-14">
                                <i class="icon icon-plus-circle text-secondary" style="font-size:18px!important;"></i>
                                <span class="pl-1 font-600">Add Payment</span>
                                </span>
                            </div>
                            <div class="pay-method-block mt-5">
                                <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <h6 class="payment-header-text pt-2 pl-3 pull-left d-inline-block">Payments Info</h6>
                                        <div class="list-title" style="position: absolute;">
                                            <div class="ttl-amount">
                                                Total Amount : {{ summary.grand_total }}
                                            </div>
                                        </div>
                                </div>
                                </div>
                                <div class="split-uk-grid split-uk-grid-width-medium-1-3 pt-2">
                                <div class="paymentcard-box">
                                    <div class="payment-list-container">
                                        <div class="payment-list-media">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cash-new-round.svg">
                                        </div>
                                        <div class="payment-list-content">
                                            <h4 class="payment-title"> Cash Payment</h4>
                                            <div class="payment-desc cashcolor" v-if="splitType == 'AMOUNT'">{{summary.currency.symbol}} {{formatPrice(total_cash_payment || 0)}}</div>
                                            <div class="payment-desc cashcolor" v-else>{{formatPrice(total_cash_payment || 0)}}%</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="paymentcard-box">
                                    <div class="payment-list-container">
                                        <div class="payment-list-media">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cash-round.svg">
                                        </div>
                                        <div class="payment-list-content">
                                            <h4 class="payment-title"> Card Payment</h4>
                                            <div class="payment-desc cardcolor" v-if="splitType == 'AMOUNT'">{{summary.currency.symbol}}{{formatPrice(total_card_payment || 0)}}</div>
                                            <div class="payment-desc cashcolor" v-else>{{formatPrice(total_card_payment || 0)}}%</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="paymentcard-box">
                                    <div class="payment-list-container">
                                        <div class="payment-list-media">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/others-round.svg">
                                        </div>
                                        <div class="payment-list-content">
                                            <h4 class="payment-title"> Others Payment</h4>
                                            <div class="payment-desc othercolor" v-if="splitType == 'AMOUNT'">{{summary.currency.symbol}}{{formatPrice(total_other_payment || 0)}}</div>
                                            <div class="payment-desc cashcolor" v-else>{{formatPrice(total_other_payment || 0)}}%</div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <!-- <hr class="mt-3" style="border-top: dashed 2px #94bef0;" />
                            <div class="d-flex flex-wrap" v-if="showCashTendered">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="form-group pt-2 contactGroup">
                                    <span class="text-secondary fs-14">Total Cash Tendered</span>
                                    <input id="cash_tendered" readonly class="inputContact pt-3" v-model="total_cash" type="number" />
                                    <span class="highlightContact"></span>
                                    <span class="barContact"></span>
                                </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="pt-2 contactGroup form-group">
                                    <span class="text-secondary fs-14">Change Remaining</span>
                                    <input id="change_remaining" class="inputContact pt-3" readonly :value="formatPrice(cash_remaining)" type="text" />
                                    <span class="highlightContact"></span>
                                    <span class="barContact"></span>
                                </div>
                                </div>
                            </div>
                            <div class="pl-5 pr-5 mt-4 contactGroup" v-if="showCashTendered">
                                <span class="text-secondary fs-14">Total Amount Due</span>
                                <input id="total_amount_due" readonly class="inputContact pt-3" :value="formatPrice(grand_total_value)" type="text" />
                                <span class="highlightContact"></span>
                                <span class="barContact"></span>
                            </div>
                            <hr class="mt-4" style="border-top: dashed 2px #94bef0;" /> -->
                        </div>
                        </div>
                    </div>
                </div>
                </div>
                <div class="v-modal-dialog-actions modal-bg">
                    <a class="btn btn-link btn-smm text-secondary" style="text-transform: uppercase !important;font-size: 12px !important;" @click="hidePaymentMethod()">CANCEL</a>
                    <button type="button" class="btn btn-danger btn-smm" @click="hideSplitbill()" v-if="splitBill" style="box-shadow: none;">
                        DISCARD SPLITTING
                    </button>
                    <template v-if="summary.payments[0].mode != 'SCANPAY'">
                        <button type="button" class="btn btn-secondary btn-smm ml-4" style="box-shadow: none;" v-if="IsProceedShown && !processResponse" :disabled="submit_payment_api_inprogress" @click="completePayment()">
                            SUBMIT
                        </button>
                        <button type="button" class="btn btn-secondary btn-smm ml-4" style="box-shadow: none;" disabled v-else-if="!IsProceedShown">
                            SUBMIT
                        </button>
                        <div class="row text-center" v-if="processResponse">
                            <div class="col-12">
                            <button class="btn btn-block btn-gradient btn-smm" style="text-transform: capitalize;" disabled>
                                Processing
                                <half-circle-spinner :animation-duration="1000" :size="20" color="#00448b" style="position: absolute;top:6px;right:2px" />
                            </button>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </modal>
        <sweet-modal ref="success_modal" icon="success">
        {{ modal_msg }}
        </sweet-modal>
        <sweet-modal ref="warning_modal" icon="warning">
        {{ modal_msg }}
        </sweet-modal>
        <sweet-modal ref="payment_process" icon="success">Payment Completed Successfully</sweet-modal>
        <payment-msg-modal modal_name="paymentModal" :stored_transaction_ref_id="stored_transaction_ref_id" :fastPay="fastPay" @hidePayModal="hidePayModal" @payment_success_print_receipt_ticket="paymentSuccessPrintReceiptTicket" ></payment-msg-modal>
        <payment-msg-page-close-warning-modal modal_name="paymentMsgPageCloseWarningPopup" ></payment-msg-page-close-warning-modal>
        <payment-qr-code v-if="payment_qr_code_show" :order_id="summary._id" modal_name="paymentQrCodeModal" @hideViewQrCode="hideViewQrCode" @payment_success_print_receipt_ticket="paymentSuccessFromScanAndPayPrintReceiptTicket"></payment-qr-code>
        <payment-session-time-out v-if="payment_qr_code_session_time_out" modal_name="paymentQrCodeSessionTimeOut" @hidePaymentQrCodeSessionTimeOut="hidePaymentQrCodeSessionTimeOut"></payment-session-time-out>
    </div>
</template>
<script>
import axios from "axios";
import { SweetModal } from "sweet-modal-vue";
import { EventBus } from "./eventBus/nav-bar-event.js";
import PaymentMsgModal from "./PaymentMsgModal";
import PaymentMsgPageCloseWarningModal from "./PaymentMsgPageCloseWarningModal";
import PaymentQrCode from "./PaymentQrCode.vue"
import CustomerServices from "./mixins/customer"
import { HollowDotsSpinner } from 'epic-spinners'
import printers from "./mixins/printers";
import Swal from 'sweetalert2';
import paymentDeviceAPI from "./mixins/paymentDeviceAPI"
import PaymentSessionTimeOut from './PaymentSessionTimeOut.vue';
import { HalfCircleSpinner } from "epic-spinners";

export default {
    name: "PaymentMethod",
    data() {
        return {
            checkout_card:true,
            safariTrue:false,
            payment: [],
            open_guest: {},
            modal_msg: "",
            cash_tendered: 0,
            recipient_email: '',
            payment_methods:[],
            PaymentPlaceholder: "Search & Select Payment Method",
            processResponse: false,
            cardDetails: [],
            customers: [],
            submitted:false,
            paid_status: false,
            splitBill: false,
            amountIsPercentage: false,
            splitEqually: false,
            selected: "Open Guest",
            remaining_amount: 0,
            total_shared_amount: 0,
            selectedCustomer: {},
            splitType:"AMOUNT",
            payment_type_by_credit_card: false,
            pay_device:'',
            pay_device_loading:false,
            pay_devices_options:[],
            ticket_details_for_print: [],
            is_call_print_code: false,
            stored_transaction_ref_id: '',
            payment_qr_code_show: false,
            count_down_session_time_out: 150,
            payment_qr_code_session_time_out: false,
            submit_payment_api_inprogress: false
        };
    },
    mixins: [CustomerServices,printers,paymentDeviceAPI],
    props: ["modal_name", "summary", "grand_total_value", "grand_total_amount","fastPay","ticket_printing", "is_credit_card_used", "pay_from_checkout"],
    components: {
        SweetModal,
        HollowDotsSpinner,
        PaymentMsgModal,
        PaymentMsgPageCloseWarningModal,
        PaymentQrCode,
        PaymentSessionTimeOut,
        HalfCircleSpinner
    },
    methods: {
        changeCardType(event, card_type, index) {
            this.pay_devices_options = [];
            this.pay_device = ''
            this.$forceUpdate();
        },
        hidePayModal(){
            this.$modal.hide("paymentModal");
        },
        hideViewQrCode() {
            this.payment_qr_code_show = false
            setTimeout(() => {
                this.$modal.hide("paymentQrCodeModal");
            }, 500);
        },
        async fetchPayDevices(card_type){
            this.pay_device_loading = true
            try {
                let response = await this.getPayDevices()
                this.pay_devices_options = response.active_devices_list;
                if(card_type == 'DEBIT CARD') {
                    let flex_index = this.pay_devices_options.findIndex(device=>device.device_name == 'Flex');
                    if(flex_index >=0) {
                        this.pay_devices_options.splice(flex_index,1)
                    }
                    let mini_2_index = this.pay_devices_options.findIndex(device=>device.device_name == 'Mini 2');
                    if(mini_2_index >=0) {
                        this.pay_devices_options.splice(mini_2_index,1)
                    }
                }
                this.pay_device_loading = false
            } catch (error) {
                console.log(error)
            }
        },
        isNumber: function(evt,index) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();;
            } else {
                return true;
            }
        },
        cashResetPercent(index){
            let share_total = ((this.summary.grand_total_after_deposit_unformatted * Number(this.payment[index]['percent_value']))/100).toFixed(2)
            this.payment[index]['type'] = "PERCENT";
            this.payment[index]['share_total'] = share_total;
            this.payment[index]['amount'] = this.payment[index]['share_total']
            this.payment[index]['customer'] = this.open_guest
            this.payment[index]['mode'] = this.payment[index].mode
            this.payment[index]['value'] = 0.0
            this.payment[index]['paid_status'] = false
            this.cashReset(index);
        },
        cashReset(index){
            if(this.payment[index].mode == 'CASH'){
                this.payment[index].amount = this.payment[index].share_total
                this.payment[index].cash_returned = this.payment[index].cash_received - this.payment[index].share_total
            }
            else{
                this.payment[index].amount = parseFloat(this.payment[index].share_total)
                this.payment[index].share_total = parseFloat(this.payment[index].amount)
            }
            // console.log(this.payment[index].cash_received)
        },
        cardSelect(index){
            this.payment[index].cash_received = 0;
            this.payment[index].cash_returned = 0;
            this.payment[index].card_provider = "";
            this.payment[index].name_on_card = "";
            this.payment[index].bank_name = "";
            this.payment[index].approval_code = "";
            this.payment[index].card_type = "";
            this.payment[index].card_number = "";
            this.payment[index].transaction_id = "";
            this.payment[index].payment_method = "";
            this.payment[index].reference_id = "";
        },
        changePaymentMode(){
            this.cash_tendered = 0;
            this.summary.payments[0].card_provider = "";
            this.summary.payments[0].name_on_card = "";
            this.summary.payments[0].bank_name = "";
            this.summary.payments[0].approval_code = "";
            this.summary.payments[0].card_type = "";
            this.summary.payments[0].card_number = "";
            this.summary.payments[0].transaction_id = "";
            this.summary.payments[0].payment_method = "";
            this.summary.payments[0].reference_id = "";
        },
        countDownTimerSessionTimeOut() {
            if (this.count_down_session_time_out > 0) {
                setTimeout(() => {
                    this.count_down_session_time_out -= 1;
                    this.countDownTimerSessionTimeOut();
                }, 1000);
            } else if (this.count_down_session_time_out === 0) {
                // this.clearOtpInputs();
                // this.qr_code_time_out = 
                // this.payment_qr_code_show = false
                // this.payment_qr_code_session_time_out = true
                // setTimeout(() => {
                //     // this.$modal.hide("paymentQrCodeModal");
                //     this.$modal.show("paymentQrCodeSessionTimeOut");
                // }, 500);
            }
        },
        hidePaymentQrCodeSessionTimeOut() {
            // this.qr_code_time_out = 
            this.count_down_session_time_out = 150
            // this.payment_qr_code_show = false
            this.payment_qr_code_session_time_out = false
            setTimeout(() => {
                // this.$modal.hide("paymentQrCodeModal");
                this.$modal.hide("paymentQrCodeSessionTimeOut");
            }, 500);
        },
        openQrCode() {
            // this.qr_code_time_out = 
            this.payment_qr_code_show = true
            setTimeout(() => {
                this.$modal.show("paymentQrCodeModal");
                // this.countDownTimerSessionTimeOut();
            }, 500);
        },
        computeCashReturned(index) {
            if(this.payment[index].cash_received !=0){
                this.payment[index].cash_received = Number(this.payment[index].cash_received)
            }
            if(this.payment[index].type == 'AMOUNT'){
                this.payment[index].cash_returned = this.payment[index].cash_received - this.payment[index].share_total
            }
            else{
                this.payment[index].cash_returned = this.payment[index].cash_received - this.payment[index].amount
            }
            this.cash_tendered = 0
            this.cash_remaining = 0
            this.payment.forEach(element => {
                this.cash_tendered += element.cash_received
                this.cash_remaining += element.cash_returned
            })
        },
        resetSplitting(){
            this.payment.forEach((payment,index) => {
                payment.type = "AMOUNT";
                payment.amount = 0;
                payment.cash_received = 0;
                payment.cash_returned = 0;
                payment.share_total = 0;
                if(index != 0){
                    payment.mode = '';
                }
            });
        },
        showSplitbill(){
            // this.summary.payments[0].mode = "";
            this.splitBill = true;
            this.payment = [
                {
                    customer: {
                        _id: this.summary.customer_id,
                        full_name: this.summary.customer_name,
                        last_name: ""
                    },
                    type: "AMOUNT",
                    value: 0.0,
                    amount: 0.0,
                    share_total: 0,
                    percent_value: 0,
                    mode: this.payment_type_by_credit_card ? 'CARD' :this.summary.payments[0].mode,
                    paid_status: false,
                    name_on_card: "",
                    card_type: this.payment_type_by_credit_card ? 'CREDIT CARD' : "",
                    card_provider: "",
                    transaction_id: "",
                    bank_name:"",
                    approval_code:"",
                    card_number:"",
                    cash_received:0,
                    cash_returned:0,
                    payment_method:this.summary.payments[0].payment_method
                },
                {
                    customer: this.open_guest,
                    type: "AMOUNT",
                    value: 0.0,
                    amount: 0.0,
                    share_total: 0,
                    percent_value: 0,
                    mode: this.payment_type_by_credit_card ? 'CARD' : "",
                    paid_status: false,
                    name_on_card: "",
                    card_type: this.payment_type_by_credit_card ? 'CREDIT CARD' : "",
                    card_provider: "",
                    transaction_id: "",
                    bank_name:"",
                    approval_code:"",
                    card_number:"",
                    cash_received:0,
                    cash_returned:0,
                    payment_method:this.summary.payments[0].payment_method,
                }
            ];
        },
        hideSplitbill(){
            this.remaining_amount = 0;
            this.amountIsPercentage = false;
            this.addCustomer = [];
            this.splitEqually = false;
            this.splitType = "AMOUNT";
            this.splitBill = false;
            this.total_cash = 0;
            this.cash_tendered = 0;
            this.payment.cash_returned = 0;
            this.summary.payments[0].mode = 'CASH';
            this.summary.payments[0].card_provider = "";
            this.summary.payments[0].name_on_card = "";
            this.summary.payments[0].bank_name = "";
            this.summary.payments[0].approval_code = "";
            this.summary.payments[0].card_type = "";
            this.summary.payments[0].card_number = "";
            this.summary.payments[0].transaction_id = "";
            this.summary.payments[0].payment_method = "";
            this.summary.payments[0].reference_id = "";
        },
        completePayment() {
            //console.log('complete payment')
            try {
                this.submit_payment_api_inprogress = true;
                if(this.splitBill == false ){
                    this.payment = [
                        {
                            customer: {
                                _id: this.summary.customer_id,
                                full_name: this.summary.customer_name,
                                last_name: ""
                            },
                            type: "AMOUNT",
                            value: 0.0,
                            amount: parseFloat(0.0),
                            share_total: parseFloat(this.summary.grand_total_after_deposit_unformatted),
                            percent_value: 0,
                            mode: this.summary.payments[0].mode,
                            paid_status: false,
                            name_on_card: this.summary.payments[0].name_on_card || '',
                            card_provider: this.summary.payments[0].card_provider || '',
                            transaction_id: this.summary.payments[0].transaction_id || '',
                            bank_name:this.summary.payments[0].bank_name || '',
                            approval_code:this.summary.payments[0].approval_code || '',
                            card_number:this.summary.payments[0].card_number || '',
                            card_type:this.summary.payments[0].card_type || 'CREDIT CARD',
                            reference_id:this.summary.payments[0].reference_id || '',
                            cash_received:0,
                            cash_returned:0,
                            payment_method:this.summary.payments[0].payment_method,
                            order_id: this.summary._id,
                            device_id: this.pay_device,
                            receipt_email: this.summary.customer_email,
                        
                        }
                    ];
                }
                this.payment.forEach(payment => {
                    payment['customer_id'] = payment.customer._id
                    if (payment.type == 'PERCENT') {
                        payment.value = parseFloat(payment.percent_value)
                        payment.amount = parseFloat(payment.amount)
                    } else {
                        payment.value = parseFloat(payment.share_total)
                        payment.amount = parseFloat(payment.share_total)
                    }
                    if (payment.mode == 'CARD' ) {
                        if (typeof payment['card_provider'] == 'object') {
                            payment['card_provider'] = payment['card_provider']['name']
                        }
                    }
                })
                this.$validator.validate().then(result => {
                    //console.log('validating')
                    if (result == true) {
                        //console.log('validated')
                        this.processResponse = true
                        this.payment.forEach(payment => {
                            if (payment.mode == 'CARD') {
                                payment['card_number'] ='XXXX XXXX XXXX ' + payment['card_number'].toString()
                            }
                        })
                        this.$http.get("/cashier/is_cashier_session_active").then(res => {
                            if (res.data.status_id == 1) {
                                if(this.$store.getters.loggedInUser.cashier_session.cash_register_details.server_url == '' ) {
                                    console.log(this.summary.payments[0].mode)
                                    if(this.$store.getters.loggedInUser.configure_payment_device && this.summary.payments[0].mode == 'CARD' ){
                                        let params;
                                        params = {
                                            order_id: this.summary._id,
                                            device_id: this.pay_device.device_id,
                                            receipt_email: this.summary.customer_email,
                                            card_type: this.summary.payments[0].card_type == 'CREDIT CARD' ? 'CREDIT' : 'DEBIT'
                                        }
                                        if(this.fastPay){
                                            params['fast_pay'] = true;
                                        } 
                                        if(this.fastPay || this.pay_from_checkout) {
                                            params['is_prepaid'] = true;
                                        }
                                        this.$http.post('/orders/payment/card',params).then(res => {
                                            if (res.data.status_id == 1) {
                                                // console.log(this.fastPay)
                                                // console.log(this.pay_from_checkout)
                                                this.is_call_print_code = false;
                                                if(this.fastPay || this.pay_from_checkout) {
                                                    this.ticket_details_for_print = res.data.ticket_details
                                                }
                                                this.modal_msg = res.data.message;
                                                EventBus.$emit("payment_in_progress",'in_progress')
                                                // paymentMsgPageCloseWarningPopup
                                                // setTimeout(() => {
                                                this.$modal.show('paymentMsgPageCloseWarningPopup')
                                                // },1000);
                                                setTimeout(() => {
                                                    this.$modal.hide('paymentMsgPageCloseWarningPopup');
                                                    this.$modal.show('paymentModal')
                                                },2000);
                                                // setTimeout(() => {
                                                //     this.$modal.show('paymentModal')
                                                // },1000);
                                                // this.$modal.hide('paymentModal')
                                                this.$modal.hide(this.modal_name)
                                                //this.$router.push('/manageorder')
                                                this.processResponse = true
                                                this.recipient_email = '' 
                                                if(res.data.transaction_ref_id != ''){
                                                    this.paymentDevice(res.data.transaction_ref_id)
                                                }
                                                EventBus.$on("status", (value) => {
                                                    if(value == 'failure' ){
                                                        this.processResponse = false;
                                                    }
                                                });
                                            }
                                            else {
                                                this.modal_msg = res.data.reason;
                                                this.$refs.warning_modal.open();
                                                this.processResponse = false
                                                this.callinProgress = false
                                                this.$modal.hide('paymentModal')
                                                this.$modal.hide(this.modal_name)
                                            // this.$router.push('/manageorder')
                                                this.submit_payment_api_inprogress = false;
                                            }
                                        })
                                        .catch(err => {
                                            console.log(err)
                                            this.submit_payment_api_inprogress = false;
                                        })
                                        
                                    }
                                    else {
                                        this.$http.post('/orders/payment/split', {
                                            orderId: this.summary._id,
                                            fastPay:this.fastPay,
                                            note: this.summary.note,
                                            splitDetails: this.payment,
                                            cashReturned: this.cash_remaining,
                                            cashReceived: Number(this.cash_tendered),
                                            receiptEmail: this.summary.customer_email,
                                            // printer_details: category_data
                                        }).then(res => {
                                            console.log(res.data)
                                            if (res.data.status_id == 1) {
                                                this.processResponse = false
                                                this.recipient_email = ''
                                                // if (!res.data.prompt_user_to_print) {
                                                //     EventBus.$emit('PaymentComplete', true)
                                                //     EventBus.$emit('updateBills', true)
                                                //     setTimeout(() => {
                                                //         if(this.fastPay == false){
                                                //             EventBus.$emit('PaymentComplete', true)
                                                //         }else{
                                                //             this.$router.push({name: 'ManageOrder'})
                                                //         }
                                                //         this.$modal.hide(this.modal_name)
                                                //         this.splitBill = false;
                                                //         this.splitEqually = false;
                                                //         this.payment.cash_returned = 0;
                                                //         this.cash_tendered = 0;
                                                //         this.summary.payments[0].mode = '';
                                                //         this.total_cash = 0;
                                                //         this.splitType = "AMOUNT";
                                                //         this.summary.payments[0].card_provider = "";
                                                //         this.summary.payments[0].name_on_card = "";
                                                //         this.summary.payments[0].bank_name = "";
                                                //         this.summary.payments[0].approval_code = "";
                                                //         this.summary.payments[0].card_type = "";
                                                //         this.summary.payments[0].card_number = "";
                                                //         this.summary.payments[0].transaction_id = "";
                                                //         this.summary.payments[0].payment_method = "";
                                                //         this.summary.payments[0].reference_id = "";
                                                //     }, 1000)
                                                // } else {
                                                    setTimeout(() => {
                                                        EventBus.$emit('PaymentComplete', true)
                                                        EventBus.$emit('updateBills', true)
                                                        if(this.fastPay == false){
                                                            EventBus.$emit('PaymentComplete', true)
                                                        }else{
                                                            this.$router.push({name: 'ManageOrder'})
                                                        }
                                                        this.$modal.hide(this.modal_name)
                                                        this.splitBill = false;
                                                        this.splitEqually = false;
                                                        this.payment.cash_returned = 0;
                                                        this.cash_tendered = 0;
                                                        this.summary.payments[0].mode = '';
                                                        this.total_cash = 0;
                                                        this.splitType = "AMOUNT";
                                                        this.summary.payments[0].card_provider = "";
                                                        this.summary.payments[0].name_on_card = "";
                                                        this.summary.payments[0].bank_name = "";
                                                        this.summary.payments[0].approval_code = "";
                                                        this.summary.payments[0].card_type = "";
                                                        this.summary.payments[0].card_number = "";
                                                        this.summary.payments[0].transaction_id = "";
                                                        this.summary.payments[0].payment_method = "";
                                                        this.summary.payments[0].reference_id = "";
                                                        let ticket_data = []
                                                        if(res.data.response.hasOwnProperty('ticket_details') && res.data.response.ticket_details.length){
                                                            // data.forEach(y => {
                                                            //     y.printers.forEach(x => {

                                                            //     })
                                                            // })
                                                            res.data.response.ticket_details.forEach(z => {
                                                                // if(x.category_id == z.category_id){
                                                                    ticket_data.push(z)
                                                                // }
                                                            })
                                                        }
                                                        this.confirmPrint(res.data.receipt_details,ticket_data)
                                                    }, 1000)
                                                    this.submitted = false
                                                    // let routeData = this.$router.resolve({
                                                    //     path: `/printre?`,
                                                    //     query: {
                                                    //     id: this.summary._id
                                                    //     },
                                                    //     target: '_blank'
                                                    // })
                                                // }
                                            } else {
                                                this.modal_msg = res.data.reason;
                                                this.$refs.warning_modal.open();
                                                this.processResponse = false
                                                this.submitted = false
                                                this.submit_payment_api_inprogress = false;
                                            }
                                        })
                                        .catch(err => {
                                            console.log(err)
                                            console.log(err.response.data.reason)
                                            this.submit_payment_api_inprogress = false;
                                        })
                                    }
                                } else {
                                    this.$http(this.$store.getters.loggedInUser.cashier_session.cash_register_details.server_url ).then(res => {
                                        if (res.data.status_id) {
                                            if(this.$store.getters.loggedInUser.configure_payment_device && this.summary.payments[0].mode == 'CARD'){
                                                let params;
                                                params = {
                                                    order_id: this.summary._id,
                                                    device_id: this.pay_device.device_id,
                                                    receipt_email: this.summary.customer_email,
                                                    card_type: this.summary.payments[0].card_type == 'CREDIT CARD' ? 'CREDIT' : 'DEBIT'
                                                }
                                                if(this.fastPay){
                                                    params['fast_pay'] = true;
                                                }
                                                if(this.fastPay || this.pay_from_checkout) {
                                                    params['is_prepaid'] = true;
                                                }
                                                this.$http.post('/orders/payment/card',params).then(res => {
                                                    if (res.data.status_id == 1) {
                                                        // console.log(this.fastPay)
                                                        // console.log(this.pay_from_checkout)
                                                        this.is_call_print_code = false;
                                                        if(this.fastPay || this.pay_from_checkout) {
                                                            this.ticket_details_for_print = res.data.ticket_details
                                                            // console.log(this.ticket_details_for_print)
                                                        }
                                                        this.modal_msg = res.data.message;
                                                        EventBus.$emit("payment_in_progress",'in_progress')
                                                        // paymentMsgPageCloseWarningPopup
                                                        // setTimeout(() => {
                                                            this.$modal.show('paymentMsgPageCloseWarningPopup')
                                                        // },1000);
                                                        setTimeout(() => {
                                                            this.$modal.hide('paymentMsgPageCloseWarningPopup');
                                                            this.$modal.show('paymentModal')
                                                        },2000);
                                                        // this.$modal.hide('paymentModal')
                                                        this.$modal.hide(this.modal_name)
                                                        this.processResponse = true
                                                        this.recipient_email = ''
                                                        if(res.data.transaction_ref_id != ''){
                                                            this.paymentDevice(res.data.transaction_ref_id)
                                                        } 
                                                    }
                                                    else {
                                                        this.modal_msg = res.data.reason;
                                                        this.$refs.warning_modal.open();
                                                        this.processResponse = false
                                                        this.callinProgress = false
                                                        this.$modal.hide('paymentModal')
                                                        this.$modal.hide(this.modal_name)
                                                    // this.$router.push('/manageorder')
                                                        this.submit_payment_api_inprogress = false;
                                                    }
                                                })
                                                .catch(err => {
                                                    console.log(err)
                                                    this.submit_payment_api_inprogress = false;
                                                })
                                            } else{
                                                this.$http.post('/orders/payment/split', {
                                                    orderId: this.summary._id,
                                                    fastPay:this.fastPay,
                                                    note: this.summary.note,
                                                    splitDetails: this.payment,
                                                    cashReturned: this.cash_remaining,
                                                    cashReceived: Number(this.cash_tendered),
                                                    receiptEmail: this.summary.customer_email,
                                                    // printer_details: category_data
                                                }).then(res => {
                                                    if (res.data.status_id == 1) {
                                                        this.processResponse = false
                                                        this.recipient_email = ''
                                                        // if (!res.data.prompt_user_to_print) {
                                                        //     EventBus.$emit('PaymentComplete', true)
                                                        //     EventBus.$emit('updateBills', true)
                                                        //     setTimeout(() => {
                                                        //         if(this.fastPay == false){
                                                        //             EventBus.$emit('PaymentComplete', true)
                                                        //         }else{
                                                        //             this.$router.push({name: 'ManageOrder'})
                                                        //         }
                                                        //         this.$modal.hide(this.modal_name)
                                                        //         this.splitBill = false;
                                                        //         this.splitEqually = false;
                                                        //         this.payment.cash_returned = 0;
                                                        //         this.cash_tendered = 0;
                                                        //         this.summary.payments[0].mode = '';
                                                        //         this.total_cash = 0;
                                                        //         this.splitType = "AMOUNT";
                                                        //         this.summary.payments[0].card_provider = "";
                                                        //         this.summary.payments[0].name_on_card = "";
                                                        //         this.summary.payments[0].bank_name = "";
                                                        //         this.summary.payments[0].approval_code = "";
                                                        //         this.summary.payments[0].card_type = "";
                                                        //         this.summary.payments[0].card_number = "";
                                                        //         this.summary.payments[0].transaction_id = "";
                                                        //         this.summary.payments[0].payment_method = "";
                                                        //         this.summary.payments[0].reference_id = "";
                                                        //     }, 1000)
                                                        // } else {
                                                            setTimeout(() => {
                                                                EventBus.$emit('PaymentComplete', true)
                                                                EventBus.$emit('updateBills', true)
                                                                if(this.fastPay == false){
                                                                    EventBus.$emit('PaymentComplete', true)
                                                                }else{
                                                                    this.$router.push({name: 'ManageOrder'})
                                                                }
                                                                this.$modal.hide(this.modal_name)
                                                                this.splitBill = false;
                                                                this.splitEqually = false;
                                                                this.payment.cash_returned = 0;
                                                                this.cash_tendered = 0;
                                                                this.summary.payments[0].mode = '';
                                                                this.total_cash = 0;
                                                                this.splitType = "AMOUNT";
                                                                this.summary.payments[0].card_provider = "";
                                                                this.summary.payments[0].name_on_card = "";
                                                                this.summary.payments[0].bank_name = "";
                                                                this.summary.payments[0].approval_code = "";
                                                                this.summary.payments[0].card_type = "";
                                                                this.summary.payments[0].card_number = "";
                                                                this.summary.payments[0].transaction_id = "";
                                                                this.summary.payments[0].payment_method = "";
                                                                this.summary.payments[0].reference_id = "";
                                                                let ticket_data = []
                                                                if(res.data.response.hasOwnProperty('ticket_details') && res.data.response.ticket_details.length){
                                                                    // data.forEach(y => {
                                                                    //     y.printers.forEach(x => {

                                                                    //     })
                                                                    // })
                                                                    res.data.response.ticket_details.forEach(z => {
                                                                        // if(x.category_id == z.category_id){
                                                                            ticket_data.push(z)
                                                                        // }
                                                                    })
                                                                }
                                                                this.confirmPrint(res.data.receipt_details,ticket_data)
                                                            }, 1000)
                                                            this.submitted = false
                                                            // let routeData = this.$router.resolve({
                                                            //     path: `/printre?`,
                                                            //     query: {
                                                            //     id: this.summary._id
                                                            //     },
                                                            //     target: '_blank'
                                                            // })
                                                        // }
                                                    } else {
                                                        this.modal_msg = res.data.reason;
                                                        this.$refs.warning_modal.open();
                                                        this.processResponse = false
                                                        this.submitted = false
                                                    }
                                                })
                                                .catch(err => {
                                                    console.log(err)
                                                    this.submit_payment_api_inprogress = false;
                                                })
                                            }
                                        } else {
                                            this.$toasted.global.success(res.data.reason);
                                            this.submit_payment_api_inprogress = false;
                                        }
                                    })
                                    .catch(error => {
                                        this.processResponse = false
                                        this.submitted = false
                                        this.modal_msg ="Cannot Connect to Cash Register. Please make sure the Cash Register and Device are Connected to the Same Network.";
                                        this.$refs.warning_modal.open();
                                        this.submit_payment_api_inprogress = false;
                                    });
                                } 
                            } else {
                                this.$toasted.global.error(res.data.reason);
                                this.submit_payment_api_inprogress = false;
                            }
                        }).catch((error) => {
                            this.$toasted.global.error(error.response.data.reason);
                            this.submit_payment_api_inprogress = false;
                        });
                    } else {
                        this.submit_payment_api_inprogress = false;
                    }
                })
            }
            catch (e) {
                console.log(e)
                this.submit_payment_api_inprogress = false;
            }
        },
        paymentDevice(transaction_ref_id) {
            this.stored_transaction_ref_id = transaction_ref_id
            let topic = transaction_ref_id
            fetch('https://iid.googleapis.com/iid/v1/'+this.fcmDetails.fcm_token+'/rel/topics/'+topic, {
                method: 'POST',
                headers: new Headers({
                'Authorization': 'key='+this.fcmDetails.fcm_server_key
                })
            }).then(response => {
                console.log(response)
                if (response.status < 200 || response.status >= 400) {
                    throw 'Error subscribing to topic: '+response.status + ' - ' + response.text();
                }
            }).catch(error => {
                console.error(error);
                this.$toasted.global.error('Subscribed to payment"'+topic+'" again');
                this.$modal.hide('paymentModal')
                this.$modal.hide(this.modal_name)
                
            })
        },
       
        confirmPrint(receipt_data,ticket_data) {
            console.log(this.loggedInUser.enable_double_receipts)
            if(ticket_data && ticket_data.length){
                if(ticket_data.length == 1){
                    this.store_ticket_data = ticket_data[0]
                    if(!this.ticket_printing){
                        this.printTicket(ticket_data[0])
                    }
                }
                else if(ticket_data.length > 1){
                    ticket_data.forEach(x => {
                        this.store_ticket_data = x
                        if(!this.ticket_printing){
                            this.printTicket(this.store_ticket_data)
                        }
                    })
                }
            }
            if(this.loggedInUser.print_receipt_by_default) {
                this.store_receipt_data = receipt_data
                this.printReceipt(receipt_data)
                if(this.loggedInUser.enable_double_receipts) {
                    setTimeout(() => {
                        this.printReceipt(receipt_data)
                    }, 5000);
                }
            } else {
                Swal({
                    title: 'Print Receipts',
                    text: 'Do You Want to Print Receipt ?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#00448b',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Continue Without Printing',
                    allowOutsideClick: false
                }).then(result => {
                    if (result.value) {
                        this.store_receipt_data = receipt_data
                        this.printReceipt(receipt_data)
                        if(this.loggedInUser.enable_double_receipts) {
                            setTimeout(() => {
                                this.printReceipt(receipt_data)
                            }, 5000);
                        }
                    } else {
                        this.$modal.hide(this.modal_name)
                    }
                })
            }
        },
        getOpenGuest() {
            this.$http.post("customers/get_guest_customer_id").then(response => {
                this.open_guest = {
                    _id: response.data.open_guest_id._id,
                    full_name: response.data.open_guest_id.full_name,
                    last_name: ""
                };
            });
        },
        hidePaymentMethod() {
            if(this.fastPay == false){
                this.splitBill = false;
                this.splitEqually = false;
                this.payment.cash_returned = 0;
                this.cash_tendered = 0;
                this.summary.payments[0].mode = 'CASH';
                this.total_cash = 0;
                this.splitType = "AMOUNT";
                this.summary.payments[0].card_provider = "";
                this.summary.payments[0].name_on_card = "";
                this.summary.payments[0].bank_name = "";
                this.summary.payments[0].approval_code = "";
                this.summary.payments[0].card_type = "";
                this.summary.payments[0].card_number = "";
                this.summary.payments[0].transaction_id = "";
                this.summary.payments[0].payment_method = "";
                this.summary.payments[0].reference_id = "";
                this.$emit("close");
            }
            else{
                this.splitBill = false;
                this.splitEqually = false;
                this.payment.cash_returned = 0;
                this.cash_tendered = 0;
                this.summary.payments[0].mode = 'CASH';
                this.total_cash = 0;
                this.splitType = "AMOUNT";
                this.summary.payments[0].card_provider = "";
                this.summary.payments[0].name_on_card = "";
                this.summary.payments[0].bank_name = "";
                this.summary.payments[0].approval_code = "";
                this.summary.payments[0].card_type = "";
                this.summary.payments[0].card_number = "";
                this.summary.payments[0].transaction_id = "";
                this.summary.payments[0].payment_method = "";
                this.summary.payments[0].reference_id = "";
                this.$emit("close");
                EventBus.$emit('loadProductList');
                this.$modal.hide(this.modal_name)
            }
        },
        get_all_payment_methods_web(){
            this.$http.get('outlet/get_all_payment_methods').then(res => {
                if(res.data.status_id == 1){
                    this.payment_methods = res.data.response.payment_methods
                }
            })
        },
        setPercentTab() {
            if(this.splitType == "PERCENT"){
                console.log('console 1')
                return
            }
            this.splitType = "PERCENT";
            let remain_amount =
                Number(this.remaining_amount) > 0 ? Number(this.remaining_amount) : 0;
            if(this.splitEqually){
                console.log('console 2')
                this.splitAmount()
            }else{
                console.log('console 3')
                // this.payment.length = 2
                this.payment.forEach(element => {
                    let percent_value = ((Number(element['share_total'])*100)/this.summary.grand_total_after_deposit_unformatted).toFixed(2)
                    element['customer'] = this.open_guest
                    element['type'] = "PERCENT"
                    element['value'] = 0.0
                    element['amount'] = element['share_total']
                    // element['share_total'] = 0
                    element['mode'] = element.mode
                    element['paid_status'] = false
                    // element['name_on_card'] = ""
                    // element['card_provider'] = ""
                    // element['transaction_id'] = ""
                    element['percent_value'] = percent_value
                    // element['payment_method'] = ''
                });
            }
            // this.payment[index].amount = Number(
            //   this.payment[index].share_total
            // ).toFixed(2);
            // if (this.payment[index].amount == NaN) {
            //   this.payment[index].amount = 0;
            // }
            // this.payment[index].percent_value = (
            //   (Number(this.payment[index].share_total) / this.grand_total_value) *
            //   100
            // ).toFixed(2);
            this.remainingAmount();
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace(",", ".");
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        setAmountTab() {
            if(this.splitType == "AMOUNT"){
                return
            }
            this.splitType = "AMOUNT";
            let remain_amount = Number(this.remaining_amount) > 0 ? Number(this.remaining_amount) : 0;
            if(this.splitEqually){
                // this.splitAmount()
                this.splitEqually = true;
                let number_of_bills = parseFloat(this.payment.length);
                if(this.splitType  == 'AMOUNT'){
                    let shareTotal = this.splitTotal(
                    this.summary.grand_total_after_deposit_unformatted,
                    number_of_bills
                    ).toFixed(2);
                    this.payment.forEach(payment => {
                        payment.type = "AMOUNT";
                        payment.share_total = Number(shareTotal);
                        payment.amount = Number(shareTotal)
                    });
                }
            }
            else{
                // this.payment.length = 2
                this.payment.forEach(element => {
                element['customer'] = this.open_guest
                element['share_total'] = element['amount']
                element['type'] = "AMOUNT"
                element['value'] = 0.0
                // element['amount'] = 0.0
                element['mode'] = element.mode
                element['paid_status'] = false
                element['name_on_card'] = ""
                element['card_provider'] = ""
                element['transaction_id'] = ""
                element['payment_method'] = 'Others'
                // element['percent_value'] = 0.0
                });
            }
            // this.payment[index].amount = 0.0;
            this.remainingAmount();
        },
        splitAmount() {
            this.splitEqually = true;
            let number_of_bills = parseFloat(this.payment.length);
            if(this.splitType  == 'AMOUNT'){
                let shareTotal = this.splitTotal(
                this.summary.grand_total_after_deposit_unformatted,
                number_of_bills
                ).toFixed(2);
                this.payment.forEach(payment => {
                    payment.type = "AMOUNT";
                    payment.share_total = Number(shareTotal);
                    payment.amount = Number(shareTotal)
                    payment.cash_received = 0;
                    payment.cash_returned = 0;
                });
            }else {
                let split_percentage = this.splitPercentage(number_of_bills)
                let split_value =  (this.summary.grand_total_after_deposit_unformatted*(split_percentage/100).toFixed(4)).toFixed(2)
                let additional_value = (100 - (split_percentage * (number_of_bills - 1))).toFixed(2)
                for(var i=0;i<number_of_bills-1;i++){
                    this.payment[i]['type'] = "PERCENT"
                    this.payment[i]['amount'] = split_value
                    this.payment[i]['percent_value'] = split_percentage
                }
                this.payment[number_of_bills -1]['type'] = "PERCENT"
                this.payment[number_of_bills -1]['amount'] = split_value
                this.payment[number_of_bills -1]['percent_value'] = additional_value
                // this.payment.forEach(payment => {
                //   payment.type = "PERCENT";
                //   payment.amount = split_value
                //   payment.percent_value = split_percentage

                // })
            }
            if(this.splitType == 'AMOUNT'){
                this.cash_tendered = 0;
            }
            this.remainingAmount();
            this.handlerRemainingAmount();
        },
        splitPercentage(number_of_splits){
            return parseFloat(100/number_of_splits).toFixed(2)
        },
        splitTotal(grand_total, number_of_bills) {
            return parseFloat(grand_total) / number_of_bills;
        },
        remainingAmount(index = -1) {
            this.total_shared_amount = 0;
            // this.splitEqually = true;
            var $this = this;
            if (index != -1) {
                this.payment[index].amount = (
                (parseFloat(this.payment[index].percent_value) / 100) *
                this.summary.grand_total_after_deposit_unformatted
                ).toFixed(2);
                if (isNaN(this.payment[index].amount)) {
                this.payment[index].amount = 0;
                }
            }
            // this.payment.forEach(el => {
            //   if (el.type == "PERCENT") {
            //     $this.total_shared_amount += Number(el.amount);
            //   } else {
            //     $this.total_shared_amount += Number(el.share_total);
            //   }
            // });
            if(this.splitType == "PERCENT"){
                this.payment.forEach(element => {
                    $this.total_shared_amount += Number(element.amount);
                });

            }else{
                this.payment.forEach(element => {
                    $this.total_shared_amount += Number(element.share_total);
                });
            }
            this.remaining_amount =
                Number(this.summary.grand_total_after_deposit_unformatted) -
                Number($this.total_shared_amount.toFixed(2));
            if (isNaN(this.remaining_amount)) {
                this.remaining_amount = 0;
            }
            return Number(this.remaining_amount);
        },
        async getSearchCustomer(search = "") {
            try {
                let params = {
                            "properties": ["first_name", "last_name", "full_name"],
                            "search": { term: search, fields: ["full_name"] },
                            "sort": { by: "full_name", order: 1 },
                            "skip": 0,
                            "limit": 5,
                        }
                let res = await this.customerGetCustomerWith(params)
                this.customers = res.response
            } catch (reason) {
                // TODO: Handle Error
            }
        },
        handlerRemainingAmount() {
            if (this.remaining_amount == NaN) {
                this.remaining_amount = 0;
            }
            let remaining_roundoff = this.remaining_amount.toFixed(2);
            this.payment[this.payment.length - 1].share_total = Number(
                (
                Number(this.payment[this.payment.length - 1].share_total) +
                Number(remaining_roundoff)
                ).toFixed(2)
            );
            this.remaining_amount = 0.0;
        },
        addPayment() {
            // let remain_amount =
            //   Number(this.remaining_amount) > 0 ? Number(this.remaining_amount) : 0;
            var newBill = {
                customer: this.open_guest,
                type: this.splitType,
                value: 0.0,
                amount: 0.0,
                share_total: 0,
                mode: this.payment_type_by_credit_card ? 'CARD' : "",
                paid_status: false,
                name_on_card: "",
                card_provider: "",
                card_type: this.payment_type_by_credit_card ? 'CREDIT CARD' : "",
                transaction_id: "",
                percent_value: 0,
                bank_name:"",
                approval_code:"",
                card_number:"",
                cash_received: 0,
                cash_returned: 0,
                payment_method:""
            };
            this.payment.push(newBill);
            if(this.splitEqually){
                this.splitAmount()
            }
            // this.remainingAmount();
            // this.handlerRemainingAmount();
        },
        removePayment(index) {
            this.payment.splice(index, 1);
            this.remainingAmount();
            if(this.splitEqually){
                this.splitAmount()
            }
            // this.handlerRemainingAmount();
        },
        get_all_cards() {
            this.$http.get('outlet/get_all_cards_web').then(res => {
                if (res.data.status_id == 1) {
                    this.cardDetails = res.data.response.card_details
                }
            })
        },
        get_all_payment_methods_web(){
            this.$http.get('outlet/get_all_payment_methods_web').then(res => {
                if(res.data.status_id == 1){
                this.payment_methods = res.data.response.payment_methods
                }

            })
        },
        async getReceiptDetails(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('/mobile/get_details_for_receipt', params)
                try {
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(err) {
                    this.$toasted.global.error(err.response.reason)
                }
            })
        },
        async printReceiptForOnlinePay(){
            console.log(this.summary._id)
            try {
                let response = await this.getReceiptDetails({order_id : this.summary._id})
                if(this.loggedInUser.print_receipt_by_default) {
                    this.store_receipt_data = response.response
                    this.printReceipt(response.response)
                    if(this.loggedInUser.enable_double_receipts) {
                        setTimeout(() => {
                            this.printReceipt(response.response)
                        }, 5000);
                    }
                } else {
                    Swal({
                        title: 'Print Receipts',
                        text: 'Do You Want to Print Receipt ?',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#00448b',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'Continue Without Printing',
                        allowOutsideClick: false
                    }).then(result => {
                        if (result.value) {
                            this.store_receipt_data = response.response
                            this.printReceipt(response.response)
                            if(this.loggedInUser.enable_double_receipts) {
                                setTimeout(() => {
                                    this.printReceipt(response.response)
                                }, 5000);
                            }
                        } else {
                            this.$modal.hide(this.modal_name)
                        }
                    })
                }
            }
            catch(err) {
                console.log(err)
            }
        },
        paymentSuccessPrintReceiptTicket(value) {
            if(value.status == 'success') {
                if(this.is_call_print_code == false) {
                    this.is_call_print_code = true;
                    if(this.loggedInUser.role != 'customer') {
                        if(this.fastPay || this.pay_from_checkout) {
                            // console.log(this.fastPay, this.pay_from_checkout)
                            // console.log(this.ticket_details_for_print)
                            let ticket_data = this.ticket_details_for_print
                            if(ticket_data && ticket_data.length){
                                if(ticket_data.length == 1){
                                    this.store_ticket_data = ticket_data[0]
                                    this.printTicket(ticket_data[0])
                                }
                                else if(ticket_data.length > 1){
                                    ticket_data.forEach(x => {
                                        this.store_ticket_data = x
                                        this.printTicket(this.store_ticket_data)
                                    })
                                }
                            }
                        }
                        setTimeout(() => {
                            this.printReceiptForOnlinePay();
                        }, 3000);
                    }
                }
            }
        },
        async getTicketDetailsForCanAndPay(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('/orders/payment/get_ticket_details', params)
                try {
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(err) {
                    this.$toasted.global.error(err.response.reason)
                }
            })
        },
        async paymentSuccessFromScanAndPayPrintReceiptTicket(value) {
            if(value.status == 'success') {
                if(this.fastPay || this.pay_from_checkout) {
                    let params = {
                        "fast_pay": this.fastPay,
                        "is_prepaid": this.pay_from_checkout,
                        "order_id": this.summary._id
                    }
                    let response = await this.getTicketDetailsForCanAndPay(params)
                    let ticket_data = response.response
                    if(ticket_data && ticket_data.length){
                        if(ticket_data.length == 1){
                            this.store_ticket_data = ticket_data[0]
                            this.printTicket(ticket_data[0])
                        }
                        else if(ticket_data.length > 1){
                            ticket_data.forEach(x => {
                                this.store_ticket_data = x
                                this.printTicket(this.store_ticket_data)
                            })
                        }
                    }
                }
                setTimeout(() => {
                    this.printReceiptForOnlinePay();
                }, 3000);
            }
        }
    },
    computed:{
        total_cash_payment() {
            let cash_payment  = 0
            this.payment.forEach(pay => {
                if(pay.mode == 'CASH') {
                    if(pay.type == 'AMOUNT'){
                        cash_payment += parseFloat(pay.share_total)
                    }
                    else{
                        cash_payment += parseFloat(pay.percent_value)
                    }
                }
            })
            return cash_payment;
        },
        total_card_payment() {
            let card_payment  = 0
            this.payment.forEach(pay => {
                if(pay.mode == 'CARD') {
                    if(pay.type == 'AMOUNT'){
                        card_payment += parseFloat(pay.share_total)
                    }
                    else{
                        card_payment += parseFloat(pay.percent_value)
                    }
                }
            })
            return card_payment
        },
        total_other_payment() {
            let other_payment = 0
            this.payment.forEach(pay => {
                if(pay.mode == 'OTHERS') {
                    if(pay.type == 'AMOUNT'){
                        other_payment += parseFloat(pay.share_total)
                    }
                    else{
                        other_payment += parseFloat(pay.percent_value)
                    }
                }
            })
            return other_payment
        },
        total_amount() {
            if(this.splitType == 'AMOUNT'){
                return this.payment.reduce((total, payment) => total += parseFloat(payment.share_total), 0)
            }
            else{
                return this.payment.reduce((total, payment) => total += parseFloat(payment.amount), 0)
            }
        },
        cash_remaining() {
            if (this.cash_tendered && this.splitBill == true) {
                return this.payment.reduce((total, payment) => total += parseFloat(payment.cash_returned), 0)
            }
            else if(this.cash_tendered && this.splitBill == false){
                return parseFloat(this.cash_tendered - this.summary.grand_total_after_deposit_unformatted)
            }
            return 0
        },
        due_value(){
            if (this.cash_tendered) {
                return parseFloat(this.cash_tendered - this.summary.grand_total_after_deposit_unformatted)<0?parseFloat(this.cash_tendered - this.grand_total_after_deposit_unformatted):0
            }
            return 0
        },
        change_remaining(){
            return this.summary.grand_total_after_deposit_unformatted - this.total_amount
        },
        total_cash:{
            get: function(){
                // if (this.cash_tendered) {
                return this.payment.reduce((total, payment) => total += parseFloat(payment.cash_received), 0)
                // }
                // return 0
            },
            set: function(newValue){

            }
        },
        IsProceedShown: function() {
            let total = 0;
            if(this.splitBill == false){
                if(this.summary.payments[0].mode == 'CASH'){
                    return parseFloat(this.cash_tendered) >= this.summary.grand_total_after_deposit_unformatted
                }
                else {
                    return true
                }
            }
            else{
                let boolFlag = true
                this.payment.forEach(payment => {
                    if(payment.type == 'AMOUNT'){
                        if(payment.mode == 'CASH' && payment.share_total > payment.cash_received) {
                            boolFlag = false
                        }
                    }
                    else{
                        if(payment.mode == 'CASH' && payment.amount > payment.cash_received) {
                            boolFlag = false
                        }
                    }
                })
                return this.summary.grand_total_after_deposit_unformatted == this.total_amount.toFixed(2) && boolFlag
            }
        },
        showCashTendered(){
        let flag = false
            this.payment.forEach( payment => {
                if(payment.mode == 'CASH'){
                    flag = true
                    return flag
                }
            })
            return flag
        },
        loggedInUser() {
            return this.$store.getters.loggedInUser;
        },
        fcmDetails() {
            return this.$store.getters.fcmDetails;
        }
    },
    mounted() {
        this.safariTrue = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        EventBus.$on("closePaymentProcess", () => {
            this.closePaymentProcessing();
        });
        this.getOpenGuest();
        this.getSearchCustomer();
        if(this.summary.customer_email) {
            this.summary.customer_email = this.summary.customer_email.replaceAll(/\s/g,'')
        } else {
            this.summary['customer_email'] = ''
        }

        if(this.is_credit_card_used) {
            // this.summary.payments[0].mode = 'CARD'
            this.payment_type_by_credit_card = true;
            // this.summary.payments[0].card_type = 'CREDIT CARD'
            if(this.summary.payments) {
                this.summary.payments.forEach((payment, index) => {
                    payment.mode = 'CARD';
                    payment.card_type = 'CREDIT CARD';
                })
            }
            // console.log(this.payment);
        }
        // EventBus.$on("payment_success_failure", (value) => {
        //     console.log('Hello Success')
            
        // })
    },
    destroyed() {
        EventBus.$off("closePaymentProcess");
    },
    created(){
        const dict = {
            custom: {
                recieptEmail: {
                    required: () => 'Receipt Email is Required',
                    email: () => 'Email should be of Valid Format'
                },
                card_number: {
                    required: () => 'Card Number is Required',
                    max:() => 'Card Number Should Have 4 Digits',
                    min:() => 'Card Number must contain minimum 4 Digits',
                    regex:() => 'Card Number is not Valid'
                },
                name_on_card: {
                    required: () => 'Name on Card is Required',
                    regex:() => 'Name on Card is not Valid'
                },
                bank_name: {
                    required: () => 'Bank Name is Required',
                    regex:() => 'Bank Name is not Valid'
                },
                reference_id: {
                    required: () => 'Reference ID is Required',
                    regex:() => 'Reference ID is not Valid'
                }
            }
        }
        this.$validator.localize('en', dict)
        // this.recipient_email = this.summary.customer_email
        this.get_all_cards()
        this.get_all_payment_methods_web()
    },
    watch:{
        // count_down_session_time_out: function(val) {
        //     if (val == 0) {
        //         // this.payment_qr_code_show = false
        //         this.payment_qr_code_session_time_out = true
        //         setTimeout(() => {
        //             // this.$modal.hide("paymentQrCodeModal");
        //             this.$modal.show("paymentQrCodeSessionTimeOut");
        //         }, 1000);
        //     }
        // },
        remaining_amount(){
            if (this.remaining_amount < 10.00){
                let number_of_bills = parseFloat(this.payment.length)
                if(Math.sign(this.remaining_amount) == -1){
                    this.payment[number_of_bills -1]['amount'] = (Number(this.payment[number_of_bills -1]['amount']) - this.remaining_amount).toFixed(2)
                }
                else {
                    this.payment[number_of_bills -1]['amount'] = (this.remaining_amount + Number(this.payment[number_of_bills -1]['amount'])).toFixed(2)
                }
                this.remaining_amount = 0.00
                // this.remainingAmount();
                // this.handlerRemainingAmount()
            }
        }
    },
};
</script>
<style scoped>
.pay-method-block {
    background-image: linear-gradient(to bottom, #005dae, #004fe3);
    padding: 12px 8px;
    color: #fff;
    height: 140px;
}
.payment-header-text {
    font-size: 15px;
    font-weight: 600;
    line-height: 1.21;
    text-align: left;
    color: #fff;
    margin-bottom: 12px;
}
.pay-method-block .list-title {
    position: absolute;
    top: -26px;
    left: 0;
    right: 0px;
    margin: 0 auto;
}
.ttl-amount {
    background-color: #fff;
    color: #00448b;
    padding: 6px 16px;
    font-size: 13px;
    font-weight: 600;
    border-radius: 20px;
    width: 220px;
    text-align: center;
    margin: 0 auto;
}
.payment-list-container {
    display: block;
    text-decoration: none;
    border-radius: 7px;
    border: solid 0.5px #ffffff;
    background-color: #0059a5;
}
.payment-list-container .payment-list-media {
    width: 40px;
    position: relative;
    display: table-cell;
    padding: 10px;
    vertical-align: middle;
}
.payment-list-container .payment-list-media img {
    display: block;
    width: 35px;;
    height: 35px;
    text-align: center;
    font-size: 1.125rem;
    line-height: 35px;
}
.payment-list-content {
    color: #fff;
}
.payment-list-container>div {
    display: table-cell;
    padding: .625rem;
    vertical-align: middle;
}
.payment-list-container>div+div {
    padding-left: 0;
}
.payment-list-content h4.payment-title {
    font-size: 14px;
    color: #fff;
    line-height: 1rem;
    font-weight: 600;
    margin: 0;
}
div.payment-desc {
    font-size: 13px;
    line-height: 1rem;
    font-weight: 500;
    margin-top: 10px;
}
div.payment-desc.cashcolor {
    color: #79ffd3;
}
div.payment-desc.cardcolor {
    color: #93caff;
}
div.payment-desc.othercolor {
    color: #ffdea8;
}
.hollow-dots-spinner.spinner-relative {
  position: relative !important;
}
.colorRed{
  color: #db0000;
}
.grandRemanining{
  background-color: #00448b;
  color: #fff;
}
.grandTotal {
  background-color: #00448b!important;
  color: #fff!important;
  border-right: solid 1px #fff;
}
.borderBottom{
  border-bottom: solid 1px #cdd2da;
}
.grandTotalValue{
  padding: 2px 12px;
  border-radius: 15px;
}
.horizontalLine{
  border-top: dashed 2px #94bef0;
}
.DollarTab {
  border: 1px solid #00448b;
  border-right: 2px solid #00448b;
  height: 34px !important;
  color: #00448b;
  background: transparent;
  line-height: 34px !important;
  text-transform: none !important;
  padding: 5px 18px 5px 18px;
  font-size: 14px !important;
  cursor: pointer;
  font-weight: 500;
  border-radius: 20px 0 0 20px !important;
}
.PerTab {
  border: 1px solid #00448b;
  height: 34px !important;
  color: #00448b;
  background: transparent;
  line-height: 34px !important;
  text-transform: none !important;
  padding: 5px 18px 5px 18px;
  font-size: 14px !important;
  font-weight: 500;
  cursor: pointer;
  border-left: none !important;
  border-radius: 0 20px 20px 0 !important;
  margin-left: -3px;
}
.activeClassAmount {
  background: #00448b !important;
  color: #fff !important;
  font-weight: bold;
}
.resetSettingButton{
  background-image: linear-gradient(to bottom, #1a9347, #1fb155 51%, #4fd882) !important;
  background-color: none !important;
  border-color: none !important;
}
.splitEquallyButton{
  background-image: linear-gradient(to bottom, #f9a00b, #f9a00b 51%, #ffd184) !important;
  background-color: none !important;
  border-color: none !important;
}


.split-uk-grid{
    display:-ms-flexbox;
    display:-webkit-flex;
    display:flex;
    -ms-flex-wrap:wrap;
    -webkit-flex-wrap:wrap;
    flex-wrap:wrap;
    margin:0;
    padding:0;
    list-style:none
}
.split-uk-grid:after,.split-uk-grid:before{
    content:"";
    display:block;
    overflow:hidden
}
.split-uk-grid:after{
    clear:both
}
.split-uk-grid>*{
    -ms-flex:none;
    -webkit-flex:none;
    flex:none;
    margin:0;
    float:left
}
.split-uk-grid>*>:last-child{
    margin-bottom:0
}
.split-uk-grid>*{
    padding-left:25px
}
.split-uk-grid+.split-uk-grid,.split-uk-grid-margin,.split-uk-grid>*>.split-uk-panel+.split-uk-panel{
    margin-top:25px
}
@media (min-width:1220px){
    .split-uk-grid>*{
        padding-left:35px
    }
    .split-uk-grid+.split-uk-grid,.split-uk-grid-margin,.split-uk-grid>*>.split-uk-panel+.split-uk-panel{
        margin-top:35px
    }
}
.split-uk-grid-collapse{
    margin-left:0
}
.split-uk-grid-collapse>*{
    padding-left:0
}
.split-uk-grid-collapse+.split-uk-grid-collapse,.split-uk-grid-collapse>*>.split-uk-panel+.split-uk-panel,.split-uk-grid-collapse>.split-uk-grid-margin{
    margin-top:0
}
.split-uk-grid-small{
    margin-left:-10px
}
.split-uk-grid-small>*{
    padding-left:10px
}
.split-uk-grid-small+.split-uk-grid-small,.split-uk-grid-small>*>.split-uk-panel+.split-uk-panel,.split-uk-grid-small>.split-uk-grid-margin{
    margin-top:10px
}
.split-uk-grid-medium>*{
    padding-left:25px
}
.split-uk-grid-medium+.split-uk-grid-medium,.split-uk-grid-medium>*>.split-uk-panel+.split-uk-panel,.split-uk-grid-medium>.split-uk-grid-margin{
    margin-top:25px
}
@media(max-width:760px){
    .pay-method-block{
        height: auto;
    }
}
@media (min-width:960px){
    .split-uk-grid-large>*{
        padding-left:35px
    }
    .split-uk-grid-large+.split-uk-grid-large,.split-uk-grid-large-margin,.split-uk-grid-large>*>.split-uk-panel+.split-uk-panel{
        margin-top:35px
    }
}
@media (min-width:1220px){
    .split-uk-grid-large{
        margin-left:-50px
    }
    .split-uk-grid-large>*{
        padding-left:50px
    }
    .split-uk-grid-large+.split-uk-grid-large,.split-uk-grid-large-margin,.split-uk-grid-large>*>.split-uk-panel+.split-uk-panel{
        margin-top:50px
    }
}
.split-uk-grid-divider>*{
    padding-left:25px;
    padding-right:25px
}
.split-uk-grid-divider>[class*=split-uk-width-9-]:nth-child(n+2),.split-uk-grid-divider>[class*=split-uk-width-1-]:not(.split-uk-width-1-1):nth-child(n+2),.split-uk-grid-divider>[class*=split-uk-width-2-]:nth-child(n+2),.split-uk-grid-divider>[class*=split-uk-width-3-]:nth-child(n+2),.split-uk-grid-divider>[class*=split-uk-width-4-]:nth-child(n+2),.split-uk-grid-divider>[class*=split-uk-width-5-]:nth-child(n+2),.split-uk-grid-divider>[class*=split-uk-width-6-]:nth-child(n+2),.split-uk-grid-divider>[class*=split-uk-width-7-]:nth-child(n+2),.split-uk-grid-divider>[class*=split-uk-width-8-]:nth-child(n+2){
    border-left:1px solid #ddd
}
@media (min-width:768px){
    .split-uk-grid-divider>[class*=split-uk-width-medium-]:not(.split-uk-width-medium-1-1):nth-child(n+2){
        border-left:1px solid #ddd
    }
}
@media (min-width:960px){
    .split-uk-grid-divider>[class*=split-uk-width-large-]:not(.split-uk-width-large-1-1):nth-child(n+2){
        border-left:1px solid #ddd
    }
}
@media (min-width:1220px){
    .split-uk-grid-divider>*{
        padding-left:35px;
        padding-right:35px
    }
    .split-uk-grid-divider:empty{
        margin-top:35px;
        margin-bottom:35px
    }
}
.split-uk-grid-divider:empty{
    margin-top:25px;
    margin-bottom:25px;
    border-top:1px solid #ddd
}
.split-uk-grid-match>*{
    display:-ms-flexbox;
    display:-webkit-flex;
    display:flex;
    -ms-flex-wrap:wrap;
    -webkit-flex-wrap:wrap;
    flex-wrap:wrap
}
.split-uk-grid-match>*>*{
    -ms-flex:none;
    -webkit-flex:none;
    flex:none;
    box-sizing:border-box;
    width:100%
}
/* [class*=split-uk-grid-width]>*{
    box-sizing:border-box;
    width:100%
} */
.split-uk-grid-width-1-2>*{
    width:50%
}
.split-uk-grid-width-1-3>*{
    width:33.333%
}
.split-uk-grid-width-1-4>*{
    width:25%
}
.split-uk-grid-width-1-5>*{
    width:20%
}
.split-uk-grid-width-1-6>*{
    width:16.666%
}
.split-uk-grid-width-1-10>*{
    width:10%
}
.split-uk-grid-width-auto>*{
    width:auto
}
@media (min-width:480px){
    .split-uk-grid-width-small-1-1>*{
        width:100%
    }
    .split-uk-grid-width-small-1-2>*{
        width:50%
    }
    .split-uk-grid-width-small-1-49>*{
        width:49%
    }
    .split-uk-grid-width-small-1-3>*{
        width:33.333%
    }
    .split-uk-grid-width-small-1-4>*{
        width:25%
    }
    .split-uk-grid-width-small-1-5>*{
        width:20%
    }
    .split-uk-grid-width-small-1-6>*{
        width:16.666%
    }
    .split-uk-grid-width-small-1-10>*{
        width:10%
    }
}
@media (min-width:768px){
    .split-uk-grid-width-medium-1-1>*{
        width:100%
    }
    .split-uk-grid-width-medium-1-2>*{
        width:50%
    }
    .split-uk-grid-width-medium-1-3>*{
        width:33.333%
    }
    .split-uk-grid-width-medium-1-32>*{
        width:32.333%
    }
    .split-uk-grid-width-medium-1-4>*{
        width:25%
    }
    .split-uk-grid-width-medium-1-5>*{
        width:20%
    }
    .split-uk-grid-width-medium-1-6>*{
        width:16.666%
    }
    .split-uk-grid-width-medium-1-10>*{
        width:10%
    }
}
@media (min-width:960px){
    .split-uk-grid-width-large-1-1>*{
        width:100%
    }
    .split-uk-grid-width-large-1-2>*{
        width:50%
    }
    .split-uk-grid-width-large-1-3>*{
        width:33.333%
    }
    .split-uk-grid-width-large-1-31>*{
        width:31.5%
    }
    .split-uk-grid-width-large-1-4>*{
        width:25%
    }
    .split-uk-grid-width-large-1-24>*{
        width:24%
    }
    .split-uk-grid-width-large-1-5>*{
        width:20%
    }
    .split-uk-grid-width-large-1-6>*{
        width:16.666%
    }
    .split-uk-grid-width-large-1-10>*{
        width:10%
    }
}
@media (min-width:1220px){
    .split-uk-grid-width-xlarge-1-1>*{
        width:100%
    }
    .split-uk-grid-width-xlarge-1-2>*{
        width:50%
    }
    .split-uk-grid-width-xlarge-1-3>*{
        width:33.333%
    }
    .split-uk-grid-width-xlarge-1-4>*{
        width:25%
    }
    .split-uk-grid-width-xlarge-1-5>*{
        width:20%
    }
    .split-uk-grid-width-xlarge-1-23>* {
        width: 23%;
    }
    .split-uk-grid-width-xlarge-1-15>*{
        width:15.866%
    }
    .split-uk-grid-width-xlarge-1-6>*{
        width:16.666%
    }
    .split-uk-grid-width-xlarge-1-16>*{
        width:16% !important;
    }
    .split-uk-grid-width-xlarge-1-10>*{
        width:10%
    }
}
[class*=split-uk-width]{
    box-sizing:border-box;
    width:100%
}
.split-uk-width-1-1{
    width:100%
}
.split-uk-width-1-2,.split-uk-width-2-4,.split-uk-width-3-6,.split-uk-width-5-10{
    width:50%
}
.split-uk-width-1-3,.split-uk-width-2-6{
    width:33.333%
}
.split-uk-width-2-3,.split-uk-width-4-6{
    width:66.666%
}
.split-uk-width-1-4{
    width:25%
}
.split-uk-width-3-4{
    width:75%
}
.split-uk-width-1-5,.split-uk-width-2-10{
    width:20%
}
.split-uk-width-2-5,.split-uk-width-4-10{
    width:40%
}
.split-uk-width-3-5,.split-uk-width-6-10{
    width:60%
}
.split-uk-width-4-5,.split-uk-width-8-10{
    width:80%
}
.split-uk-width-1-6{
    width:16.666%
}
.split-uk-width-5-6{
    width:83.333%
}
.split-uk-width-1-10{
    width:10%
}
.split-uk-width-3-10{
    width:30%
}
.split-uk-width-7-10{
    width:70%
}
.split-uk-width-9-10{
    width:90%
}
@media (min-width:480px){
    .split-uk-width-small-1-1{
        width:100%
    }
    .split-uk-width-small-1-2,.split-uk-width-small-2-4,.split-uk-width-small-3-6,.split-uk-width-small-5-10{
        width:50%
    }
    .split-uk-width-small-1-3,.split-uk-width-small-2-6{
        width:33.333%
    }
    .split-uk-width-small-2-3,.split-uk-width-small-4-6{
        width:66.666%
    }
    .split-uk-width-small-1-4{
        width:25%
    }
    .split-uk-width-small-3-4{
        width:75%
    }
    .split-uk-width-small-1-5,.split-uk-width-small-2-10{
        width:20%
    }
    .split-uk-width-small-2-5,.split-uk-width-small-4-10{
        width:40%
    }
    .split-uk-width-small-3-5,.split-uk-width-small-6-10{
        width:60%
    }
    .split-uk-width-small-4-5,.split-uk-width-small-8-10{
        width:80%
    }
    .split-uk-width-small-1-6{
        width:16.666%
    }
    .split-uk-width-small-5-6{
        width:83.333%
    }
    .split-uk-width-small-1-10{
        width:10%
    }
    .split-uk-width-small-3-10{
        width:30%
    }
    .split-uk-width-small-7-10{
        width:70%
    }
    .split-uk-width-small-9-10{
        width:90%
    }
}
@media (min-width:768px){
    .split-uk-width-medium-1-1{
        width:100%
    }
    .split-uk-width-medium-1-2,.split-uk-width-medium-2-4,.split-uk-width-medium-3-6,.split-uk-width-medium-5-10{
        width:50%
    }
    .split-uk-width-medium-1-3,.split-uk-width-medium-2-6{
        width:33.333%
    }
    .split-uk-width-medium-2-3,.split-uk-width-medium-4-6{
        width:66.666%
    }
    .split-uk-width-medium-1-4{
        width:25%
    }
    .split-uk-width-medium-3-4{
        width:75%
    }
    .split-uk-width-medium-1-5,.split-uk-width-medium-2-10{
        width:20%
    }
    .split-uk-width-medium-2-5,.split-uk-width-medium-4-10{
        width:40%
    }
    .split-uk-width-medium-3-5,.split-uk-width-medium-6-10{
        width:60%
    }
    .split-uk-width-medium-4-5,.split-uk-width-medium-8-10{
        width:80%
    }
    .split-uk-width-medium-1-6{
        width:16.666%
    }
    .split-uk-width-medium-5-6{
        width:83.333%
    }
    .split-uk-width-medium-1-10{
        width:10%
    }
    .split-uk-width-medium-3-10{
        width:30%
    }
    .split-uk-width-medium-7-10{
        width:70%
    }
    .split-uk-width-medium-9-10{
        width:90%
    }
}
@media (min-width:960px){
    .split-uk-width-large-1-1{
        width:100%
    }
    .split-uk-width-large-1-2,.split-uk-width-large-2-4,.split-uk-width-large-3-6,.split-uk-width-large-5-10{
        width:50%
    }
    .split-uk-width-large-1-3,.split-uk-width-large-2-6{
        width:33.333%
    }
    .split-uk-width-large-2-3,.split-uk-width-large-4-6{
        width:66.666%
    }
    .split-uk-width-large-1-4{
        width:25%
    }
    .split-uk-width-large-3-4{
        width:75%
    }
    .split-uk-width-large-1-5,.split-uk-width-large-2-10{
        width:20%
    }
    .split-uk-width-large-2-5,.split-uk-width-large-4-10{
        width:40%
    }
    .split-uk-width-large-3-5,.split-uk-width-large-6-10{
        width:60%
    }
    .split-uk-width-large-4-5,.split-uk-width-large-8-10{
        width:80%
    }
    .split-uk-width-large-1-6{
        width:16.666%
    }
    .split-uk-width-large-5-6{
        width:83.333%
    }
    .split-uk-width-large-1-10{
        width:10%
    }
    .split-uk-width-large-3-10{
        width:30%
    }
    .split-uk-width-large-7-10{
        width:70%
    }
    .split-uk-width-large-9-10{
        width:90%
    }
}
@media (min-width:1220px){
    .split-uk-width-xlarge-1-1{
        width:100%
    }
    .split-uk-width-xlarge-1-2,.split-uk-width-xlarge-2-4,.split-uk-width-xlarge-3-6,.split-uk-width-xlarge-5-10{
        width:50%
    }
    .split-uk-width-xlarge-1-3,.split-uk-width-xlarge-2-6{
        width:33.333%
    }
    .split-uk-width-xlarge-2-3,.split-uk-width-xlarge-4-6{
        width:66.666%
    }
    .split-uk-width-xlarge-1-4{
        width:25%
    }
    .split-uk-width-xlarge-3-4{
        width:75%
    }
    .split-uk-width-xlarge-1-5,.split-uk-width-xlarge-2-10{
        width:20%
    }
    .split-uk-width-xlarge-2-5,.split-uk-width-xlarge-4-10{
        width:40%
    }
    .split-uk-width-xlarge-3-5,.split-uk-width-xlarge-6-10{
        width:60%
    }
    .split-uk-width-xlarge-4-5,.split-uk-width-xlarge-8-10{
        width:80%
    }
    .split-uk-width-xlarge-1-6{
        width:16.666%
    }
    .split-uk-width-xlarge-5-6{
        width:83.333%
    }
    .split-uk-width-xlarge-1-10{
        width:10%
    }
    .split-uk-width-xlarge-3-10{
        width:30%
    }
    .split-uk-width-xlarge-7-10{
        width:70%
    }
    .split-uk-width-xlarge-9-10{
        width:90%
    }
}
@media (min-width:768px){
    [class*=split-uk-push-],[class*=split-uk-pull-]{
        position:relative
    }
    .split-uk-push-1-2,.split-uk-push-2-4,.split-uk-push-3-6,.split-uk-push-5-10{
        left:50%
    }
    .split-uk-push-1-3,.split-uk-push-2-6{
        left:33.333%
    }
    .split-uk-push-2-3,.split-uk-push-4-6{
        left:66.666%
    }
    .split-uk-push-1-4{
        left:25%
    }
    .split-uk-push-3-4{
        left:75%
    }
    .split-uk-push-1-5,.split-uk-push-2-10{
        left:20%
    }
    .split-uk-push-2-5,.split-uk-push-4-10{
        left:40%
    }
    .split-uk-push-3-5,.split-uk-push-6-10{
        left:60%
    }
    .split-uk-push-4-5,.split-uk-push-8-10{
        left:80%
    }
    .split-uk-push-1-6{
        left:16.666%
    }
    .split-uk-push-5-6{
        left:83.333%
    }
    .split-uk-push-1-10{
        left:10%
    }
    .split-uk-push-3-10{
        left:30%
    }
    .split-uk-push-7-10{
        left:70%
    }
    .split-uk-push-9-10{
        left:90%
    }
    .split-uk-pull-1-2,.split-uk-pull-2-4,.split-uk-pull-3-6,.split-uk-pull-5-10{
        left:-50%
    }
    .split-uk-pull-1-3,.split-uk-pull-2-6{
        left:-33.333%
    }
    .split-uk-pull-2-3,.split-uk-pull-4-6{
        left:-66.666%
    }
    .split-uk-pull-1-4{
        left:-25%
    }
    .split-uk-pull-3-4{
        left:-75%
    }
    .split-uk-pull-1-5,.split-uk-pull-2-10{
        left:-20%
    }
    .split-uk-pull-2-5,.split-uk-pull-4-10{
        left:-40%
    }
    .split-uk-pull-3-5,.split-uk-pull-6-10{
        left:-60%
    }
    .split-uk-pull-4-5,.split-uk-pull-8-10{
        left:-80%
    }
    .split-uk-pull-1-6{
        left:-16.666%
    }
    .split-uk-pull-5-6{
        left:-83.333%
    }
    .split-uk-pull-1-10{
        left:-10%
    }
    .split-uk-pull-3-10{
        left:-30%
    }
    .split-uk-pull-7-10{
        left:-70%
    }
    .split-uk-pull-9-10{
        left:-90%
    }
}
.split-uk-panel{
    display:block;
    position:relative
}
.split-uk-panel,.split-uk-panel:hover{
    text-decoration:none
}
.split-uk-panel:after,.split-uk-panel:before{
    content:"";
    display:table
}
.split-uk-panel:after{
    clear:both
}
.split-uk-panel>:not(.split-uk-panel-title):last-child{
    margin-bottom:0
}
.split-uk-panel-title{
    margin-top:0;
    margin-bottom:15px;
    font-size:18px;
    line-height:24px;
    font-weight:400;
    text-transform:none;
    color:#444
}
.split-uk-panel-badge{
    position:absolute;
    top:0;
    right:0;
    z-index:1
}
.split-uk-panel-teaser{
    margin-bottom:15px
}
.split-uk-panel-body{
    padding:15px
}
.split-uk-panel-box{
    padding:15px;
    background:#fafafa;
    color:#444;
    border:1px solid #ddd;
    border-radius:4px
}
.split-uk-panel-box-hover:hover{
    color:#444
}
.split-uk-panel-box .split-uk-panel-title{
    color:#444
}
.split-uk-panel-box .split-uk-panel-badge{
    top:10px;
    right:10px
}
.split-uk-panel-box>.split-uk-panel-teaser{
    margin-top:-16px;
    margin-left:-16px;
    margin-right:-16px
}
.split-uk-panel-box>.split-uk-nav-side{
    margin:0 -15px
}
.split-uk-panel-box-primary{
    background-color:#ebf7fd;
    color:#2d7091;
    border-color:rgba(45,112,145,.3)
}
.split-uk-panel-box-primary-hover:hover{
    color:#2d7091
}
.split-uk-panel-box-primary .split-uk-panel-title{
    color:#2d7091
}
.split-uk-panel-box-secondary{
    background-color:#fff;
    color:#444
}
.split-uk-panel-box-secondary-hover:hover{
    color:#444
}
.split-uk-panel-box-secondary .split-uk-panel-title{
    color:#444
}
.split-uk-panel-hover{
    padding:15px;
    color:#444;
    border:1px solid transparent;
    border-radius:4px
}
.split-uk-panel-hover:hover{
    background:#fafafa;
    color:#444;
    border-color:#ddd
}
.split-uk-panel-hover .split-uk-panel-badge{
    top:10px;
    right:10px
}
.split-uk-panel-hover>.split-uk-panel-teaser{
    margin-top:-16px;
    margin-left:-16px;
    margin-right:-16px
}
.split-uk-panel-header .split-uk-panel-title{
    padding-bottom:10px;
    border-bottom:1px solid #ddd;
    color:#444
}
.split-uk-panel-space{
    padding:30px
}
.split-uk-panel-space .split-uk-panel-badge{
    top:30px;
    right:30px
}
.split-uk-panel+.split-uk-panel-divider{
    margin-top:50px!important
}
.split-uk-panel+.split-uk-panel-divider:before{
    content:"";
    display:block;
    position:absolute;
    top:-25px;
    left:0;
    right:0;
    border-top:1px solid #ddd
}
@media (min-width:1220px){
    .split-uk-panel+.split-uk-panel-divider{
        margin-top:70px!important
    }
    .split-uk-panel+.split-uk-panel-divider:before{
        top:-35px
    }
}
.split-uk-panel-box .split-uk-panel-teaser{
    border-top-left-radius:4px;
    border-top-right-radius:4px;
    overflow:hidden;
    -webkit-transform:translateZ(0)
}
.split-uk-block{
    position:relative;
    box-sizing:border-box;
    padding-top:20px;
    padding-bottom:20px
}
@media (min-width:768px){
    .split-uk-block{
        padding-top:50px;
        padding-bottom:50px
    }
}
.split-uk-block:after,.split-uk-block:before{
    content:"";
    display:table
}
.split-uk-block:after{
    clear:both
}
.split-uk-block>:last-child{
    margin-bottom:0
}
.split-uk-block-large{
    padding-top:20px;
    padding-bottom:20px
}
@media (min-width:768px){
    .split-uk-block-large{
        padding-top:50px;
        padding-bottom:50px
    }
}
@media (min-width:960px){
    .split-uk-block-large{
        padding-top:100px;
        padding-bottom:100px
    }
}
.split-uk-block-default{
    background:#fff
}
.split-uk-block-muted{
    background:#f9f9f9
}
.split-uk-block-primary{
    background:#00a8e6
}
.split-uk-block-secondary{
    background:#222
}
.split-uk-block-default+.split-uk-block-default,.split-uk-block-muted+.split-uk-block-muted,.split-uk-block-primary+.split-uk-block-primary,.split-uk-block-secondary+.split-uk-block-secondary{
    padding-top:0
}
.md-card.dashboarddetailswidget-card{
    position:relative;
    border-radius: 6px;
    box-shadow: 0 1px 11px 0 rgba(0, 0, 0, 0.2);
    background-image: linear-gradient(140deg, #005dae -9%, #004fe3 95%);
    cursor: pointer;
}
.md-card.dashboarddetailswidget-card+.md-card.dashboarddetailswidget-card,.md-card.dashboarddetailswidget-card+.split-uk-grid,.split-uk-grid+.md-card.dashboarddetailswidget-card{
    margin-top:25px
}
.md-card.dashboarddetailswidget-card .full_width_in_card{
    padding:16px 24px;
    background:rgba(0,0,0,.085)
}
.md-card.dashboarddetailswidget-card .md-card-toolbar{
    height:48px;
    padding:0 16px;
    border-bottom:1px solid rgba(0,0,0,.12);
    background:#fff
}
.md-card.dashboarddetailswidget-card .md-card-toolbar:after,.md-card.dashboarddetailswidget-card .md-card-toolbar:before{
    content:" ";
    display:table
}
.md-card.dashboarddetailswidget-card .md-card-toolbar:after{
    clear:both
}
.md-card.dashboarddetailswidget-card .md-card-toolbar:after,.md-card.dashboarddetailswidget-card .md-card-toolbar:before{
    content:" ";
    display:table
}
.md-card.dashboarddetailswidget-card .md-card-toolbar:after{
    clear:both
}
.md-card.dashboarddetailswidget-card .md-card-toolbar-heading-text{
    font:500 14px/50px Roboto,sans-serif;
    color:#212121;
    margin:0;
    float:left;
    overflow:hidden;
    height:48px
}
.md-card.dashboarddetailswidget-card .md-card-toolbar-heading-text.large{
    font-size:18px;
    font-weight:400
}
.md-card.dashboarddetailswidget-card .md-card-toolbar .md-toggle-group{
    float:left;
    margin:8px 0 0 16px
}
.md-card.dashboarddetailswidget-card .md-card-toolbar .md-toggle-group.md-toggle-group-small{
    margin-top:10px
}
.md-card.dashboarddetailswidget-card .md-card-toolbar .md-card-toolbar-actions{
    float:right;
    padding-top:10px
}
.md-card.dashboarddetailswidget-card .md-card-toolbar .md-card-toolbar-actions .split-uk-open .md-card-toolbar-icon{
    background:rgba(0,0,0,.085);
    color:#212121
}
.md-card.dashboarddetailswidget-card .md-card-toolbar .md-card-toolbar-actions .md-card-dropdown{
    display:inline-block;
    position:relative
}
.md-card.dashboarddetailswidget-card .md-card-toolbar .md-card-toolbar-actions .selectize-control{
    min-width:220px;
    margin-top:-3px
}
.md-card.dashboarddetailswidget-card .md-card-toolbar .md-card-toolbar-actions .selectize-control .selectize-input{
    min-height:30px;
    padding:4px 8px
}
.md-card.dashboarddetailswidget-card .md-card-toolbar .md-card-toolbar-actions .selectize-control .selectize-dropdown{
    margin-top:-34px
}
.md-card.dashboarddetailswidget-card .md-card-toolbar .md-icon+.md-card-dropdown{
    margin-left:4px
}
.md-card.dashboarddetailswidget-card .md-card-toolbar .md-card-fullscreen-deactivate{
    margin:9px 8px 0 0
}
.md-card.dashboarddetailswidget-card .md-card-toolbar-input{
    border:none;
    font:400 18px/24px Roboto,sans-serif;
    height:auto;
    background:0 0!important;
    padding:12px 0;
    width:50%;
    -webkit-box-sizing:border-box;
    box-sizing:border-box
}
.md-card.dashboarddetailswidget-card .md-card-toolbar .split-uk-tab{
    margin-top:-2px;
    border-bottom:none
}
.md-card.dashboarddetailswidget-card .md-card-toolbar .split-uk-tab li>a{
    padding:10px 8px!important
}
.md-card.dashboarddetailswidget-card .md-card-head{
    height:125px;
    position:relative;
    border-bottom:1px solid transparent
}
.md-card.dashboarddetailswidget-card .md-card-head-menu{
    position:absolute;
    right:8px;
    top:8px;
    border: 1px solid #e0e3e6;
    border-radius: 4px;
    background:#fff;
}
.img-div {
    background-color: rgba(255, 255, 255, 0);
    width: 86px;
    height: 86px;
    border-radius: 50%;
    margin-top: 20px;
    display: inline-block;
}
.md-card.dashboarddetailswidget-card .md-card-head-avatar{
    width:75px;
    height:75px;
    border-radius:50%;
    margin-top:4px;
    display:inline-block
}
.md-card.dashboarddetailswidget-card .md-card-head-text{
    padding:10px 5px;
    font-size: 16px;
    color:#fff;
    margin:0;
    font-weight: 600;
    line-height: 1.19;
}
.md-card.dashboarddetailswidget-card .md-card-head-text span:not(.listNavSelector){
    display:block;
    font-size: 13px;
    margin-top: 5px;
    margin-bottom: 0px;
    color: #fff;
    font-weight: 400;
    line-height: 1.25;
}
.md-card.dashboarddetailswidget-card .md-card-head-text.text_dark{
    color: #303031 !important
}
.md-card.dashboarddetailswidget-card .md-card-head-text-over{
    background-image:-webkit-gradient(linear,left top,left bottom,from(rgba(0,0,0,.65)),to(rgba(0,0,0,0)));
    background-image:linear-gradient(to bottom,rgba(0,0,0,.65) 0,rgba(0,0,0,0) 100%)
}
.md-card.dashboarddetailswidget-card .md-card-head-subtext{
    position:absolute;
    bottom:10px;
    left:0;
    right:0;
    padding:0 16px;
    text-align:right;
    color:#fff
}
.md-card.dashboarddetailswidget-card .md-card-head-subtext span{
    font-size:26px
}
.md-card.dashboarddetailswidget-card .md-card-head-icon{
    font-size:48px;
    color:#fff;
    vertical-align:middle
}
.md-card.dashboarddetailswidget-card .md-card-head.head_background{
    background-repeat:no-repeat;
    background-position:center center;
    background-size:cover;
    border-bottom-color:transparent
}
.md-card.dashboarddetailswidget-card .md-card-head.head_background .md-card-head-text{
    color:#fff
}
.md-card.dashboarddetailswidget-card .md-card-head.head_background_top{
    background-repeat:no-repeat;
    background-position:center top
}
.md-card.dashboarddetailswidget-card .md-card-head.head_background_bottom{
    background-repeat:no-repeat;
    background-position:center bottom
}
.md-card.dashboarddetailswidget-card .md-card-head .head_chart{
    height:100px;
    width:100%;
    position:absolute!important;
    left:0;
    top:40px
}
.md-card.dashboarddetailswidget-card .md-card-head .fitVid_player{
    width:100%;
    height:160px;
    overflow:hidden
}
.md-card.dashboarddetailswidget-card .md-card-head-img{
    height:100%;
    width: auto;
}
.md-card.dashboarddetailswidget-card .md-card-head iframe{
    height:160px
}
.md-card.dashboarddetailswidget-card .md-card-content{
    padding: 5px 12px 5px;
}
.md-card.dashboarddetailswidget-card .md-card-content.padding-reset{
    padding:0
}
.md-card.dashboarddetailswidget-card .md-card-content.large-padding{
    padding:24px 35px
}
.md-card.dashboarddetailswidget-card .md-card-content.small-padding{
    padding:8px
}
.md-card.md-card-fullscreen-activate{
    cursor:pointer
}
.md-card.md-card-fullscreen{
    position:fixed;
    z-index:9998;
    overflow-x:hidden;
    cursor:default
}
.md-card.md-card-fullscreen .md-card-fullscreen-activate{
    display:none
}
.md-card.md-card-fullscreen .md-card-fullscreen-hide{
    display:none
}
.md-card.dashboarddetailswidget-card .md-card-fullscreen-content{
    display:none;
    padding:16px
}
.md-card-fullscreen-content-hidden .md-card.dashboarddetailswidget-card .md-card-fullscreen-content{
    display:block;
    visibility:hidden;
    padding:0
}
.md-card.mdToolbar_fixed{
    overflow-y:hidden
}
.md-card.mdToolbar_fixed>.md-card-toolbar{
    position:fixed;
    left:0;
    right:0;
    top:0;
    z-index:9999;
    -webkit-box-shadow:0 3px 6px rgba(0,0,0,.16),0 3px 6px rgba(0,0,0,.23);
    box-shadow:0 3px 6px rgba(0,0,0,.16),0 3px 6px rgba(0,0,0,.23)
}
.md-card.mdToolbar_fixed>.md-card-content{
    overflow-y:scroll;
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
    position:absolute;
    width:100%;
    top:48px;
    bottom:0
}
.md-card.mdToolbar_fixed>.md-card-content .md-card-fullscreen-content{
    padding:16px 0
}
.md-card.dashboarddetailswidget-card.md-card-overlay{
    overflow:hidden;
    padding-bottom:54px
}
.md-card.dashboarddetailswidget-card .md-card-overlay .md-card-content{
    height:142px;
    overflow:hidden;
    -webkit-box-sizing:border-box;
    box-sizing:border-box
}
.md-card.dashboarddetailswidget-card .md-card-overlay .md-card-content.no_truncate{
    position:relative
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-content.no_truncate:after{
    position:absolute;
    bottom:0;
    left:0;
    right:0;
    height:8px;
    background-image:-webkit-gradient(linear,left bottom,left top,color-stop(50%,#fff),to(rgba(255,255,255,0)));
    background-image:linear-gradient(to top,#fff 50%,rgba(255,255,255,0) 100%);
    display:block;
    content:'';
    z-index:10
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-content>pre{
    margin-top:0;
    max-height:110px
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-content>pre>code{
    overflow:hidden
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-content{
    position:absolute;
    top:100%;
    left:0;
    right:0;
    padding:0 16px;
    margin-top:-54px;
    border-top:1px solid rgba(0,0,0,.12);
    text-align:left;
    bottom:0;
    background:#fff;
    z-index:10;
    -webkit-transition:all 280ms cubic-bezier(.4,0,.2,1);
    transition:all 280ms cubic-bezier(.4,0,.2,1)
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-content p{
    margin:0
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-content p+*{
    margin-top:16px
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-content p+p{
    margin-top:4px
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-header{
    padding:12px 0
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-header:after,.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-header:before{
    content:" ";
    display:table
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-header:after{
    clear:both
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-header:after,.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-header:before{
    content:" ";
    display:table
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-header:after{
    clear:both
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-header h3,.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-header h4{
    margin:0;
    text-overflow:ellipsis;
    display:inline-block;
    vertical-align:top;
    white-space:nowrap;
    overflow:hidden;
    width:100%;
    padding-right:32px;
    -webkit-box-sizing:border-box;
    box-sizing:border-box
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-header .md-icon{
    position:absolute;
    right:12px;
    top:11px
}
.md-card.dashboarddetailswidget-card.md-card-overlay-active .md-card-overlay-content{
    top:-1px;
    margin-top:0
}
.split-uk-sortable-dragged .md-card{
    -webkit-box-shadow:0 10px 20px rgba(0,0,0,.19),0 6px 6px rgba(0,0,0,.23);
    box-shadow:0 10px 20px rgba(0,0,0,.19),0 6px 6px rgba(0,0,0,.23)
}
.split-uk-sortable-dragged .md-card.dashboarddetailswidget-card canvas{
    margin:0 auto;
    display:block
}
.md-card.dashboarddetailswidget-card .heading_list{
    padding:0
}
.md-card-danger,.md-card-primary,.md-card-success,.md-card-warning{
    border-left:4px solid transparent
}
.md-card-primary{
    border-left-color:#00448b
}
.md-card-success{
    border-left-color:#7cb342
}
.md-card-danger{
    border-left-color:#e53935
}
.md-card-warning{
    border-left-color:#ffa000
}
.md-card-horizontal{
    position:relative;
    min-height:200px
}
.md-card-horizontal:after,.md-card-horizontal:before{
    content:" ";
    display:table
}
.md-card-horizontal:after{
    clear:both
}
.md-card-horizontal:after,.md-card-horizontal:before{
    content:" ";
    display:table
}
.md-card-horizontal:after{
    clear:both
}
.md-card-horizontal-grid{
    min-height:auto
}
.md-card-horizontal .md-card-head{
    border-right:1px solid rgba(0,0,0,.12);
    border-bottom:none;
    width:200px;
    height:100%;
    position:absolute;
    left:0;
    top:0;
    bottom:0
}
@media only screen and (max-width:767px){
    .md-card-horizontal .md-card-head{
        width:140px
    }
}
.md-card-horizontal .md-card-head-grid{
    border-right:1px solid rgba(0,0,0,.12)
}
.md-card-horizontal .md-card-head-menu{
    right:auto;
    left:8px
}
.md-card-horizontal .md-card-content{
    margin-left:200px
}
@media only screen and (max-width:767px){
    .md-card-horizontal .md-card-content{
        margin-left:140px
    }
}
.md-card-horizontal .md-card-content-grid{
    padding:16px
}
.md-expand,.md-expand-group>*{
    opacity:0
}
.md-card-placeholder{
    min-width:100%
}
.md-card-list-wrapper,.md-card-list-wrapper *,.md-card-list-wrapper :after,.md-card-list-wrapper :before,.md-card-list-wrapper:after,.md-card-list-wrapper:before{
    -webkit-box-sizing:border-box;
    box-sizing:border-box
}
.md-card-list-wrapper .md-card-list-header{
    position:absolute;
    top:-24px;
    left:0
}
.md-card-list-wrapper .md-card-list{
    margin:48px 0 0 0;
    position:relative
}
.md-card-list-wrapper .md-card-list:first-child{
    margin-top:24px
}
.md-card-list-wrapper .md-card-list>ul{
    margin:0;
    padding:0;
    list-style:none
}
.md-card-list-wrapper .md-card-list>ul>li{
    padding:0;
    margin:0;
    list-style:none
}
.md-card-list-wrapper .md-card-list>ul>li{
    min-height:34px;
    padding:8px 16px;
    font-size:13px;
    -webkit-transition:background 150ms,padding .2s;
    transition:background 150ms,padding .2s;
    background:#fff;
    -webkit-box-shadow:0 1px 3px rgba(0,0,0,.12),0 1px 2px rgba(0,0,0,.24);
    box-shadow:0 1px 3px rgba(0,0,0,.12),0 1px 2px rgba(0,0,0,.24)
}
.md-card-list-wrapper .md-card-list>ul>li:after,.md-card-list-wrapper .md-card-list>ul>li:before{
    content:" ";
    display:table
}
.md-card-list-wrapper .md-card-list>ul>li:after{
    clear:both
}
.md-card-list-wrapper .md-card-list>ul>li:after,.md-card-list-wrapper .md-card-list>ul>li:before{
    content:" ";
    display:table
}
.md-card-list-wrapper .md-card-list>ul>li:after{
    clear:both
}
.md-card-list-wrapper .md-card-list>ul>li.item-shown{
    background:#fff;
    padding:8px 36px
}
@media only screen and (max-width:767px){
    .md-card-list-wrapper .md-card-list>ul>li.item-shown .md-card-list-item-subject{
        clear:both;
        float:none;
        padding-top:16px
    }
    .md-card-list-wrapper .md-card-list>ul>li.item-shown .md-card-list-item-subject>span{
        white-space:normal
    }
}
.md-card-list-wrapper .md-card-list>ul>li.item-shown .md-card-list-item-sender{
    width:auto;
    overflow:hidden
}
.md-card-list-wrapper .md-card-list>ul>li.item-shown.md-card-list-item-selected{
    position:relative
}
.md-card-list-wrapper .md-card-list>ul>li.item-shown.md-card-list-item-selected:before{
    content:'';
    position:absolute;
    display:block;
    left:0;
    top:0;
    bottom:0;
    width:8px;
    background:#e3f2fd
}
.md-card-list-wrapper .md-card-list .md-card-list-item-avatar-wrapper,.md-card-list-wrapper .md-card-list .md-card-list-item-select,.md-card-list-wrapper .md-card-list .md-card-list-item-sender{
    float:left
}
.md-card-list-wrapper .md-card-list .md-card-list-item-select{
    padding:6px 8px 0 0
}
.md-card-list-wrapper .md-card-list .md-card-list-item-avatar-wrapper,.md-card-list-wrapper .md-card-list .md-card-list-item-date,.md-card-list-wrapper .md-card-list .md-card-list-item-sender,.md-card-list-wrapper .md-card-list .md-card-list-item-subject{
    padding:0 8px
}
.md-card-list-wrapper .md-card-list .md-card-list-item-avatar-wrapper .md-card-list-item-avatar{
    background:#757575;
    color:#fff;
    width:34px;
    border-radius:50%;
    display:block
}
.md-card-list-wrapper .md-card-list .md-card-list-item-avatar-wrapper .md-card-list-item-avatar-large{
    width:82px;
    border-radius:50%
}
.md-card-list-wrapper .md-card-list .md-card-list-item-avatar-wrapper span.md-card-list-item-avatar{
    line-height:34px;
    text-transform:uppercase;
    text-align:center
}
.md-card-list-wrapper .md-card-list .md-card-list-item-sender{
    width:220px;
    line-height:34px
}
.md-card-list-wrapper .md-card-list .md-card-list-item-sender>span{
    text-overflow:ellipsis;
    display:inline-block;
    vertical-align:top;
    white-space:nowrap;
    overflow:hidden;
    width:100%
}
@media only screen and (max-width:1219px){
    .md-card-list-wrapper .md-card-list .md-card-list-item-sender{
        display:none
    }
}
.md-card-list-wrapper .md-card-list .md-card-list-item-subject{
    overflow:hidden;
    font-weight:500
}
.md-card-list-wrapper .md-card-list .md-card-list-item-subject>span{
    line-height:34px;
    text-overflow:ellipsis;
    display:inline-block;
    vertical-align:top;
    white-space:nowrap;
    overflow:hidden;
    width:100%
}
.md-card-list-wrapper .md-card-list .md-card-list-item-subject .md-card-list-item-sender-small{
    display:none
}
@media only screen and (max-width:1219px){
    .md-card-list-wrapper .md-card-list .md-card-list-item-subject .md-card-list-item-sender-small{
        display:block
    }
    .md-card-list-wrapper .md-card-list .md-card-list-item-subject .md-card-list-item-sender-small+span{
        line-height:inherit
    }
}
.md-card-list-wrapper .md-card-list .md-card-list-item-subject .md-card-list-item-sender-small>span{
    text-overflow:ellipsis;
    display:inline-block;
    vertical-align:top;
    white-space:nowrap;
    overflow:hidden;
    width:100%;
    font-size:12px;
    color:#999
}
.md-card-list-wrapper .md-card-list .md-card-list-item-date{
    line-height:34px;
    float:right;
    color:#999
}
@media only screen and (max-width:479px){
    .md-card-list-wrapper .md-card-list .md-card-list-item-date{
        display:none
    }
}
.md-card-list-wrapper .md-card-list .md-card-list-item-menu{
    float:right;
    margin:0 0 0 8px;
    position:relative
}
.md-card-list-wrapper .md-card-list .md-card-list-item-menu .split-uk-dropdown .material-icons{
    margin-right:8px
}
.md-card-list-wrapper .md-card-list .md-card-list-item-content-wrapper{
    display:none;
    clear:both;
    opacity:0
}
.md-card-list-wrapper .md-card-list .md-card-list-item-content{
    padding:16px 16px 0 0;
    max-height:360px;
    overflow-x:hidden;
    margin:0 0 40px;
    top:20px;
    position:relative;
    font-size:14px
}
.md-card-list-wrapper .md-card-list .md-card-list-item-content+.md-card-list-item-reply{
    padding-top:10px
}
.md-card-list-wrapper .md-card-list .md-card-list-item-reply{
    padding:16px 0
}
.md-card-list-wrapper .md-card-list .md-card-list-item-selected{
    background:#e3f2fd
}
.md-list{
    margin:0;
    padding:0;
    list-style:none
}
.md-list>li{
    padding:0;
    margin:0;
    list-style:none
}
.md-list,.md-list *,.md-list :after,.md-list :before,.md-list:after,.md-list:before{
    -webkit-box-sizing:border-box;
    box-sizing:border-box
}
.md-list .split-uk-nestable-list>li,.md-list>li{
    min-height:48px;
    padding:8px 4px;
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
    border-bottom:1px solid rgba(0,0,0,.12);
    position:relative
}
.md-list .split-uk-nestable-list>li>.md-list-content,.md-list>li>.md-list-content{
    overflow:hidden
}
.md-list .split-uk-nestable-list>li>.md-list-content>span,.md-list>li>.md-list-content>span{
    display:block
}
.md-list .split-uk-nestable-list>li>.md-list-content .md-list-heading,.md-list>li>.md-list-content .md-list-heading{
    margin:0;
    font-weight:500;
    display:block;
    overflow:hidden;
    padding-bottom:1px
}
.md-list .split-uk-nestable-list>li>.md-list-content .md-list-menu,.md-list>li>.md-list-content .md-list-menu{
    float:right
}
.md-list .split-uk-nestable-list>li>.md-list-content .md-list-menu .md-list-menu-toggle,.md-list>li>.md-list-content .md-list-menu .md-list-menu-toggle{
    display:block;
    font-size:18px;
    color:rgba(0,0,0,.8);
    width:28px;
    height:28px;
    line-height:28px;
    border-radius:14px;
    text-align:center
}
.md-list .split-uk-nestable-list>li>.md-list-content .split-uk-badge,.md-list>li>.md-list-content .split-uk-badge{
    float:right;
    color:#fff!important
}
.md-list .split-uk-nestable-list>li>.md-list-content .md-list-action,.md-list>li>.md-list-content .md-list-action{
    float:right;
    margin-left:8px;
    margin-top:2px;
    display:none
}
.md-list .split-uk-nestable-list>li>.md-list-content .md-list-action-placeholder,.md-list>li>.md-list-content .md-list-action-placeholder{
    float:right;
    margin-left:8px;
    display:none;
    width:32px;
    height:32px
}
.md-list .split-uk-nestable-list>li .md-list-action-dropdown,.md-list>li .md-list-action-dropdown{
    position:absolute;
    right:16px;
    top:10px;
    display:none
}
.md-list .split-uk-nestable-list>li>a.md-list-content,.md-list>li>a.md-list-content{
    display:block;
    color:#212121
}
.md-list .split-uk-nestable-list>li:last-child,.md-list>li:last-child{
    border-bottom:none
}
.md-list .split-uk-nestable-list>li.md-list-item-active,.md-list>li.md-list-item-active{
    color:#7cb342;
    background:#ededed
}
.md-list .split-uk-nestable-list>li.md-list-item-disabled>.md-list-content,.md-list>li.md-list-item-disabled>.md-list-content{
    color:#bdbdbd
}
.md-list .split-uk-nestable-list>li.md-list-item-disabled>.md-list-content span,.md-list>li.md-list-item-disabled>.md-list-content span{
    color:#bdbdbd!important
}
.md-list .split-uk-nestable-list>li.heading_list,.md-list>li.heading_list{
    min-height:32px;
    padding:32px 8px 16px;
    border-bottom:none;
    background:0 0!important;
    text-transform:uppercase
}
.md-list .split-uk-nestable-list>li:hover>.md-list-addon-element .split-uk-nestable-handle,.md-list>li:hover>.md-list-addon-element .split-uk-nestable-handle,.split-uk-touch .md-list .split-uk-nestable-list>li>.md-list-addon-element .split-uk-nestable-handle,.split-uk-touch .md-list>li>.md-list-addon-element .split-uk-nestable-handle{
    display:block
}
.md-list .split-uk-nestable-list>li:hover>.md-list-content .md-list-action,.md-list .split-uk-nestable-list>li:hover>.md-list-content .md-list-action-placeholder,.md-list>li:hover>.md-list-content .md-list-action,.md-list>li:hover>.md-list-content .md-list-action-placeholder,.split-uk-touch .md-list .split-uk-nestable-list>li>.md-list-content .md-list-action,.split-uk-touch .md-list .split-uk-nestable-list>li>.md-list-content .md-list-action-placeholder,.split-uk-touch .md-list>li>.md-list-content .md-list-action,.split-uk-touch .md-list>li>.md-list-content .md-list-action-placeholder{
    display:block
}
.md-list .split-uk-nestable-list>li:hover .md-list-action-dropdown,.md-list>li:hover .md-list-action-dropdown,.split-uk-touch .md-list .split-uk-nestable-list>li .md-list-action-dropdown,.split-uk-touch .md-list>li .md-list-action-dropdown{
    display:block
}
.md-list .split-uk-nestable-list>li{
    margin-left:64px
}
.md-list-addon>li{
    margin-left:64px;
    position:relative
}
.md-list-addon>li:last-child .md-list-addon-element{
    border-bottom:none;
    bottom:0
}
.md-list-addon>li:first-child .md-list-addon-element{
    top:0
}
.md-list-addon>li.md-list-item-active .md-list-addon-element,.md-list-addon>li.md-list-item-active .md-list-addon-element .material-icons{
    color:#7cb342
}
.md-list-addon-element{
    position:absolute;
    left:-64px;
    top:-1px;
    bottom:-1px;
    width:64px;
    text-align:center;
    padding:8px 0;
    display:block
}
.md-list-addon-element .element-status{
    position:absolute;
    right:12px;
    top:10px;
    width:12px;
    height:12px;
    border-radius:50%;
    background:#bdbdbd;
    border:1px solid #fff
}
.md-list-addon-element .element-status-danger{
    background:#e53935
}
.md-list-addon-element .element-status-success{
    background:#7cb342
}
.md-list-addon-element .element-status-warning{
    background:#ffa000
}
.md-list-addon-element>.md-list-addon-avatar{
    margin-top:2px
}
.md-list-addon-element>.md-list-addon-icon{
    font-size:28px;
    margin-top:4px;
    color:#727272
}
.md-list-addon-element>.material-icons{
    margin-top:6px
}
.md-list-addon-element .icheckbox_md,.md-list-addon-element .iradio_md{
    margin-top:10px
}
.md-list-addon-element .split-uk-nestable-handle{
    position:absolute;
    left:-2px;
    top:12px;
    display:none
}
.md-list-interactive li{
    cursor:pointer
}
.md-list-bg{
    background:#fff
}
.md-list-separated li{
    background:#fff;
    padding:8px
}
.md-list-separated li+li{
    border-top:none;
    margin-top:8px
}
.md-list-bg-no-sep{
    background:#fff;
    padding:8px
}
.md-list-bg-no-sep li>.md-list-content{
    padding:0 4px
}
.md-list-outside>li{
    padding:0
}
.md-list-outside>li>.md-list-content{
    padding:8px 16px;
    display:block;
    color:#212121
}
.md-list-outside>li.md-list-item-active,.md-list-outside>li:hover:not(.heading_list){
    background:rgba(0,0,0,.085)
}
.md-list-outside>li.heading_list{
    padding:32px 16px 16px
}
.md-list-outside.md-list-addon li{
    margin-left:0
}
.md-list-outside.md-list-addon li .md-list-addon-element{
    position:relative;
    top:auto;
    left:auto;
    float:left
}
.md-list-outside.md-list-addon li .md-list-content{
    padding-left:0
}
.md-list-outside-wrapper{
    position:relative
}
.md-list-outside-wrapper .md-list-outside-search{
    position:absolute;
    top:-46px;
    left:16px;
    right:16px;
    z-index:1000
}
.md-list-outside-wrapper .md-list-outside-search .md-input-wrapper{
    padding:0
}
.md-list-outside-wrapper .md-list-outside-search .md-input-wrapper .md-input-bar:after,.md-list-outside-wrapper .md-list-outside-search .md-input-wrapper .md-input-bar:before{
    background:#fff
}
.md-list-outside-wrapper .md-list-outside-search .md-input{
    border-bottom-color:transparent!important
}
.md-list .split-uk-nestable-list{
    padding-left:0
}
.md-list .split-uk-nestable-list .split-uk-nestable-item{
    padding-right:0
}
.md-list .split-uk-nestable-item+.split-uk-nestable-item{
    margin-top:0
}
.md-list-right.md-list-addon>li{
    margin-left:0;
    margin-right:64px
}
.md-list-right.md-list-addon>li .md-list-addon-element{
    left:auto;
    right:-64px
}
.split-uk-touch .md-list-addon-element .split-uk-nestable-handle{
    display:block!important
}
.split-uk-touch .md-list-content .md-list-action,.split-uk-touch .md-list-content .md-list-action-placeholder{
    display:block!important
}
.split-uk-touch .md-list-action-dropdown{
    display:block!important
}

.paymentcard-box {
    box-sizing: border-box;
    padding-left: 10px;
    padding-bottom: 20px;
    display: inline-block;
}
.des-box {
    border-radius: 5px;
    padding: 5px 5px;
    color: #43425d !important;
    font-size: 13px;
    font-weight: 500;
}
.pink-box {
    box-shadow: 0 2px 10px 0 rgba(255, 38, 138, 0.55);
    background-color: #ff268a;
}
.blue-box {
    box-shadow: 0 2px 10px 0 rgba(13, 132, 242, 0.36);
    background-color: #00448b;
}
.lightblue-box {
    box-shadow: 0 2px 10px 0 rgba(13, 132, 242, 0.36);
    background-color: #50bcff;
}
.orange-box {
    box-shadow: 0 2px 10px 0 rgba(245, 166, 35, 0.35);
    background-color: #f5a623;
}
.border-orange1 {
    border-color: #ff7f00 !important;
}
.border-green1 {
    border-color: #1a9347 !important;
}
.border-red1 {
    border-color: #e82828 !important;
}
.card-group {
    margin-bottom: 2.143rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
}
.card-block {
    position: relative;
    padding: 1.429rem;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}
.card.card-block {
    box-shadow: none;
}
@media (min-width: 480px) {

    .card-group {
        -webkit-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    }
    .card-group .card:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .card-group .card {
        margin-bottom: 0;
        -webkit-box-flex: 1;
        -webkit-flex: 1 0 0%;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
    }
    .disable-state{
        pointer-events: none;
        cursor: not-allowed;
        filter: grayscale(100%);
        -webkit-filter: grayscale(100%);
        color:grey
    }
}
</style>
