export default {
    ADMIN_API: "https://adminapis.digicollect.com",
    // ADMIN_API: "https://testadminapis.digicollect.com",
    AUTH_URL: "https://crm.digicollect.com",
    // AUTH_URL: "https://testadmin.digicollect.com",
    CRM_URL: "https://crm.digicollect.com",
    // CRM_URL: "https://testcrm.digicollect.com",
    SCM_URL: "https://scm.digicollect.com",
    // SCM_URL: "https://testscm.digicollect.com",
    POS_API: "https://scmapis.digicollect.com/fnb",
    // POS_API: "https://testscmapis.digicollect.com/fnb",
    POS_URL: "https://pos.digicollect.com",
    // POS_URL: "https://testpos.digicollect.com",
    // POS_URL: "http://localhost:8080",
    POS_API_INVENTORY: "https://scmapis.digicollect.com/common",
    // POS_API_INVENTORY: "https://testscmapis.digicollect.com/common",
    SCM_API: "https://scmapis.digicollect.com/manufacturing",
    // SCM_API: "https://testscmapis.digicollect.com/manufacturing",
    CRM_API: "https://crmapis.digicollect.com",
    // CRM_API: "https://testcrmapis.digicollect.com",
    HELP_URL: "https://helpdesk.digicollect.com",
    // HELP_URL: "https://testhelpdesk.digicollect.com",
    HELP_API: "https://helpdeskapis.digicollect.com/",
    // HELP_API: "https://testhelpdeskapis.digicollect.com/",
    // HELP_API: "http://testhelpdeskapis.digicollect.com:8004",
    // HELP_API: "http://172.104.55.81:8004/",
};
