<template>
    <modal :name="modal_name" transition="nice-modal-fade" class="create-cash-register final-modal popup-modal-all-width-height-height-570">
        <div class="v-modal-content">
            <div class="v-modal-header">
                <span class="v-modal-dialog-title v-modal-title">ADD CASH REGISTER</span>
            </div>
            <div class="general-section" style="padding: 15px 0px;">
            <div class="pl-5 pr-2 pt-0">
                <div class="d-form">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="form-group mt-2 contactGroup">
                                <input class="inputContact" type="text" name="cash_register_id" v-validate="'required|alpha_dash'" v-model="create.id" autofocus required :maxlength="cashRegisterId"/>
                                <span class="highlightContact"></span>
                                <span class="barContact"></span>
                                <label class="labelContact">Cash Register ID<sup>★</sup></label>
                                <span class="invalid-feedback-form text-danger fs-13"  v-show="errors.has('cash_register_id') && submitted" style="display:contents;">{{ errors.first("cash_register_id") }}</span>
                                <p class="pull-right text-right text-grey font-600 fs-12 pt-1">{{cashRegisterId - create.id.length}} / 15</p>
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <label class="text-secondary pr-2 fw-600 pb-1">Is Connection Wired? :
                            <el-switch class="ml-2 approvalswitch" v-model="is_connection_wired"></el-switch>
                        </label>
                    </div>
                    <div v-if="!is_connection_wired" class="row align-items-center" style="margin-top: -10px !important;">
                        <div class="col-lg-3 col-md-3 col-sm-3">
                            <v-select class="filterName" label="name" placeholder="Server Type" :filterable="true" :options="['http://','https://']" v-model="create.server_type"></v-select>
                        </div>
                        <div class="col-lg-9 col-md-9 col-sm-9">
                            <div class="form-group mt-0 contactGroup">
                                <input class="inputContact" type="text" name="server_url" v-validate="'required'" v-model="create.server_url" required :maxlength="serverURL"/>
                                <span class="highlightContact"></span>
                                <span class="barContact"></span>
                                <label class="labelContact">Server URL<sup>★</sup></label>
                                <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('server_url') && submitted" style="display:contents;">{{ errors.first("server_url") }}</span>
                                <p class="pull-right text-right text-grey font-600 fs-12 pt-1">{{serverURL - create.server_url.length}} / {{serverURL}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <label for="inputSattus" class="col-form-label text-secondary">Phone Number</label>
                            <vue-tel-input name="phone_number" v-model="create.phone_number" :value="create.phone_number" placeholder="Please enter your Phone Number" :preferredCountries="['th', 'in', 'us']" @onInput="onInput"></vue-tel-input>
                            <span class="invalid-feedback-form text-danger fs-13" v-show="!validPhone">Phone Number Should be Valid.</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="form-group contactGroup" style="margin-top: 0px;">
                                <input v-model="create.pos_id" class="inputContact" type="text" v-validate="{required:true,regex:/^[a-zA-Z0-9]+$/}" required name="pos_id" :maxlength="posID"/>
                                <span class="highlightContact"></span>
                                <span class="barContact"></span>
                                <label class="labelContact">POS ID<sup>★</sup></label>
                                <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('pos_id') && submitted" style="display:contents;">{{errors.first('pos_id')}}</span>
                                <p class="pull-right text-right text-grey font-600 fs-12 pt-1">{{posID - create.pos_id.length}} / 10</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <label class="text-secondary pr-2">Is Printer Available?</label>
                            <div class="form-check form-check-inline">
                                <label class="form-check-label">
                                    <input @click="checkStatus()" type="radio" class="form-check-input" name="optionsRadios" id="optionsRadios2" v-bind:value="true" v-model="create.is_printer_available"/>
                                    <span class="radio-icon"></span>
                                    <span>Yes</span>
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <label class="form-check-label">
                                    <input @click="checkStatus()" type="radio" class="form-check-input" name="optionsRadios" id="optionsRadios2" v-bind:value="false" v-model="create.is_printer_available"/>
                                    <span class="radio-icon"></span>
                                    <span>No</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="create.is_printer_available">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <multiselect v-model="create.printer_id" openDirection="top" style="background-color:#cddbeb !important" v-validate="'required'" @open="getPrinterDetails()" class="digi-multiselect" label="name" name="printer_name" :options="allPrinters" :internal-search="true" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Select Printer">
							</multiselect>
							<span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('printer_name')">Printer Name is Required</span>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div class="v-modal-dialog-actions modal-bg text-center pt-5">
                <button class="btn btn-white btn-smm text-uppercase ml-1" style="border: 1px solid #00448b !important;" :disabled="create_cash_register_api_inprogress" @click="cancelNewRegister()">CANCEL</button>
                <button class="btn btn-secondary btn-smm text-uppercase" @click="addNewCashRegister()" :disabled="create_cash_register_api_inprogress">SAVE
                    <half-circle-spinner :animation-duration="1000" :size="20" v-if="create_cash_register_api_inprogress" color="#00448b" style="position: absolute;top:6px;right:2px" />
                </button>
            </div>
        </div>
    </modal>
</template>
<script>
import cashRegister from './mixins/cashRegister.js'
import { HalfCircleSpinner } from "epic-spinners";
export default {
	props: ['modal_name'],
	data() {
		return {
			cashRegisterId: 15,
			serverURL: 200,
			posID: 10,
			validPhone: true,
			create: {
				id: '',
				phone_number: '',
				server_type: 'http://',
				server_url:'',
				pos_id: '',
				is_printer_available: false,
				printer_id:''
			},
			modal_msg: '',
            submitted: false,
            allPrinters:[],
            is_connection_wired: false,
            create_cash_register_api_inprogress: false
		}
	},
  	mixins: [cashRegister],
    components: {
        HalfCircleSpinner
    },
	methods: {
        checkStatus(){
            if(this.create.is_printer_available){
                this.create.printer_id = ''
            }
        },
		cancelNewRegister() {
			this.create = {
				id: '',
				phone_number: '',
				server_type:'http://',
				server_url: '',
				pos_id: '',
				is_printer_available: false,
				printer_id: '',
			}
			this.$modal.hide('create-cash')
			this.validPhone = true
		},
        addNewCashRegister() {
            this.submitted = true
            this.create.phone_number = this.create.phone_number.replace(/\s/g,'')
            this.$validator.validate().then(result => {
                if (result && this.validPhone) {
                    if(!this.create.is_printer_available){
                       this.create.printer_id = ''
                    }
                    this.create_cash_register_api_inprogress = true;
                    let params ={
                        id: this.create.id,
						phone_number: this.create.phone_number,
						server_type:this.create.server_type,
                        server_url: this.create.server_url,
                        pos_id: this.create.pos_id,
                        is_printer_available: this.create.is_printer_available,
                        printer_id: this.create.printer_id._id || '',
                        is_connection_wired: this.is_connection_wired
                    }
                    if(this.is_connection_wired) {
                        params.server_type = '';
				        params.server_url ='';
                    }
                    let newparams = {
                        register_details:params,
                        outlet_id:this.$route.query.outletId
                    }
                    this.$http.post('/outlet/add_cash_register', newparams).then(res => {
                        if (res.data.status_id == 1) {
                            this.$swal('Created', 'Cash Register Created Successfully', 'success')
                            this.cancelNewRegister()
                            this.$emit('created-cash-register')
                        } else {
                            this.modal_msg ='Cash Register Creation Failed. ' + res.data.reason
                            this.$swal('Warning', this.modal_msg , 'warning')
                        }
                        this.create_cash_register_api_inprogress = false;
                    }).catch((err) => {
                        this.create_cash_register_api_inprogress = false;
                    })
                }
            })
        },
		onInput({ number, isValid, country }) {
			this.validPhone = isValid
			if ($('.vue-tel-input')[0].lastChild.value.match('.*[a-z].*')) {
				this.validPhone = false
			}
        },
        async getPrinterDetails(){
            try {
                let params = {
                    search_key:'',
                }
                let res = await this.$http.post('outlet/get_all_printer_dropdown',params)
                if(res.data.status_id == 1){
                    this.allPrinters = res.data.response.printers
                }
            } catch (reason) {

            }

        }
	},
	created() {
		const dict = {
			custom: {
				pos_id: {
					required: () => 'POS ID is Required',
					regex: () => 'Please Enter Valid POS ID'
				},
				printer_server_url: {
                    required: () => 'Printer Server URL IS Required',
                    ip:()=>'Enter Valid IP Address'
				}
			}
		}
		this.$validator.localize('en', dict)
	}
}
</script>
<style>
    .v-select.filterName .dropdown-toggle .clear {
        display: none !important;
    }
</style>
