<template>
    <div class="page-layout blank checkoutdiv"
        style="background-color: #fff;margin-left: 0px!important;padding-left: 15px;padding-right: 15px;">
        <div v-if="callinProgress" class="checkoutLoader">
            <div class="LoaderPlacement">
                <div class="digi-table-loader inverse">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
        <div class="pb-10" v-else>
            <!-- <div class="checkoutLoader" v-if="loadData">
                <div class="LoaderPlacement">
                    <div class="digi-table-loader inverse">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div> -->
            <h2 class="headerblock">
                <div class="d-flex justify-content-between align-items-center">
                    <div>
                        <div class="list-title" style="font-weight: 500;" @click="back">
                            <button class="btn btn-checkout btn-smm mt-0">
                                <i class="icon-chevron-left px-0" style="line-height: 1.5;"></i>Back
                            </button>
                        </div>
                    </div>
                    <div>
                        <div v-if="!editOrder" class="pull-right d-flex">
                            <button class="btn btn-danger btn-smm" @click="discardOrder">
                                <i class="icon icon-delete" style="font-size:18px!important;"></i>
                                Discard Order
                            </button>
                            <!-- <button class="btn btn-secondary btn-smm" @click="SaveOrder">
                                <img src="https://cdn.digicollect.com/cdn/posv2/checkout-white.svg" style="width:25px;">
                                Save Order
                            </button> -->
                        </div>
                        <div v-else class="pull-right d-flex">
                            <button class="btn btn-danger btn-smm mr-2" @click="discardOrderItems">
                                <i class="icon icon-delete" style="font-size:18px!important;"></i>
                                Discard Order
                            </button>
                        </div>
                    </div>
                </div>
            </h2>
            
            <div>
                <div class="checkout-manage-info row justify-content-between align-items-center">
                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/checkoutpromotion.svg">
                    <span>Checkout</span>
                </div>
                <div class="page-layout carded left-sidebar" style="overflow: auto !important;">
                    <div class="page-content-wrapper"
                        style="background: #00448b;padding: 13px;padding-left: 27px;border-radius: 12px;">
                        <div class="page-content row" style="background: #dee8f2!important;border-radius: 10px;">
                            <div class="d-flex" style="display: flex;width: 100%;">
                                <div class="col-md-12 mb-4" style="padding-right: 0px;padding-left: 0px;">
                                    <div class="d-flex flex-nowrap justify-content-between">
                                        <div>
                                            <p class="order-summary-checkout">Your Order Summary</p>
                                        </div>
                                        <div class="right-cust-name d-flex pr-6 position-relative">
                                            <span class="d-flex">
                                                <span class="chekout-customer font-600">Customer Name : </span>
                                                <span class="checkout-customer-name text-truncate d-block mt-5"
                                                    style="max-width:180px;" :title="order.customer_name">
                                                    {{order.customer_name}}</span>
                                                <span class="pull-right ml-2" @click="searchCustomer=true">
                                                    <el-tooltip class="item" effect="dark" content="Edit Customer" placement="top">
                                                        <button type="button" class="btn btn-icon actionicons cust-del" style="padding: 0rem 0rem;" id="edit_customer_take">
                                                        <i class="icon icon-pencil"></i>
                                                        </button>
                                                    </el-tooltip>
                                                </span>
                                            </span>
                                            <div class="selectCustomer" v-show="searchCustomer">
                                                <div @click="searchCustomer=false" class="d-flex pull-right pointer" id="click_customer_edit">
                                                    <i class="icon icon-close lightBlue"></i>
                                                </div>
                                                <div class="p-4">
                                                    <v-select v-model="selectedCustomer" :close-on-select="true" class="user-select add-customer-order" @open="getSearchCustomer" @search:blur="getSearchCustomer" @search="getSearchCustomer" placeholder="Search By Name, Number, Email" label="first_name" :options="customers" @input="updateOrderCustomer" id="search_click_customer">
                                                        <span slot="no-options">
                                                        No Matches Found. Please Create New Account
                                                        <span class="text-secondary p-0 m-0 w-0" style="text-decoration:none;text-transform: none;min-width: 0px;height: 2rem;line-height: 2rem;" @click="showCreateCustomer()">Here</span>
                                                        </span>
                                                    </v-select>
                                                </div>
                                            </div>
                                            <span style="padding-left: 25px;"
                                                v-if="order.type == 'Dine In' && order.tables">
                                                <span class="chekout-customer font-600">Table Number : </span>
                                                <span v-if="order.tables[0]['name']"
                                                    class="checkout-customer-name">{{order.tables[0]['name']}}</span>
                                                <span v-else class="checkout-customer-name">{{order.tables[0]}}</span>
                                                <span>
                                                    <el-dropdown trigger="click">
                                                        <span class="el-dropdown-link pointer ml-1" @click="getTables">
                                                            <i class="icon icon-pencil text-secondary fs-16"></i>
                                                        </span>
                                                        <el-dropdown-menu slot="dropdown" :class="tableStatus == true ? 'table-dd' : ''" v-if="tableStatus == true">
                                                            <div v-for="(table, table_index) in tables" :key="table_index">
                                                                <el-dropdown-item v-if="table.status == 'Available'">
                                                                    <div @click="selectTable(table.name)">
                                                                        {{table.name}}
                                                                    </div>
                                                                </el-dropdown-item >
                                                            </div>
                                                        </el-dropdown-menu>
                                                        <el-dropdown-menu slot="dropdown" class="table-dd" v-else-if="tableStatus == false && loadTables == false">
                                                                <span>No Tables Available</span>    
                                                        </el-dropdown-menu>
                                                        <el-dropdown-menu slot="dropdown" class="table-dd" v-else>
                                                                <div class="digi-table-loader">
                                                                    <div></div>
                                                                    <div></div>
                                                                    <div></div>
                                                                    <div></div>
                                                                </div>  
                                                        </el-dropdown-menu>
                                                    </el-dropdown>
                                                </span>
                                            </span>
                                            <span style="padding-left: 25px;"
                                                v-if="order.type == 'Dine In' && order.tables">
                                                <span class="chekout-customer font-600">No. of GUESTS: </span>
                                                <span class="checkout-customer-name" v-if="!edit_guest_nos">{{order.no_of_pax||0}}</span>
                                                <input type="number" onkeypress='return event.charCode >= 48 && event.charCode <= 57' v-model="order.no_of_pax" @input="noOfPaxInput" name="no_of_pax" v-if="edit_guest_nos" class="copies-field">
                                                <span class="el-dropdown-link pointer ml-1" v-if="!edit_guest_nos"  @click="edit_guest_nos = true">
                                                    <i class="icon icon-pencil text-secondary fs-16"></i>
                                                </span>
                                                <span v-if="edit_guest_nos" class="pt-2">
                                                    <i class="icon icon-check-circle text-success pointer" @click="updateNoOfPax"></i>
                                                </span>
                                            </span>
                                            <span class="d-flex"
                                                style="padding-left: 25px;">
                                                <span class="chekout-customer font-600">Order Type: </span>
                                                <span class="checkout-customer-name text-truncate d-block mt-5 ml-1"
                                                    style="max-width:180px;" :title="order.type"> {{order.type}}</span>
                                                <el-dropdown trigger="click">
                                                    <span class="el-dropdown-link pointer ml-1" @click="loadOrderTypes(order.type)">
                                                        <i class="icon icon-pencil text-secondary fs-16"></i>
                                                    </span>
                                                    <el-dropdown-menu slot="dropdown">
                                                        <el-dropdown-item v-if="show_order_type_dropdown" >
                                                            <span >
                                                                ---
                                                            </span>
                                                        </el-dropdown-item >
                                                        <el-dropdown-item v-else @click.native="changeOrderType(types)" v-for="(types,index) in order_types" :key="index">{{types.order_type}}</el-dropdown-item>
                                                    </el-dropdown-menu>
                                                </el-dropdown>
                                            </span>
                                        </div>
                                    </div>
                                    <checkout-popup v-if="isPopUp" :editOrder="editOrder" :order="order"
                                        :isSummary="false" @updateOrderItem="updateOrderItem" :apiLock="apiLock"
                                        @updateAPILock="updateAPILock" @updateCheckout="updateCheckout"
                                        :showRoundOffBox="false"></checkout-popup>
                                    <table v-else class="table">
                                        <tbody>
                                            <tr style="height: 36px;">
                                                <td class="font-weight-bold" colspan="2"
                                                    style="background-color:rgba(13, 132, 242, 0.36)">Order Preferences
                                                </td>
                                                <td class="font-weight-bold" colspan="2"
                                                    style="background-color:rgba(13, 132, 242, 0.36)"
                                                    v-if="order.show_send_together">Send All Together
                                                    <el-switch class="pl-2" v-model="order.send_together"
                                                        @change="sendAllTogetherOrder(order.send_together)"
                                                        active-color="#1a9347"></el-switch>
                                                </td>
                                                <td class="font-weight-bold" colspan="2"
                                                    style="background-color:rgba(13, 132, 242, 0.36)" v-else>

                                                </td>
                                                <td class="font-weight-bold" colspan="2"
                                                    style="background-color:rgba(13, 132, 242, 0.36)">Fire Order
                                                    <el-switch style="padding-left: 1.5rem !important;"
                                                        v-model="order.fire_selected" :disabled="order.hold_order_before_placing == true"
                                                        @change="orderFire(order.fire_selected)" active-color="#1a9347">
                                                    </el-switch>
                                                </td>
                                                <td class="font-weight-bold" colspan="2"
                                                    style="background-color:rgba(13, 132, 242, 0.36)">Hold Order
                                                    <el-switch style="padding-left: 1.5rem !important;"
                                                        v-model="order.hold_order_before_placing"
                                                        @change="toggleHold(order.hold_order_before_placing)" active-color="#1a9347">
                                                    </el-switch>
                                                </td>
                                            </tr>
                                            <template v-for="(course,cindex) in order.courses">
                                                <tr :key="cindex+'ccindex'" style="height: 32px !important;"
                                                    :class="{'courses_dotted':cindex > 0}">
                                                    <td class="font-weight-bold" colspan="2"
                                                        style="background-color:#f5f6f9">Course {{course.course}}</td>
                                                    <td class="font-weight-bold" colspan="2"
                                                        style="background-color:#f5f6f9"> Send Together
                                                        <el-switch active-color="#1a9347"
                                                            style="padding-left:2.5rem !important;"
                                                            v-model="course.send_together"
                                                            @change="sendTogetherCourse(course.send_together,course.course)">
                                                        </el-switch>
                                                    </td>
                                                    <td class="font-weight-bold" colspan="2"
                                                        style="background-color:#f5f6f9" v-if="order.show_course_fire">
                                                        Fire Course
                                                        <el-switch class="pl-2" v-model="course.fire_selected" :disabled="order.hold_order_before_placing == true"
                                                            @change="courseFire(course.fire_selected,course.course)"
                                                            active-color="#1a9347"></el-switch>
                                                    </td>
                                                    <td class="font-weight-bold" colspan="2"
                                                        style="background-color:#f5f6f9" v-else></td>
                                                    <td class="font-weight-bold" colspan="2"
                                                    style="background-color:#f5f6f9">
                                                    </td>    
                                                </tr>
                                                <tr style="border-bottom: 1px solid #94c3f3;height: 40px;"
                                                    :key="cindex">
                                                    <th class="font-600">Product</th>
                                                    <th class="text-center font-600">Quantity</th>
                                                    <th class="font-600">Rate</th>
                                                    <th class="font-600" v-if="!(parseInt(order.items_discount_total.replace('(','').replace(')','').slice(1,)) <= 0)">Discount</th>
                                                    <th v-if="(parseInt(order.items_discount_total.replace('(','').replace(')','').slice(1,)) <= 0)"></th>
                                                    <th class="font-600" style="width:14%;">Total Amount</th>
                                                    <th class="font-600" style="white-space: nowrap !important;">Fire
                                                        Item</th>
                                                    <th class="font-600">Admin</th>
                                                </tr>
                                                <template v-for="(item, itemIndex) in course.items">
                                                    <tr :key="item._id+'ff'" style="height: 29px;" class="subtotal">
                                                        <th scope="row" class="text-secondary textCaps"
                                                            v-if="item.variant_id"
                                                            style="padding: 1.4rem 1.1rem !important;">
                                                            <el-tooltip v-if="item.variant_type == 'Non-Veg'"
                                                                content="Non-Veg" placement="top">
                                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/non-veg.svg"
                                                                    style="width: 13px!important;">
                                                            </el-tooltip>
                                                            <el-tooltip v-else-if="item.variant_type == 'Veg'"
                                                                content="Veg" placement="top">
                                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/veg.svg"
                                                                    style="width: 13px!important;">
                                                            </el-tooltip>
                                                            <el-tooltip v-else-if="item.variant_type == 'Egg'"
                                                                content="Egg" placement="top">
                                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/egg.svg"
                                                                    style="width: 13px!important;">
                                                            </el-tooltip>
                                                            <el-tooltip v-else-if="item.variant_type == 'Pescatarian'"
                                                                content="Pescatarian" placement="top">
                                                                <img style="width:13px;"
                                                                    src="https://cdn.digicollect.com/cdn/pos/images-new-theme/psec.svg">
                                                            </el-tooltip>
                                                            <span class="pt-1"
                                                                :class="{'ml-1':item.variant_type != ''}">
                                                                <!-- <span v-if="item.variant_name != ''">{{item.product_name + ' / ' + item.variant_name}}</span> -->
                                                                <span >{{item.product_name}}</span>
                                                                <span v-if="item.is_combo">
                                                                    <el-popover placement="left-end" width="200"
                                                                        trigger="hover" popper-class="combo-popover">
                                                                        <div v-for="(ic,index) in item.combo_details"
                                                                            :key="index">
                                                                            <span>{{ic.name}} X {{ic.quantity}}</span>
                                                                        </div>
                                                                        <el-button slot="reference"
                                                                            style="background: transparent !important;padding:12px 0px !important;">
                                                                            <img
                                                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/info_checkout.svg">
                                                                        </el-button>
                                                                    </el-popover>
                                                                </span>
                                                            </span>
                                                        </th>
                                                        <th scope="row" class="text-secondary" v-else
                                                            style="padding: 1.4rem 1.1rem !important;">
                                                            <el-tooltip v-if="item.product_type == 'Non-Veg'"
                                                                content="Non-Veg" placement="top">
                                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/non-veg.svg"
                                                                    style="width: 13px!important;">
                                                            </el-tooltip>
                                                            <el-tooltip v-else-if="item.product_type == 'Veg'"
                                                                content="Veg" placement="top">
                                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/veg.svg"
                                                                    style="width: 13px!important;">
                                                            </el-tooltip>
                                                            <el-tooltip v-else-if="item.product_type == 'Egg'"
                                                                content="Egg" placement="top">
                                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/egg.svg"
                                                                    style="width: 13px!important;">
                                                            </el-tooltip>
                                                            <el-tooltip v-else-if="item.product_type == 'Pescatarian'"
                                                                content="Pescatarian" placement="top">
                                                                <img style="width:13px;"
                                                                    src="https://cdn.digicollect.com/cdn/pos/images-new-theme/psec.svg">
                                                            </el-tooltip>
                                                            <span class="pt-1"
                                                                :class="{'ml-1':item.product_type != ''}">{{item.product_name}}
                                                                <span v-if="item.is_combo">
                                                                    <el-popover placement="left-end" width="200"
                                                                        trigger="hover" popper-class="combo-popover">
                                                                        <div v-for="(ic,index) in item.combo_details"
                                                                            :key="index">
                                                                            <span>{{ic.name}} X {{ic.quantity}}</span>
                                                                        </div>
                                                                        <el-button slot="reference"
                                                                            style="background: transparent !important;padding:12px 0px !important;">
                                                                            <img
                                                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/info_checkout.svg">
                                                                        </el-button>
                                                                    </el-popover>
                                                                </span>
                                                            </span>
                                                        </th>
                                                        <td class="text-center whiteSpace" style="padding: 0.6rem 1.2rem;"
                                                            :class="{'pointerEventsNone':item.is_promotional_product}">
                                                            <span v-if="item.quantity <= 1">
                                                                <img src="https://cdn.digicollect.com/cdn/posv2/disabled-minus-blue.svg"
                                                                    style="width:20px!important;height: 20px!important;margin-top: -6px;cursor: not-allowed;" />
                                                            </span>
                                                            <span @click="decreaseItemQuantity(cindex,itemIndex)"
                                                                v-else>
                                                                <img src="https://cdn.digicollect.com/cdn/posv2/minus-blue.svg"
                                                                    style="width:20px!important;height: 20px!important;margin-top: -6px;">
                                                            </span>
                                                            <span class="qty-checkout mx-2">
                                                                <input class="qty-input-checkout" :value="item.quantity"
                                                                    disabled>
                                                            </span>
                                                            <span v-if="item.quantity >= item.available_qty">
                                                                <img src="https://cdn.digicollect.com/cdn/posv2/disabled-plus-blue.svg"
                                                                    style="width:20px!important;height: 20px!important;margin-top: -6px;cursor: not-allowed;" />
                                                            </span>
                                                            <span v-else @click="increaseItemQuantity(cindex,itemIndex)">
                                                                <img src="https://cdn.digicollect.com/cdn/posv2/plus-blue.svg"
                                                                    style="width:20px!important;height: 20px!important;margin-top: -6px;">
                                                            </span>
                                                        </td>
                                                        <td style="padding: 0.6rem 1.2rem;">{{item.product_price}}</td>
                                                        <td>
                                                            <div class="d-flex align-items-center" v-if="!(parseInt(item.discount.amount.replace('(','').replace(')','').slice(1,)) <= 0) || item.discount.approval_message != ''">
                                                                {{item.discount.amount}}
                                                                <i class="icon-minus-circle text-danger pointer ml-2" v-if="item.discount.show_edit == false" @click="withdrawItem(order._id, item._id, 'item')" style="width: 15px !important;font-size: 15px !important;color: #00448b;position: relative;"></i>
                                                                <span class="ml-2" v-if="item.discount.approval_message && item.discount.approval_message != ''">
                                                                    <el-tooltip class="item" effect="dark" :content="item.discount.approval_message" placement="top">
                                                                        <i class="icon icon-information" style="width: 15px !important;height: 15px !important;font-size: 15px !important;color: #00448b;position: relative;"></i>
                                                                    </el-tooltip>
                                                                </span>
                                                                <el-button v-if="loggedInUser.role == 'default_manager' || loggedInUser.role == 'default_order_taker' && order.payment_status != 'PAID' && item.discount.show_edit == true && loggedInUser.access_change_discount == true" slot="reference" class="el-button-edit" 
                                                                    @click="editPopover(item, itemIndex)">
                                                                    <button class="btn btn-icon companyactions actionicons"
                                                                        style="padding: 0.4rem 1.3rem;"
                                                                        :disabled="item.is_promotional_product">
                                                                        <i class="icon icon-pencil"></i>
                                                                    </button>
                                                                </el-button>
                                                            </div>
                                                            <div>
                                                                <el-popover placement="bottom" 
                                                                    :popper-class="'itemPopOverDiscount'+item._id"
                                                                    width="300" trigger="click" v-model="item.popover">
                                                                    <div class="digi-table-loader d-flex align-items-center justify-content-center" style="margin: 0 auto" v-if="item_discount_loading">
                                                                        <div></div>
                                                                        <div></div>
                                                                        <div></div>
                                                                        <div></div>
                                                                    </div>
                                                                    <div v-else>
                                                                        <span class="defaultBlue mt-1 pl-2">
                                                                            Item Discount:
                                                                        </span>
                                                                        <div class="pull-right pr-2" style="margin: 0 auto;">
                                                                            <span @click="closePopOver(item)">
                                                                                <i class="icon icon-close"
                                                                                    style="color: rgb(184, 212, 255); cursor: pointer;font-size: 18px;"></i>
                                                                            </span>
                                                                        </div>
                                                                        <div class="d-flex align-items-center justify-content-between mt-3 px-2">
                                                                            <span class="fs-14 text-capitalize" style="color: #000;margin-right: 10px;">
                                                                                {{item.product_name}}
                                                                            </span>
                                                                            <span class="fs-14" style="color: #000;white-space: nowrap;">
                                                                                {{item.total.substring(0,1)+''+(parseFloat((parseFloat(item.discount.amount.substring(1,)) + item.total_unformated)))}}
                                                                            </span>
                                                                        </div>
                                                                        <div class="px-2" v-if="item.discount">
                                                                            <div class="d-flex align-items-center mt-2">
                                                                                <input type="number" class="enterCharges percent-input-field w-100"
                                                                                    v-model="item.discount.value" @input="itemDiscountChange(item)" :id="'itemDiscount'+item.product_id" placeholder="Enter Discount"
                                                                                    v-on:keypress="isNumber($event)">
                                                                                <span class="percent-input-field-box text-white">
                                                                                    %
                                                                                </span>
                                                                            </div>
                                                                            <div class="d-flex align-items-center mt-2">
                                                                                <span class="amount-input-field-box text-white">
                                                                                    {{order.currency.symbol}}
                                                                                </span>
                                                                                <input type="number" class="enterCharges amount-input-field w-100"
                                                                                    @input="itemDiscountChangeAmount(item)" v-model="item.discount.discounted_amount" placeholder="Discounted Amount"
                                                                                    v-on:keypress="isNumber($event)">
                                                                            </div>
                                                                            <textarea v-model="item.discount.reason" class="w-100 my-2" style="padding: 4.2px 7.8px 34.3px 7.8px;border-radius: 4px;border: solid 0.5px #b5b4b4;background-color: #fff;"></textarea>
                                                                            <button type="submit" class="applyCharges position-relative" style="border-radius: 20px !important; float: right;"
                                                                                @click="applyItemDiscount(item.product_id,item._id,item)">APPLY</button>
                                                                        </div>
                                                                    </div>
                                                                </el-popover>
                                                            </div>
                                                        </td>
                                                        <td style="padding: 0.6rem 1.2rem;">
                                                            {{item.product_price_exclusive}}
                                                            <el-tooltip class="item" effect="dark" content="Inclusive Of Add-ons" placement="top">
                                                                <i class="icon icon-information" style="width: 15px !important;height: 15px !important;font-size: 15px !important;color: #00448b;position: relative;"></i>
                                                            </el-tooltip>
                                                        </td>
                                                        <td>
                                                            <el-switch v-model="item.fire_selected" :disabled="order.hold_order_before_placing"
                                                                @change="itemFire(item)" active-color="#1a9347">
                                                            </el-switch>
                                                        </td>
                                                        <td
                                                            style="padding: 0.6rem 1.2rem;white-space: nowrap !important;">
                                                            <el-tooltip v-if="order.type == 'Dine In'" content="Change Course" placement="top">
                                                                <span @click="changeCourse(item)"
                                                                    :class="{'pointerEventsNone':item.is_promotional_product}">
                                                                    <img class="mr-3" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/chage_course.svg" style="width: 15px;">
                                                                </span>
                                                            </el-tooltip>
                                                            <el-tooltip content="Add Note" placement="top">
                                                                <span @click="AddNoteModal(cindex,itemIndex)">
                                                                    <img class="mr-2" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/add-note.svg" style="width: 15px;">
                                                                </span>
                                                            </el-tooltip>
                                                            
                                                            <!-- <el-tooltip content="Edit" placement="top">
                                                                <button type="button" class="btn btn-icon actionicons"
                                                                    style="padding: 0px 2px;"
                                                                    @click="editAddons(item)">
                                                                    <i class="icon icon-pencil text-secondary" style="font-size: 20px;"></i>
                                                                </button>
                                                            </el-tooltip> -->
                                                            <el-tooltip content="Edit" placement="top">
                                                                <button type="button" class="btn btn-icon actionicons"
                                                                    @click="showModifierpopup(item)">
                                                                    <i class="icon icon-pencil text-secondary" style="font-size: 20px;"></i>
                                                                </button>
                                                            </el-tooltip>

                                                            <el-tooltip v-if="item.show_delete" content="Delete" placement="top">
                                                                <button type="button" class="btn btn-icon actionicons"
                                                                    style="padding: 0px 3px;"
                                                                    @click="removeItem(item)">
                                                                    <i class="icon icon-delete" style="font-size: 20px;"></i>
                                                                </button>
                                                            </el-tooltip>

                                                            <span v-if="(parseInt(item.discount.amount.replace('(','').replace(')','').slice(1,)) <= 0) && loggedInUser.role == 'default_manager' ||  loggedInUser.role == 'default_order_taker' && loggedInUser.access_change_discount == true && (parseInt(item.discount.amount.replace('(','').replace(')','').slice(1,)) <= 0)">
                                                                <el-dropdown trigger="click" style="top: 4px;">
                                                                    <span class="el-dropdown-link pointer">
                                                                        <i class="icon icon-dots-vertical text-secondary" style="font-size: 20px;" :disabled="item.is_promotional_product"></i>
                                                                    </span>
                                                                    <el-dropdown-menu>
                                                                        <el-dropdown-item>
                                                                            <div @click="openPopover(item, itemIndex)">
                                                                                Add Item Discount
                                                                            </div>
                                                                        </el-dropdown-item>
                                                                    </el-dropdown-menu>
                                                                </el-dropdown>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr v-if="item.discount.reason != '' || ( item.discount.approval_message && item.discount.approval_message != '' )" :key="item._id+'ff'" style="height: 29px;" class="subtotal">
                                                        <td colspan="7">
                                                            <div class="font-italic fs-13" style="color: #303031;text-transform: lowercase !important; font-size: 13px !important;">
                                                                {{item.discount.approval_message}}
                                                            </div>
                                                            <div class="text-secondary font-italic fs-13" style="text-transform: lowercase !important; font-size: 13px !important;">
                                                                {{item.discount.reason}}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <!-- <template v-if="item.addons">
                                                        <tr v-if="item.addons.length > 0" :key="item._id+'ff'" class="px-3" style="height: 30px;background: #f5f6f9;">
                                                            <td colspan="7"
                                                                style="padding: 0px!important;padding-left: 12px!important;padding-right: 12px!important;background-color:#ffffff4a !important;">
                                                                <div class="b-r-7 pl-2 pt-1 pb-1">
                                                                    <div class="text-blue font-600 fs-14">
                                                                        Size : <span> Small</span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr v-if="item.addons.length > 0" :key="item._id+'ff'" class="px-3" style="height: auto;background: #f5f6f9;">
                                                            <td colspan="7"
                                                                style="padding: 0px 12px!important;background-color:#ffffff4a !important;">
                                                                <div class="b-r-7 pl-2 pt-1 pb-1">
                                                                    <div class="text-black font-600 fs-14">
                                                                        Modifier :
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr v-for="(addon,addon_index) in item.addons" class="px-3 pb-1" style="height: auto;background: #f5f6f9;" :key="addon_index">
                                                            <td style="padding: 0px!important;padding-left: 12px!important;padding-right: 12px!important;background-color:#ffffff4a !important;">
                                                                <div class="b-r-7 pl-2">
                                                                    <div class="d-flex flex-wrap">
                                                                        <div 
                                                                            class="d-flex justify-content-between align-items-center">
                                                                            <div class="col pl-0 mb-1">
                                                                                <div class="d-flex ml-0 pl-0">
                                                                                    <el-tooltip
                                                                                        v-if="addon.type_name=='Non-Veg'"
                                                                                        content="Non-Veg" placement="top">
                                                                                        <img class="mr-1"
                                                                                            src="https://cdn.digicollect.com/cdn/pos/images-new-theme/non-veg.svg"
                                                                                            style="width: 13px!important;">
                                                                                    </el-tooltip>
                                                                                    <el-tooltip
                                                                                        v-else-if="addon.type_name=='Veg'"
                                                                                        content="Veg" placement="top">
                                                                                        <img class="mr-1"
                                                                                            src="https://cdn.digicollect.com/cdn/pos/images-new-theme/veg.svg"
                                                                                            style="width: 13px!important;">
                                                                                    </el-tooltip>
                                                                                    <el-tooltip
                                                                                        v-else-if="addon.type_name=='Egg'"
                                                                                        content="Egg" placement="top">
                                                                                        <img class="mr-1"
                                                                                            src="https://cdn.digicollect.com/cdn/pos/images-new-theme/egg.svg"
                                                                                            style="width: 13px!important;">
                                                                                    </el-tooltip>
                                                                                    <el-tooltip
                                                                                        v-else-if="addon.type_name == 'Pescatarian'"
                                                                                        content="Pescatarian"
                                                                                        placement="top">
                                                                                        <img class="mr-1"
                                                                                            style="width:13px;"
                                                                                            src="https://cdn.digicollect.com/cdn/pos/images-new-theme/psec.svg">
                                                                                    </el-tooltip>
                                                                                    <span class="font-weight-bold"
                                                                                        :class="{'ml-1':addon.type_name != ''}">{{addon.name}}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td class="text-center"
                                                                style="padding: 0px 12px 3px!important;background-color:#ffffff4a !important;">
                                                                <div class="b-r-7 pl-2">
                                                                    <span v-if="item.quantity <= 1">
                                                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/disable-minus-new.svg"
                                                                            style="width:20px!important;height: 20px!important;margin-top: -6px;" />
                                                                    </span>
                                                                    <span @click="decreaseItemQuantity(cindex,itemIndex)"
                                                                        v-else>
                                                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/qty-minus-new.svg"
                                                                            style="width:20px!important;height: 20px!important;margin-top: -6px;">
                                                                    </span>
                                                                    <span class="qty-checkout mx-2">
                                                                        <input class="qty-input-checkout" :value="item.quantity"
                                                                            disabled>
                                                                    </span>
                                                                    <span @click="increaseItemQuantity(cindex,itemIndex)">
                                                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/plus-qty-new.svg"
                                                                            style="width:20px!important;height: 20px!important;margin-top: -6px;">
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <td
                                                                style="padding: 0px!important;padding-left: 12px!important;padding-right: 12px!important;background-color:#ffffff4a !important;">
                                                                <div class="b-r-7">
                                                                    {{addon.price}}
                                                                </div>
                                                            </td>
                                                            <td
                                                                style="padding: 0px!important;padding-left: 12px!important;padding-right: 12px!important;background-color:#ffffff4a !important;">
                                                                <div class="b-r-7 pl-2">
                                                                    
                                                                </div>
                                                            </td>
                                                            <td
                                                                style="padding: 0px!important;padding-left: 12px!important;padding-right: 12px!important;background-color:#ffffff4a !important;">
                                                                <div class="b-r-7">
                                                                    {{addon.amount}}
                                                                    <el-tooltip class="item" effect="dark" content="Here the Total Amount is calculated based on = Product Qty * Modifier Qty * Modifier Rate" placement="top">
                                                                        <i class="icon icon-information" style="width: 15px !important;height: 15px !important;font-size: 15px !important;color: #00448b;position: relative;"></i>
                                                                    </el-tooltip>
                                                                </div>
                                                            </td>
                                                            <td
                                                                style="padding: 0px!important;padding-left: 12px!important;padding-right: 12px!important;background-color:#ffffff4a !important;">
                                                                <div class="b-r-7 pl-2">
                                                                    
                                                                </div>
                                                            </td>
                                                            <td  colspan="7" class="position-relative"
                                                                style="padding: 0 12px!important;background-color:#ffffff4a !important;">
                                                            </td>
                                                        </tr>
                                                    </template> -->
                                                    <!-- <tr v-if="item.modifiers.for_secondary.length>0" :key="itemIndex+'ffoom'" style="height: auto !important;">
                                                        <td colspan="7"
                                                            style="padding: 0px 12px 3px!important;background-color:#f5f6f9 !important;">
                                                            <div class="my-2 fs-16 fw-600" style="color: #000;">
                                                                Modifiers :
                                                            </div>
                                                        </td>
                                                    </tr> -->
                                                    <tr :key="itemIndex+'ffoom'+cindex" style="height: auto !important;">
                                                        <td colspan="7"
                                                            style="padding: 3px 12px!important;background-color:#f5f6f9 !important;">
                                                            <div class="text-secondary fs-15">
                                                                {{item.variant_name || ""}}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <template v-if="item.modifiers.hasOwnProperty('for_secondary')">
                                                        <tr style="height: auto !important;" v-for="(modifier, index_mod) in item.modifiers.for_secondary" :key="index_mod+'fcfoom'">
                                                            <td
                                                                style="padding: 0px 12px 3px!important;background-color:#f5f6f9 !important;width: 230px;">
                                                                <span class="fw-bold text-capitalize whiteSpace" style="color: #5f6265 !important;">{{modifier.m_item_name}}</span>
                                                            </td>
                                                            <!-- <td
                                                                style="padding: 0px 12px 3px!important;background-color:#f5f6f9 !important;">
                                                                <span class="fw-bold text-capitalize whiteSpace" style="color: #5f6265 !important;">{{modifier.m_name}}: {{modifier.m_item_name}}</span>
                                                            </td> -->
                                                            <td class="text-center whiteSpace"
                                                                style="padding: 0px 12px 3px!important;background-color:#f5f6f9 !important;width: 281px;">
                                                                <div v-if="modifier.has_price" class="b-r-7">
                                                                    <span v-if="modifier.quantity <= 1">
                                                                        <img src="https://cdn.digicollect.com/cdn/posv2/disabled-minus-blue.svg"
                                                                            style="width:20px!important;height: 20px!important;margin-top: -6px;cursor: not-allowed;" />
                                                                    </span>
                                                                    <span @click="decreaseModifierQuantity(cindex, itemIndex, index_mod)"
                                                                        v-else>
                                                                        <img src="https://cdn.digicollect.com/cdn/posv2/minus-blue.svg"
                                                                            style="width:20px!important;height: 20px!important;margin-top: -6px;">
                                                                    </span>
                                                                    <span class="qty-checkout mx-2">
                                                                        <input class="qty-input-checkout" :value="modifier.quantity"
                                                                            disabled>
                                                                    </span>
                                                                    <span v-if="modifier.quantity >= modifier.max_quantity_to_be_selected">
                                                                        <img src="https://cdn.digicollect.com/cdn/posv2/disabled-plus-blue.svg"
                                                                            style="width:20px!important;height: 20px!important;margin-top: -6px;cursor: not-allowed;" />
                                                                    </span>
                                                                    <span v-else @click="increaseModifierQuantity(cindex,itemIndex,index_mod)">
                                                                        <img src="https://cdn.digicollect.com/cdn/posv2/plus-blue.svg"
                                                                            style="width:20px!important;height: 20px!important;margin-top: -6px;">
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <td
                                                                style="padding: 0px!important;padding-left: 12px!important;padding-right: 12px!important;background-color:#f5f6f9 !important;">
                                                                <div v-if="modifier.has_price" class="b-r-7">
                                                                    {{modifier.price}}
                                                                </div>
                                                            </td>
                                                            <td
                                                                style="padding: 0px!important;padding-left: 12px!important;padding-right: 12px!important;background-color:#f5f6f9 !important;">
                                                                <div class="b-r-7 pl-2">
                                                                    
                                                                </div>
                                                            </td>
                                                            <td
                                                                style="padding: 0px!important;padding-left: 12px!important;padding-right: 12px!important;background-color:#f5f6f9 !important;">
                                                                <div v-if="modifier.has_price" class="b-r-7">
                                                                    {{modifier.amount}}
                                                                </div>
                                                            </td>
                                                            <td
                                                                style="padding: 0px!important;padding-left: 12px!important;padding-right: 12px!important;background-color:#f5f6f9 !important;">
                                                                <div class="b-r-7 pl-2">
                                                                    
                                                                </div>
                                                            </td>
                                                            <td  class="position-relative"
                                                                style="padding: 0 12px!important;background-color:#f5f6f9 !important;">
                                                            </td>
                                                        </tr>
                                                    </template>
                                                    <template v-if="item.modifiers.hasOwnProperty('for_primary')">
                                                        <tr style="height: auto !important;" v-for="(modifier, index_mod) in item.modifiers.for_primary" :key="index_mod+'fcfoom'">
                                                            <td colspan="7" style="padding: 10px 0 0 0 !important;">
                                                                <table class="w-100">
                                                                    <thead>
                                                                        <tr style="height: auto !important;">
                                                                            <th colspan="7" style="padding: 5px 12px 3px!important;background-color:#f5f6f9 !important; border-bottom: 0 !important;">
                                                                                <span class="fw-bold text-capitalize whiteSpace text-secondary">{{modifier.modifier_name}}</span>
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr style="height: auto !important;" v-for="(modifier_item, index_mod_item) in modifier.modifier_items" :key="index_mod_item+'fcfoom'">
                                                                            <td
                                                                                style="padding: 0px 12px 3px!important;background-color:#f5f6f9 !important;width: 230px;">
                                                                                <span class="fw-bold text-capitalize whiteSpace" style="color: #5f6265 !important;">{{modifier_item.m_item_name}}</span>
                                                                            </td>
                                                                            <td class="text-center whiteSpace"
                                                                                style="padding: 0px 12px 3px!important;background-color:#f5f6f9 !important;width: 281px;">
                                                                                <div v-if="modifier_item.has_price" class="b-r-7">
                                                                                    <span v-if="modifier_item.quantity <= 1">
                                                                                        <img src="https://cdn.digicollect.com/cdn/posv2/disabled-minus-blue.svg"
                                                                                            style="width:20px!important;height: 20px!important;margin-top: -6px;cursor: not-allowed;" />
                                                                                    </span>
                                                                                    <span @click="decreaseModifierItemQuantity(cindex, itemIndex, index_mod, index_mod_item)"
                                                                                        v-else>
                                                                                        <img src="https://cdn.digicollect.com/cdn/posv2/minus-blue.svg"
                                                                                            style="width:20px!important;height: 20px!important;margin-top: -6px;">
                                                                                    </span>
                                                                                    <span class="qty-checkout mx-2">
                                                                                        <input class="qty-input-checkout" :value="modifier_item.quantity"
                                                                                            disabled>
                                                                                    </span>
                                                                                    <span v-if="modifier_item.quantity >= modifier_item.max_quantity_to_be_selected">
                                                                                        <img src="https://cdn.digicollect.com/cdn/posv2/disabled-plus-blue.svg"
                                                                                            style="width:20px!important;height: 20px!important;margin-top: -6px;cursor: not-allowed;" />
                                                                                    </span>
                                                                                    <span v-else @click="increaseModifierItemQuantity(cindex,itemIndex,index_mod, index_mod_item)" >
                                                                                        <img src="https://cdn.digicollect.com/cdn/posv2/plus-blue.svg"
                                                                                            style="width:20px!important;height: 20px!important;margin-top: -6px;">
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            <td
                                                                                style="padding: 0px!important;padding-left: 12px!important;padding-right: 12px!important;background-color:#f5f6f9 !important;">
                                                                                <div v-if="modifier_item.has_price" class="b-r-7">
                                                                                    {{modifier_item.price}}
                                                                                </div>
                                                                            </td>
                                                                            <td
                                                                                style="padding: 0px!important;padding-left: 12px!important;padding-right: 12px!important;background-color:#f5f6f9 !important;">
                                                                                <div class="b-r-7 pl-2">
                                                                                    
                                                                                </div>
                                                                            </td>
                                                                            <td
                                                                                style="padding: 0px!important;padding-left: 12px!important;padding-right: 12px!important;background-color:#f5f6f9 !important;">
                                                                                <div v-if="modifier_item.has_price" class="b-r-7">
                                                                                    {{modifier_item.amount}}
                                                                                </div>
                                                                            </td>
                                                                            <td
                                                                                style="padding: 0px!important;padding-left: 12px!important;padding-right: 12px!important;background-color:#f5f6f9 !important;">
                                                                                <div class="b-r-7 pl-2">
                                                                                    
                                                                                </div>
                                                                            </td>
                                                                            <td class="position-relative"
                                                                                style="padding: 0 12px!important;background-color:#f5f6f9 !important;">
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                    <tr :key="itemIndex+'kllss'" style="height:18px !important;"
                                                        v-if="order.promotion_applied">
                                                        <td  colspan="7"
                                                            style="color:#1d9449 !important;font-weight:600 !important;font-size:12px !important;">
                                                            (Offer Applied to this Item: {{order.promotion_type}})</td>
                                                    </tr>
                                                </template>
                                            </template>
                                            <tr style="height: 29px;" class="subtotal"
                                                v-for="(category, index) in order.categories" :key="index+'lnmc'"
                                                :class="{'borderTop':index == 0}">
                                                <th scope="row" style="padding: 0.4rem 1.1rem !important;"></th>
                                                <td class="text-center" style="padding: 0.6rem 1.2rem;"></td>
                                                <td
                                                    style="padding: 0.6rem 1.2rem;text-transform: capitalize!important;">
                                                    {{category.name}}</td>
                                                <td class="text-center" style="padding: 0.6rem 1.2rem;"></td>
                                                <td style="padding: 0.6rem 1.2rem;">{{category.total}}</td>
                                                <td class="text-center" style="padding: 0.6rem 1.2rem;"></td>
                                                <td style="padding: 0.6rem 1.2rem;"  colspan="7" class="text-center" v-if="index == 0">
                                                    <el-dropdown trigger="click">
                                                        <span class="el-dropdown-link pointer ml-1" @click="fetchItems">
                                                            <i class="icon icon-dots-vertical text-secondary"></i>
                                                        </span>
                                                        <el-dropdown-menu slot="dropdown">
                                                                <!-- <div v-if="loggedInUser.role == 'default_manager' || loggedInUser.role == 'default_order_taker' && loggedInUser.access_change_discount == true">
                                                                    <div v-for="(dd_course, dd_index) in dd_courses" :key="dd_index"  >
                                                                        <div v-for="(item,item_indx) in dd_course.items" :key="item_indx">
                                                                            <el-dropdown-item v-if="parseInt(item.discount.amount.replace('(','').replace(')','').slice(1,)) <= 0">
                                                                                <span @click="item.popover=true">
                                                                                    course {{dd_course.course}} - Add Item Discount {{item_indx+1}}
                                                                                </span>
                                                                            </el-dropdown-item>
                                                                        </div>
                                                                    </div>
                                                                </div> -->
                                                                <div v-if="(parseInt(order.discount_amount.substring(1)) <= 0) && order.discount.approval_message == '' && loggedInUser.access_change_discount == true && order.discount.show_edit == true && order.payment_status != 'PAID'">
                                                                    <el-dropdown-item>    
                                                                        <div @click="openOrderDiscount(order)" >
                                                                            Add Order Discount
                                                                        </div>
                                                                    </el-dropdown-item>
                                                                </div>
                                                                <el-dropdown-item v-if="parseInt(order.promotion_value.replace('(','').replace(')','').slice(1,)) <= 0">
                                                                    <div @click="showpromotions()">
                                                                        Add Promotions
                                                                    </div>
                                                                </el-dropdown-item>
                                                        </el-dropdown-menu>
                                                    </el-dropdown>
                                                    <div>
                                                        <el-popover placement="bottom" v-model="order_charges_discount"
                                                        width="300" class="right-zero" trigger="click">
                                                            <div class="digi-table-loader d-flex align-items-center justify-content-center" style="margin: 0 auto" v-if="order_discount_loading">
                                                                <div></div>
                                                                <div></div>
                                                                <div></div>
                                                                <div></div>
                                                            </div>
                                                            <div v-else>
                                                                <span class="defaultBlue mt-1 pl-2">
                                                                    Order Discount:
                                                                </span>
                                                                <div class="pull-right pr-2" style="margin: 0 auto;">
                                                                    <span @click="closeDiscount(order)">
                                                                        <i class="icon icon-close"
                                                                            style="color: rgb(184, 212, 255); cursor: pointer;font-size: 18px;"></i>
                                                                    </span>
                                                                </div>
                                                                <div class="d-flex align-items-center justify-content-between mt-3 px-2">
                                                                    <span class="fs-14 text-uppercase" style="color: #000;">
                                                                        {{order.order_id}}
                                                                    </span>
                                                                    <span class="fs-14" v-if="order.double_discount == true" style="color: #000;">
                                                                        {{order.sub_total}}
                                                                    </span>
                                                                    <span class="fs-14" v-else style="color: #000;">
                                                                        {{order.sub_total_without_discount_items}}
                                                                    </span>
                                                                </div>
                                                                <div class="px-2" v-if="order.discount">
                                                                    <div class="d-flex align-items-center mt-2">
                                                                        <input type="number" class="enterCharges percent-input-field w-100"
                                                                            v-model="order.discount.value" @input="orderDiscountChange(order)" placeholder="Enter Discount"
                                                                            v-on:keypress="isNumber($event)">
                                                                        <span class="percent-input-field-box text-white">
                                                                            %
                                                                        </span>
                                                                    </div>
                                                                    <div class="d-flex align-items-center mt-2">
                                                                        <span class="amount-input-field-box text-white">
                                                                            {{order.currency.symbol}}
                                                                        </span>
                                                                        <input type="number" class="enterCharges amount-input-field w-100"
                                                                            v-model="order_discounted_amount" @input="orderDiscountChangeAmount(order)" placeholder="Discounted Amount"
                                                                            v-on:keypress="isNumber($event)">
                                                                    </div>
                                                                    <textarea v-model="order.discount.reason" class="w-100 my-2" style="padding: 4.2px 7.8px 34.3px 7.8px;border-radius: 4px;border: solid 0.5px #b5b4b4;background-color: #fff;"></textarea>
                                                                    <button type="submit" class="applyCharges position-relative" style="border-radius: 20px !important; float: right;"
                                                                        @click="updateDiscount">APPLY</button>
                                                                </div>
                                                            </div>
                                                        </el-popover>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr v-if="order.sub_total_without_discount" class="subtotal" style="height: 29px;">
                                                <th scope="row"
                                                    style="padding: 0.4rem 1.1rem !important;border-top:1px dotted #00448b;">
                                                </th>
                                                <td class="text-center"
                                                    style="padding: 0.6rem 1.2rem;border-top:1px dotted #00448b;"></td>
                                                <td style="padding: 0.6rem 1.2rem;border-top:1px dotted #00448b;">
                                                    Sub-Total Without Discount</td>
                                                <td class="text-center"
                                                    style="padding: 0.6rem 1.2rem;border-top:1px dotted #00448b;"></td>
                                                <td style="padding: 0.6rem 1.2rem;border-top:1px dotted #00448b;">
                                                    {{order.sub_total_without_discount}}</td>
                                                <td class="text-center"
                                                    style="padding: 0.6rem 1.2rem;border-top:1px dotted #00448b;"></td>
                                                <td style="padding: 0.6rem 1.2rem;border-top:1px dotted #00448b;"  colspan="7"></td>
                                            </tr>
                                            <tr class="subtotal" v-if="!(parseInt(order.items_discount_total.replace('(','').replace(')','').slice(1,)) <= 0)" style="height: 29px;">
                                                <th scope="row"
                                                    style="padding: 0.4rem 1.1rem !important;border-top:1px dotted #00448b;">
                                                </th>
                                                <td class="text-center"
                                                    style="padding: 0.6rem 1.2rem;border-top:1px dotted #00448b;"></td>
                                                <td style="padding: 0.6rem 1.2rem;border-top:1px dotted #00448b;">Item
                                                    Discount</td>
                                                <td class="text-center"
                                                    style="padding: 0.6rem 1.2rem;border-top:1px dotted #00448b;"></td>
                                                <td style="padding: 0.6rem 1.2rem;border-top:1px dotted #00448b;">(
                                                    {{order.items_discount_total}} )</td>
                                                <td class="text-center"
                                                    style="padding: 0.6rem 1.2rem;border-top:1px dotted #00448b;"></td>
                                                <td style="padding: 0.6rem 1.2rem;border-top:1px dotted #00448b;"  colspan="7"></td>
                                            </tr>
                                            <tr class="subtotal" v-if="order.discount.value > 0 || order.discount.approval_message != ''" style="height: 29px;">
                                                <th scope="row" style="padding: 0.4rem 1.1rem !important;"></th>
                                                <td class="text-center" style="padding: 0.6rem 1.2rem;"></td>
                                                <td style="padding: 0.6rem 1.2rem;white-space: nowrap;">Order Discount
                                                    <span v-if="order.discount && order.discount.type == 'PERCENT'">
                                                        (%)
                                                    </span>
                                                    <div v-if="order.discount.reason != ''" class="text-secondary font-italic fs-13">
                                                        {{order.discount.reason}}
                                                    </div> 
                                                </td>
                                                <td class="text-center" style="padding: 0.6rem 1.2rem;"></td>
                                                <td style="padding: 0.6rem 1.2rem;">
                                                    <div class="d-flex align-items-center">
                                                        <span style="width: max-content;">( {{order.discount_amount}} )</span>
                                                        <i class="icon-minus-circle text-danger pointer ml-2" v-if="order.discount.show_edit == false" @click="withdrawItem(order._id, '', 'order')" style="width: 15px !important;font-size: 15px !important;color: #00448b;position: relative;"></i>
                                                        <span class="ml-2" v-if="(order.discount.approval_message && order.discount.approval_message != '')">
                                                            <el-tooltip class="item" effect="dark" :content="order.discount.approval_message" placement="top">
                                                                <i class="icon icon-information" style="width: 15px !important;height: 15px !important;font-size: 15px !important;color: #00448b;position: relative;"></i>
                                                            </el-tooltip>
                                                        </span>
                                                        <el-button slot="reference" class="el-button-edit ml-2" v-if="loggedInUser.role == 'default_manager' || (order.discount.show_edit == true && order.payment_status != 'PAID' && loggedInUser.role == 'default_order_taker' && loggedInUser.access_change_discount)"
                                                            @click="editOrderDiscount">
                                                            <button class="btn btn-icon companyactions actionicons">
                                                                <i class="icon icon-pencil" style="width: 15px !important;height: 15px !important;font-size: 15px !important;color: #00448b;bottom: 2px;position: relative;"></i>
                                                            </button>
                                                        </el-button>
                                                    </div>
                                                </td>
                                                <td class="text-center" style="padding: 0.6rem 1.2rem;"></td>
                                                <!-- <td style="padding: 0.6rem 1.2rem;"  colspan="7" v-if="!(parseInt(order.discount_amount.replace('(','').replace(')','').slice(1,)) <= 0) && order.discount.value">
                                                    <el-popover placement="bottom" v-model="order_charges_discount"
                                                        width="300" class="right-zero" trigger="click">
                                                        <div class="row">
                                                            <span class="defaultBlue mt-1 pl-3">
                                                                Select:
                                                            </span>
                                                            <span class="preview-elements pl-2">
                                                                <div class="form-check form-check-inline">
                                                                    <label class="form-check-label"
                                                                        v-if="order.discount">
                                                                        <input type="radio" value="AMOUNT"
                                                                            class="form-check-input"
                                                                            name="order-addn-charge"
                                                                            v-model="order.discount.type" checked>
                                                                        <span class="radio-icon"></span>
                                                                        <span
                                                                            class="form-check-description">Price</span>
                                                                    </label>
                                                                </div>
                                                                <div class="form-check form-check-inline">
                                                                    <label class="form-check-label"
                                                                        v-if="order.discount">
                                                                        <input type="radio" value="PERCENT"
                                                                            class="form-check-input"
                                                                            name="order-addn-charge"
                                                                            v-model="order.discount.type">
                                                                        <span class="radio-icon"></span>
                                                                        <span
                                                                            class="form-check-description">Percentage</span>
                                                                    </label>
                                                                </div>
                                                            </span>
                                                            <div class="pull-right" style="margin: 0 auto;">
                                                                <span @click="closeDiscount">
                                                                    <i class="icon icon-close"
                                                                        style="color: rgb(184, 212, 255); cursor: pointer;font-size: 18px;"></i>
                                                                </span>
                                                            </div>
                                                            <div class="px-2" v-if="order.discount">
                                                                <input type="number" class="enterCharges mt-2 w-100"
                                                                    v-model="order.discount.value"
                                                                    v-on:keypress="isNumber($event)" style="padding: 4px 10.9px 4px 10.9px;border-radius: 9px;box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);background-color: #fff;">
                                                                <textarea v-model="order.discount.reason" class="w-100 my-2" style="padding: 4.2px 7.8px 34.3px 7.8px;border-radius: 4px;border: solid 0.5px #b5b4b4;background-color: #fff;"></textarea>
                                                                <button type="submit" class="applyCharges position-relative" style="border-radius: 20px !important; float: right;"
                                                                    @click="updateDiscount">APPLY</button>
                                                            </div>
                                                        </div>
                                                    </el-popover>
                                                </td> -->
                                            </tr>
                                            <tr class="subtotal" v-if="!(parseInt(order.promotion_value.replace('(','').replace(')','').slice(1,)) <= 0)" style="height: 29px;">
                                                <th scope="row"
                                                    style="padding: 0.4rem 1.1rem !important;border-top:1px dotted #00448b;">
                                                </th>
                                                <td class="text-center"
                                                    style="padding: 0.6rem 1.2rem;border-top:1px dotted #00448b;"></td>
                                                <td style="padding: 0.6rem 1.2rem;border-top:1px dotted #00448b;">
                                                    Promotion/Entertainment</td>
                                                <td class="text-center"
                                                    style="padding: 0.6rem 1.2rem;border-top:1px dotted #00448b;"></td>
                                                <td style="padding: 0.6rem 1.2rem;border-top:1px dotted #00448b;">
                                                    ({{order.promotion_value}} )</td>
                                                <td class="text-center"
                                                    style="padding: 0.6rem 1.2rem;border-top:1px dotted #00448b;"></td>
                                                <td style="padding: 0.6rem 1.2rem;border-top:1px dotted #00448b;"  colspan="7">
                                                    <button v-if="order.payment_status != 'PAID' && loggedInUser.access_change_discount" class="btn btn-icon companyactions actionicons"
                                                        style="padding: 0.4rem 1.3rem;" @click="showpromotions()">
                                                        <i class="icon icon-pencil"></i>
                                                    </button>
                                                    <button v-if="order.promotion_applied"
                                                        class="btn btn-icon companyactions actionicons"
                                                        style="padding: 0.4rem 1.3rem;" @click="removepromotion()">
                                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/minusRed.svg"
                                                            style="width:17px;">
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr v-if="order.sub_total_with_discount" class="subtotal" style="height: 29px;border-bottom: 1px dotted #00448b">
                                                <th scope="row"
                                                    style="border-top: 1px dotted #00448b;padding: 0.4rem 1.1rem !important;">
                                                </th>
                                                <td class="text-center"
                                                    style="border-top: 1px dotted #00448b;padding: 0.6rem 1.2rem;"></td>
                                                <td style="border-top: 1px dotted #00448b;padding: 0.6rem 1.2rem;">
                                                    Sub-Total With Discount</td>
                                                <td class="text-center"
                                                    style="border-top: 1px dotted #00448b;padding: 0.6rem 1.2rem;"></td>
                                                <!-- <td class="text-center" style="border-top: 1px dotted #dcc8c8;"></td> -->
                                                <td style="border-top: 1px dotted #00448b;padding: 0.6rem 1.2rem;">
                                                    {{order.sub_total_with_discount}}</td>
                                                <td class="text-center"
                                                    style="border-top: 1px dotted #00448b;padding: 0.6rem 1.2rem;"></td>
                                                <td style="border-top: 1px dotted #00448b;padding: 0.6rem 1.2rem;"  colspan="7"></td>
                                            </tr>
                                            <tr v-if="!order.sub_total_with_discount && !order.sub_total_without_discount" class="subtotal" style="height: 29px;border-bottom: 1px dotted #00448b">
                                                <th scope="row"
                                                    style="border-top: 1px dotted #00448b;padding: 0.4rem 1.1rem !important;">
                                                </th>
                                                <td class="text-center"
                                                    style="border-top: 1px dotted #00448b;padding: 0.6rem 1.2rem;"></td>
                                                <td style="border-top: 1px dotted #00448b;padding: 0.6rem 1.2rem;">
                                                    Sub-Total</td>
                                                <td class="text-center"
                                                    style="border-top: 1px dotted #00448b;padding: 0.6rem 1.2rem;"></td>
                                                <!-- <td class="text-center" style="border-top: 1px dotted #dcc8c8;"></td> -->
                                                <td style="border-top: 1px dotted #00448b;padding: 0.6rem 1.2rem;">
                                                    {{order.sub_total}}</td>
                                                <td class="text-center"
                                                    style="border-top: 1px dotted #00448b;padding: 0.6rem 1.2rem;"></td>
                                                <td style="border-top: 1px dotted #00448b;padding: 0.6rem 1.2rem;"  colspan="7"></td>
                                            </tr>
                                            <tr class="subtotal" style="height: 29px;" v-if="editOrder">
                                                <th scope="row" style="padding: 0.4rem 1.1rem !important;"></th>
                                                <td class="text-center" style="padding: 0.6rem 1.2rem;"></td>
                                                <td style="padding: 0.6rem 1.2rem;">Old Sub Total</td>
                                                <td class="text-center" style="padding: 0.6rem 1.2rem;"></td>
                                                <!-- <td class="text-center" style="border-top: 1px dotted #dcc8c8;"></td> -->
                                                <td style="padding: 0.6rem 1.2rem;">{{order.old_sub_total}}</td>
                                                <td class="text-center" style="padding: 0.6rem 1.2rem;"></td>
                                                <td style="padding: 0.6rem 1.2rem;"  colspan="7"></td>
                                            </tr>
                                            <!-- <tr class="subtotal" style="height: 29px;">
                                            <th scope="row" style="border-top:1px dotted #00448b;padding: 0.4rem 1.1rem !important;"></th>
                                            <td class="text-center" style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;"></td>
                                            <td class="text-center" style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;"></td>
                                            <td class="text-center" style="border-top: 1px dotted #dcc8c8;"></td>
                                            <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;">Subtotal</td>
                                            <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;">{{order.sub_total}}</td>
                                            <td class="text-center" style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;"></td>
                                            <td style="border-top: 1px dotted #00448b;padding: 0.6rem 1.2rem;"></td>
                                            </tr> -->
                                            <!-- <tr class="subtotal" style="height: 29px;">
                                            <th scope="row" style="border-top:1px dotted #00448b;padding: 0.4rem 1.1rem !important;"></th>
                                            <td class="text-center" style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;"></td>
                                            <td class="text-center" style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;"></td>
                                            <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;white-space: nowrap;">Order Additional Charges</td>
                                            <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;">{{order.additional_amount}}</td>
                                            <td class="text-center" style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;"></td>
                                            <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;">
                                                <el-popover placement="bottom" v-model="order_charges_popover" width="300" trigger="click">
                                                <div class="row">
                                                    <span class="defaultBlue mt-1 pl-3">
                                                    Select:
                                                    </span>
                                                    <span class="preview-elements pl-2">
                                                    <div class="form-check form-check-inline">
                                                        <label class="form-check-label">
                                                        <input type="radio" value="AMOUNT" class="form-check-input" name="order-addn-charge" v-model="order.additional_charges.type" checked>
                                                        <span class="radio-icon"></span>
                                                        <span class="form-check-description">Price</span>
                                                        </label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <label class="form-check-label">
                                                        <input type="radio" value="PERCENT" class="form-check-input" name="order-addn-charge" v-model="order.additional_charges.type">
                                                        <span class="radio-icon"></span>
                                                        <span class="form-check-description">Percentage</span>
                                                        </label>
                                                    </div>
                                                    </span>
                                                    <div class="pull-right" style="margin: 0 auto;" @click="closeAdditionalCharges">
                                                    <span>
                                                        <i class="icon icon-close" style="color: rgb(184, 212, 255); cursor: pointer;font-size: 18px;"></i>
                                                    </span>
                                                    </div>
                                                    <div class="additionalCharges pl-2">
                                                    <input type="number" class="enterCharges" v-model="order.additional_charges.value" v-on:keypress="isNumber($event)">
                                                    <button type="submit" class="applyCharges" @click="updateAdditionalCharges">APPLY</button>
                                                    </div>
                                                </div>
                                            </el-popover>
                                            </td>
                                            </tr> -->

                                            <!-- Gotta replace with charges here!!! -->
                                            <tr class="subtotal" style="height: 29px;"
                                                v-for="(other_charges,index) in order.other_charges"
                                                :key="other_charges.code">
                                                <th scope="row" style="padding: 0.4rem 1.1rem !important;"></th>
                                                <td style="padding: 0.6rem 1.2rem;" class="text-center"></td>
                                                <td style="padding: 0.6rem 1.2rem;white-space:nowrap !important;">
                                                    {{other_charges.name}} <span
                                                        v-if="other_charges.selected_type == 'PERCENTAGE'">({{other_charges.tax_rate}}%)</span>
                                                    <span v-if="other_charges.type == 'CUSTOM'">
                                                        <el-tooltip class="item" effect="dark"
                                                            content="Discount not applicable for this charge"
                                                            placement="top">
                                                            <img
                                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/info_checkout.svg">
                                                        </el-tooltip>
                                                    </span>
                                                </td>
                                                <td style="padding: 0.6rem 1.2rem;" class="text-center"></td>
                                                <!-- <td class="text-center"></td> -->
                                                <td style="padding: 0.6rem 1.2rem;">{{other_charges.value}}</td>
                                                <td style="padding: 0.6rem 1.2rem;" class="text-center"  colspan="7"></td>
                                                <td style="padding: 0.6rem 1.2rem;"
                                                    v-if="other_charges.type == 'CUSTOM'">
                                                    <el-popover placement="bottom" v-model="other_charges.show"
                                                        width="300" trigger="click">
                                                        <div class="row">
                                                            <span class="defaultBlue mt-1 pl-3">
                                                                Select:
                                                            </span>
                                                            <span class="preview-elements pl-2"
                                                                style="padding-top: 4px;">
                                                                <el-radio v-model="other_charges.selected_type"
                                                                    label="AMOUNT">Price</el-radio>
                                                                <el-radio v-model="other_charges.selected_type"
                                                                    label="PERCENTAGE">Percentage</el-radio>
                                                                <!-- <div class="form-check form-check-inline">
                                                            <label class="form-check-label">

                                                            <input type="radio" value="AMOUNT" class="form-check-input" name="order-addn-charge" v-model="other_charges.selected_type" checked>
                                                            <span class="radio-icon"></span>
                                                            <span class="form-check-description">Price</span>
                                                            </label>
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <label class="form-check-label">
                                                            <input type="radio" value="PERCENTAGE" class="form-check-input" name="order-addn-charge" v-model="other_charges.selected_type">
                                                            <span class="radio-icon"></span>
                                                            <span class="form-check-description">Percentage</span>
                                                            </label>
                                                        </div> -->
                                                            </span>
                                                            <div class="pull-right" style="margin: 0 auto;">
                                                                <span @click="closeOtherCharges(index)">
                                                                    <i class="icon icon-close"
                                                                        style="color: rgb(184, 212, 255); cursor: pointer;font-size: 18px;"></i>
                                                                </span>
                                                            </div>
                                                            <div class="additionalCharges pl-2">
                                                                <input type="number" class="enterCharges"
                                                                    v-model="other_charge_amount"
                                                                    v-on:keypress="isNumber($event)"
                                                                    @input="checkNumber(index)">
                                                                <button type="submit" class="applyCharges"
                                                                    @click="updateOtherCharge(index)">APPLY</button>
                                                            </div>
                                                        </div>
                                                        <el-button slot="reference" class="el-button-edit"
                                                            @click="showOtherCharge(index)">
                                                            <button class="btn btn-icon companyactions actionicons"
                                                                style="padding: 0.4rem 1.3rem;">
                                                                <i class="icon icon-pencil"></i>
                                                            </button>
                                                        </el-button>
                                                    </el-popover>
                                                </td>
                                            </tr>
                                            <tr class="subtotal" style="height: 29px;" v-if="order.loyalty_applied">
                                                <th scope="row"
                                                    style="border-top:1px dotted #00448b;padding: 0.4rem 1.1rem !important;">
                                                </th>
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;"
                                                    class="text-center"></td>
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;">Reward
                                                    Points</td>
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;"
                                                    class="text-center"></td>
                                                <!-- <td class="text-center"></td> -->
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;">
                                                    {{order.loyalty_amount}}</td>
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;"
                                                    class="text-center"></td>
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;"  colspan="7">
                                                    <span class="pl-3" @click="loyaltyremove()">
                                                        <img
                                                            src="https://cdn.digicollect.com/cdn/pos/images-new-theme/minusRed.svg">
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr v-if="order.total_amount" class="subtotal" style="height: 29px;">
                                                <th scope="row"
                                                    style="border-top:1px dotted #00448b;padding: 0.4rem 1.1rem !important;">
                                                </th>
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;"
                                                    class="text-center"></td>
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;">Total
                                                    Amount</td>
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;"
                                                    class="text-center"></td>
                                                <!-- <td class="text-center"></td> -->
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;">
                                                    {{order.total_amount}}</td>
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;"
                                                    class="text-center"></td>
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;"  colspan="7"></td>
                                            </tr>
                                            <tr class="subtotal" style="height: 29px;" v-for="tax in order.tax"
                                                :key="tax.code">
                                                <th scope="row"
                                                    style="border-top: 1px dotted #00448b;padding: 0.4rem 1.1rem !important;">
                                                </th>
                                                <td style="border-top: 1px dotted #00448b;padding: 0.6rem 1.2rem;"
                                                    class="text-center"></td>
                                                <td style="border-top: 1px dotted #00448b;padding: 0.6rem 1.2rem;">
                                                    {{tax.name}} ({{tax.rate}}%)</td>
                                                <td style="border-top: 1px dotted #00448b;padding: 0.6rem 1.2rem;"
                                                    class="text-center"></td>
                                                <!-- <td class="text-center"></td> -->
                                                <td style="border-top: 1px dotted #00448b;padding: 0.6rem 1.2rem;">
                                                    {{tax.value}}</td>
                                                <td style="border-top: 1px dotted #00448b;padding: 0.6rem 1.2rem;"
                                                    class="text-center"></td>
                                                <td style="border-top: 1px dotted #00448b;padding: 0.6rem 1.2rem;"  colspan="7"></td>
                                            </tr>
                                            <tr class="subtotal" style="height: 29px;"
                                                v-if="order.is_round_off_required">
                                                <th scope="row"
                                                    style="border-top:1px dotted #00448b;padding: 0.4rem 1.1rem !important;">
                                                </th>
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;"
                                                    class="text-center"></td>
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;">Gross
                                                    Total</td>
                                                <!-- <td class="text-center"></td> -->
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;"></td>
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;">
                                                    {{order.gross_total}}</td>
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;"
                                                    class="text-center"></td>
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;"  colspan="7"></td>
                                            </tr>
                                            <tr class="subtotal" style="height: 29px;"
                                                v-if="order.is_round_off_required && !order.is_credit_card_used">
                                                <th scope="row"
                                                    style="border-top:1px dotted #00448b;padding: 0.4rem 1.1rem !important;">
                                                </th>
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;"
                                                    class="text-center"></td>
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;">Round
                                                    Off</td>
                                                <!-- <td class="text-center"></td> -->
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;"></td>
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;">
                                                    {{order.round_off.amount}}</td>
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;"
                                                    class="text-center"></td>
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;"  colspan="7">
                                                </td>
                                            </tr>
                                            <tr class="subtotal" style="height: 40px!important;">
                                                <th scope="row"
                                                    style="padding: 0.4rem 1.1rem !important;background-color: #f29c1f;border-radius: 8px 0px 0px 8px;">
                                                </th>
                                                <td style="padding: 0.6rem 1.2rem;background-color: #f29c1f;"
                                                    class="text-center"></td>
                                                <td style="padding: 0.6rem 1.2rem;font-size:15px;color:#303031!important;background-color: #f29c1f;"
                                                    class="total-checkout-img">Grand Total</td>
                                                <td style="padding: 0.6rem 1.2rem;background-color: #f29c1f;"
                                                    class="text-center"></td>
                                                <!-- <td class="text-center"></td> -->
                                                <td style="padding: 0.6rem 1.2rem;font-size:15px;color:#303031!important;background-color: #f29c1f;"
                                                    class="value-checkout-img">{{order.grand_total}}</td>
                                                <td style="padding: 0.6rem 1.2rem;background-color: #f29c1f;"
                                                    class="text-center"></td>
                                                <td  colspan="7"
                                                    style="padding: 0.6rem 1.2rem;background-color: #f29c1f;border-radius: 0px 8px 8px 0px;">
                                                </td>
                                            </tr>
                                            <tr class="subtotal" v-if="order.upcharge_for_credit_card_usage" style="height: 40px!important;">
                                                <th scope="row"
                                                    style="padding: 0.4rem 1.1rem !important;">
                                                </th>
                                                <td style="padding: 0.6rem 1.2rem;"
                                                    class="text-center"></td>
                                                <td style="padding: 0.6rem 1.2rem;font-size:15px;color:#303031!important;"
                                                    class="total-checkout-img">
                                                    <span class="fw-bold fs-14" style="color: #4e5764;">Payment Type :</span>
                                                    <span class="fs-14" style="color: #4e5764; font-weight: 500;">
                                                        Credit Card
                                                    </span>
                                                </td>
                                                <td style="padding: 0.6rem 1.2rem;"
                                                    class="text-center"></td>
                                                <!-- <td class="text-center"></td> -->
                                                <td style="padding: 0.6rem 1.2rem;font-size:15px;color:#303031!important;"
                                                    class="value-checkout-img">
                                                    <el-switch class="ml-2 approvalswitch" @change="isCreditCardUsed($event)" v-model="order.is_credit_card_used"></el-switch>
                                                </td>
                                                <td style="padding: 0.6rem 1.2rem;"
                                                    class="text-center"></td>
                                                <td  colspan="7"
                                                    style="padding: 0.6rem 1.2rem;">
                                                </td>
                                            </tr>
                                            <tr class="subtotal" v-if="order.is_credit_card_used" style="height: 40px!important;">
                                                <th scope="row"
                                                    style="padding: 0.4rem 1.1rem !important;">
                                                </th>
                                                <td style="padding: 0.6rem 1.2rem;"
                                                    class="text-center"></td>
                                                <td style="padding: 0.6rem 1.2rem;font-size:15px;color:#303031!important;"
                                                    class="total-checkout-img">
                                                    <span class="fw-bold fs-14" style="color: #4e5764;">Credit Card Charges </span>
                                                </td>
                                                <td style="padding: 0.6rem 1.2rem;"
                                                    class="text-center"></td>
                                                <!-- <td class="text-center"></td> -->
                                                <td style="padding: 0.6rem 1.2rem;font-size:15px;color:#303031!important;"
                                                    class="value-checkout-img">
                                                    {{order.credit_card_charges_amount}}
                                                </td>
                                                <td style="padding: 0.6rem 1.2rem;"
                                                    class="text-center"></td>
                                                <td  colspan="7"
                                                    style="padding: 0.6rem 1.2rem;">
                                                </td>
                                            </tr>
                                            <tr class="subtotal" v-if="order.is_credit_card_used" style="height: 40px!important;">
                                                <th scope="row"
                                                    style="padding: 0.4rem 1.1rem !important;">
                                                </th>
                                                <td style="padding: 0.6rem 1.2rem;"
                                                    class="text-center"></td>
                                                <td style="padding: 0.6rem 1.2rem;font-size:15px;color:#303031!important;"
                                                    class="total-checkout-img">
                                                    <span class="fs-14 fw-normal" style="color: #4e5764;">Round Off</span>
                                                </td>
                                                <td style="padding: 0.6rem 1.2rem;"
                                                    class="text-center"></td>
                                                <!-- <td class="text-center"></td> -->
                                                <td style="padding: 0.6rem 1.2rem;font-size:15px;color:#4e5764!important;"
                                                    class="fw-normal">
                                                    ({{order.round_off.amount}})
                                                </td>
                                                <td style="padding: 0.6rem 1.2rem;"
                                                    class="text-center"></td>
                                                <td  colspan="7"
                                                    style="padding: 0.6rem 1.2rem;">
                                                </td>
                                            </tr>
                                            <tr class="subtotal" v-if="order.is_credit_card_used" style="height: 40px!important;">
                                                <th scope="row"
                                                    style="padding: 0.4rem 1.1rem !important;background-color: #f29c1f;border-radius: 8px 0px 0px 8px;">
                                                </th>
                                                <td style="padding: 0.6rem 1.2rem;background-color: #f29c1f;"
                                                    class="text-center"></td>
                                                <td style="padding: 0.6rem 1.2rem;font-size:16px;color:#000!important;background-color: #f29c1f;"
                                                    class="total-checkout-img fw-bold">Grand Total with CC Charges</td>
                                                <td style="padding: 0.6rem 1.2rem;background-color: #f29c1f;"
                                                    class="text-center"></td>
                                                <!-- <td class="text-center"></td> -->
                                                <td style="padding: 0.6rem 1.2rem;font-size:15px;color:#303031!important;background-color: #f29c1f;"
                                                    class="value-checkout-img">{{order.grand_total_with_credit_card_charges}}</td>
                                                <td style="padding: 0.6rem 1.2rem;background-color: #f29c1f;"
                                                    class="text-center"></td>
                                                <td  colspan="7"
                                                    style="padding: 0.6rem 1.2rem;background-color: #f29c1f;border-radius: 0px 8px 8px 0px;">
                                                </td>
                                            </tr>
                                            <tr class="subtotal" style="height: 29px;" v-if="order.is_deposit_used">
                                                <th scope="row"
                                                    style="border-top:1px dotted #00448b;padding: 0.4rem 1.1rem !important;">
                                                </th>
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;"
                                                    class="text-center"></td>
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;">
                                                    Deposit Amount</td>
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;"></td>
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;">
                                                    ({{order.customer_deposit_used}})</td>
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;"
                                                    class="text-center"></td>
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;"  colspan="7">
                                                    <span class="pl-3" @click="removeDepositUsed()">
                                                        <img
                                                            src="https://cdn.digicollect.com/cdn/pos/images-new-theme/minusRed.svg">
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr class="subtotal" v-if="order.is_deposit_used"
                                                style="height: 29px;background: linear-gradient(to bottom, #005dae, #004fe3);">
                                                <th scope="row"
                                                    style="border-top:1px dotted #00448b;padding: 0.4rem 1.1rem !important;">
                                                </th>
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;"
                                                    class="text-center"></td>
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;"
                                                    class="text-white">Amount Due</td>
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;"></td>
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;"
                                                    class="text-white">({{order.grand_total_after_deposit}})</td>
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;"
                                                    class="text-center"></td>
                                                <td  colspan="7" style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;">
                                                </td>
                                            </tr>
                                            <tr class="subtotal" style="height: 29px;"
                                                v-if="order.loyalty_points_earned > 0">
                                                <th scope="row"
                                                    style="border-top:1px dotted #00448b;padding: 0.4rem 1.1rem !important;">
                                                </th>
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;"
                                                    class="text-center"></td>
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;">
                                                    Loyalty Points Earned</td>
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;"></td>
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;">
                                                    {{order.loyalty_points_earned}}</td>
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;"
                                                    class="text-center"></td>
                                                <td  colspan="7" style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;">
                                                </td>
                                            </tr>
                                            <tr v-if="order.is_deposit_used">
                                                <td colspan="7">
                                                    <div class="deposit-details pt-2 mt-4 pb-2">
                                                        <span
                                                            class="text-center text-white font-weight-bold d-block">Deposit
                                                            Info</span>
                                                        <hr style="border:0.7px solid rgb(132, 136, 140);"
                                                            class="ml-3 mr-3">
                                                        <div class="m-4 p-2 text-white font-weight-bold"
                                                            style="position: relative;left: 28%;">
                                                            <div class="d-flex flex-nowrap ">
                                                                <div class="div-content">
                                                                    <div class="content-left specmeter">Issued by</div>
                                                                    <div class="content-right w-150">
                                                                        <span
                                                                            class="right-div whiteSpace">{{order.deposit_information.issued_by || "-"}}</span>
                                                                    </div>
                                                                </div>
                                                                <div class="div-content">
                                                                    <div
                                                                        class="content-left specmeter left-flex-summary">
                                                                        Date of Issue</div>
                                                                    <div class="content-right">
                                                                        <span
                                                                            class="right-div">{{order.deposit_information.date_of_issue_formatted || "-"}}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="d-flex flex-nowrap">
                                                                <div class="div-content">
                                                                    <div class="content-left specmeter">Bank</div>
                                                                    <div class="content-right">
                                                                        <span
                                                                            class="right-div">{{order.deposit_information.bank_name || "-"}}</span>
                                                                    </div>
                                                                </div>
                                                                <div class="div-content">
                                                                    <div
                                                                        class="content-left specmeter left-flex-summary">
                                                                        Cheque/Transfer no</div>
                                                                    <div class="content-right">
                                                                        <span
                                                                            class="right-div">{{order.deposit_information.cheque_number || "-"}}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="d-flex flex-nowrap">
                                                                <div class="div-content">
                                                                    <div class="content-left specmeter">Deposit Note
                                                                    </div>
                                                                    <div class="content-right">
                                                                        <span
                                                                            class="right-div">{{order.deposit_information.deposit_notes|| "-"}}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="subtotal" style="height: 29px;">
                                                <th scope="row"
                                                    style="border-top:1px dotted #00448b;padding: 0.4rem 1.1rem !important;">
                                                </th>
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;"
                                                    class="text-center"></td>
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;"
                                                    colspan="2">
                                                    <el-button v-if="order.is_deposit_available && !order.is_deposit_used" slot="reference" class="btncustom" @click="showAddDeposit">
                                                        <button class="btn btn-secondary-custom">
                                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/moneybag.svg"
                                                                style="width:17px;">
                                                            <span style="padding-left: 8px;">Use Deposit</span>
                                                        </button>
                                                    </el-button>
                                                    <el-popover placement="top-start" width="300" v-model="showDeposit"
                                                        v-if="order.is_deposit_available && !order.is_deposit_used">
                                                        <div class="d-flex justify-content-between">
                                                            <span class="font-weight-bold text-secondary">Use Deposit
                                                                Balance:{{order.customer_balance}}</span>
                                                            <span @click="showDeposit = false">
                                                                <i class="icon icon-close text-secondary"></i>
                                                            </span>
                                                        </div>
                                                        <p class="text-grey font-600"> Enter Amount to be Used </p>
                                                        <div class="additionalCharges">
                                                            <input type="number" min="0" class="enterCharges"
                                                                v-on:keypress="isNumber($event)"
                                                                v-model.number="deposit_amount_used">
                                                            <button type="submit" class="applyCharges font-600"
                                                                @click="applyDepositcall()">SUBMIT</button>
                                                        </div>
                                                    </el-popover>
                                                    <el-button v-if="order.is_deposit_available && order.is_deposit_used" slot="reference" class="btncustom" @click="showEditDeposit">
                                                        <button class="btn btn-secondary-custom">
                                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/moneybag.svg"
                                                                style="width:17px;">
                                                            <span style="padding-left: 8px;">Edit Deposit</span>
                                                        </button>
                                                    </el-button>
                                                    <el-popover placement="top-start" width="300"
                                                        v-model="showDepositEdit"
                                                        v-if="order.is_deposit_available && order.is_deposit_used">
                                                        <div class="d-flex justify-content-between">
                                                            <span class="font-weight-bold text-secondary">Use Deposit
                                                                Balance:{{order.customer_balance}}</span>
                                                            <span @click="showDepositEdit = false">
                                                                <i class="icon icon-close text-secondary"></i>
                                                            </span>
                                                        </div>
                                                        <p class="text-grey font-600"> Enter Amount to be Used ({{order.currency.symbol}}) </p>
                                                        <div class="additionalCharges">
                                                            <input type="number" min="0" class="enterCharges"
                                                                v-on:keypress="isNumber($event)"
                                                                v-model.number="deposit_amount_used">
                                                            <button type="submit" class="applyCharges font-600"
                                                                @click="applyDepositcall()">SUBMIT</button>
                                                        </div>
                                                    </el-popover>
                                                    <el-popover placement="top-start" width="400" v-model="showLoyalty"
                                                        v-if="order.is_loyalty_applicable">
                                                        <div class="d-flex justify-content-between"
                                                            style="background:#00448b !important;margin: -14px !important;border-top-left-radius: 10px!important;padding:10px !important;border-top-right-radius: 10px !important;">
                                                            <div>
                                                                <div class="font-weight-bold text-white">Total Loyalty
                                                                    points : {{loyaltyDataFromApi.total_loyalty_points}}
                                                                </div>
                                                                <div class="font-weight-bold text-white pt-2">Redeemable
                                                                    Amount: {{loyaltyDataFromApi.max_redeemable_amount}}
                                                                </div>
                                                            </div>
                                                            <span @click="showLoyalty = false">
                                                                <i class="icon icon-close text-white"></i>
                                                            </span>
                                                        </div>
                                                        <el-radio-group v-model="order.loyalty_type" class="mt-7"
                                                            @change="loyaltyTypeChange()">
                                                            <el-radio label="amount">Amount</el-radio>
                                                            <el-radio label="points">Points</el-radio>
                                                        </el-radio-group>
                                                        <div class="mt-2">
                                                            <span class="text-secondary font-weight-bold">Enter Redeem
                                                                <span
                                                                    style="text-transform:capitalize;">{{order.loyalty_type}}</span>
                                                                :</span>
                                                        </div>
                                                        <div class="d-flex applyinput mt-3 mb-5">
                                                            <input type="text" placeholder=""
                                                                v-on:keypress="isNumber($event)"
                                                                v-model.number="loyaltyValue"
                                                                @input="calculateLoyalty()">
                                                            <button class="btn btn-apply"
                                                                :disabled="loyaltyValue.length == 0"
                                                                @click="loyaltyapply">Apply</button>
                                                        </div>
                                                        <div v-if="order.loyalty_type == 'amount'"
                                                            style="background:#1b9e4c !important;padding:10px !important;border-radius:12px !important;">
                                                            <div class="font-weight-bold text-white">Points to be
                                                                Deducted: {{pointsdeducted}}</div>
                                                            <div class="font-weight-bold text-white pt-2">Net Points:
                                                                {{netpoints}}</div>
                                                        </div>
                                                        <div v-if="order.loyalty_type == 'points'"
                                                            style="background:#1b9e4c !important;padding:10px !important;border-radius:12px !important;">
                                                            <div class="font-weight-bold text-white">Amount to be
                                                                Deducted: {{amountdeducted}}</div>
                                                            <div class="font-weight-bold text-white pt-2">Net Amount:
                                                                {{netamount}}</div>
                                                        </div>
                                                        <el-button slot="reference" class="btncustom">
                                                            <button class="btn btn-secondary-custom-loyalty" style="width: auto !important">
                                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/loyaltyimage.svg"
                                                                    style="width:17px;">
                                                                <span style="padding-left: 8px;"
                                                                    v-if="!order.loyalty_applied">Use Loyalty Points</span>
                                                                <span style="padding-left: 8px;"
                                                                    v-if="order.loyalty_applied">Edit Loyalty Points</span>
                                                            </button>
                                                        </el-button>
                                                    </el-popover>
                                                </td>
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;"></td>
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;"
                                                    class="text-center"></td>
                                                <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;"  colspan="7">
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="d-flex flex-wrap justify-content-between mt-5 pt-4 pb-3 qrsection align-items-center"
                                        style="background:#f5f5f5 !important;border-radius:12px;">
                                        <!-- <div class="col-lg-2 col-md-2 col-sm-3">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme//qr-logo-dg.svg"
                                                class="qr-logo1">
                                            <qrcode-vue :value="value" :size="size" level="H" foreground="#00448b">
                                            </qrcode-vue>
                                        </div> -->
                                        <div class="col-lg-10 col-md-9 col-sm-12">
                                            <span class="font-600">Order Notes</span>
                                            <div class="d-flex pr-2 mt-1 note-checkouttextarea align-items-center"
                                                style="background: #fff !important;border:1px solid #cdd2da !important;border-radius:12px !important;">
                                                <textarea rows="6" v-model="order.note"
                                                    class="form-control note-checkout" :maxlength="200"></textarea>
                                                <button class="btn btn-secondary-custom ml-4"
                                                    @click="addOrderNote">Apply</button>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-3 col-sm-12">
                                            <div class="pt-4 text-center" v-if="!editOrder">
                                                <!-- <button class="btn btn-secondary-custom" @click="payBill"> -->
                                                    <div v-if="loggedInUser.show_place_order_in_checkout">
                                                        <button class="btn btn-secondary-custom" @click="paying">
                                                            <img
                                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cashpromotion.svg">
                                                            <span class="pl-2">Pay</span>
                                                        </button>
                                                        <button class="btn btn-secondary-custom-loyalty ml-3 ml-md-0"
                                                            :disabled="loadData" @click="placeOrder">
                                                            <img
                                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/checkout-white.svg">
                                                            <span class="pl-2">Place Order</span>
                                                        </button>
                                                    </div>
                                                    <div v-else>
                                                        <button class="btn btn-secondary-custom" @click="paying">
                                                            <img
                                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cashpromotion.svg">
                                                            <span class="pl-2">Pay</span>
                                                        </button>
                                                        <!-- <button class="btn btn-secondary-custom" @click="fastPaying">
                                                            <img
                                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cashpromotion.svg">
                                                            <span class="pl-2">Fast Pay</span>
                                                        </button> -->
                                                    </div>
                                            </div>
                                            <div class="pt-4" v-else>
                                                <!-- <button class="btn btn-secondary-custom" @click="payBill"> -->
                                                <button class="btn btn-secondary-custom" @click="paying">
                                                    <img
                                                        src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cashpromotion.svg">
                                                    <span class="pl-2">Pay</span>
                                                </button>
                                                <!-- <a class="btn btn-secondary-pay btn-smm" @click="payBill">Pay</a> -->
                                                <!-- <a class="btn btn-danger btn-smm" style="border-radius: 5px!important;"  @click="discardOrderItems">Discard Order</a> -->
                                                <button class="btn btn-secondary-custom ml-3 ml-md-0" @click="updateOrder">
                                                    <!-- <img src="https://cdn.digicollect.com/cdn/posv2/cashpromotion.svg"> -->
                                                    <span class="">Update Order</span>
                                                </button>
                                                <!-- <a class="btn btn-secondary ml-3 mr-3 mt-3" style="border-radius:5px !important;text-transform:Capitalize" @click="updateOrder">
                                                Update Order
                                                </a> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-md-3" style="background-color:#f5f5f5;border-top-right-radius: 7px;border-bottom-right-radius: 7px;">
                                    <div class="widget widget-now" style="border-bottom: 4px solid #eee;">
                                        <div class="widget-header row no-gutters align-items-center justify-content-between pl-4 py-4">
                                        </div>
                                        <div class="widget-content d-flex flex-column align-items-center justify-content-center p-4 pb-6">
                                        <div class="month text-muted">
                                            <img src="https://cdn.digicollect.com/cdn/posv2/checkout-total.svg">
                                        </div>
                                        <div class="day text-muted pt-3">
                                            <span class="total-checkout-img">Grand Total :</span>
                                            <span class="value-checkout-img">
                                            {{order.grand_total}}
                                            </span>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="pt-5 text-center">
                                        <img src="https://cdn.digicollect.com/cdn/posv2/qr-logo-dg.svg" class="qr-logo">
                                        <qrcode-vue :value="value" :size="size" level="H" foreground="#00448b"></qrcode-vue>
                                    </div>
                                        <div class="pt-5 mb-4" style="text-align: center; margin: 0 auto;" v-if="!editOrder">
                                        <a class="btn btn-secondary-pay btn-smm" style="width:42%;" @click="SaveOrder">Save Order</a>

                                        <a class="btn btn-danger btn-smm" style="width:42%;border-radius: 5px!important;"  @click="discardOrder">Discard Order</a>
                                    </div>
                                    <div class="align-items-center justify-content-between row no-gutters pt-3 ml-4">
                                        <div class="text-secondary pt-3 pb-2 font-600">Note</div>
                                        <a class="btn btn-secondary mr-5 pb-0 pr-0 pl-0 pt-0" style="height: 3rem !important;line-height: 2.9rem !important;text-transform:capitalize;" @click="addOrderNote">Apply</a>
                                    </div>
                                    <div class="ml-4 mr-4 mt-2">
                                        <textarea rows="6" v-model="order.note" class="form-control note-checkout" :maxlength="200"></textarea>
                                    </div>
                                    <div class="pt-3 mb-4" style="text-align: center; margin: 0 auto;" v-if="!editOrder">
                                        <a class="btn btn-secondary-pay btn-smm" style="width:42%;" @click="payBill">Pay</a>
                                        <a class="btn btn-yellow-place btn-smm" style="width:42%;color:#303031!important;" @click="placeOrder">Place Order</a>
                                        <a class="btn btn-yellow-place btn-smm mt-3" style="width:85%;color:#fff!important;background-image: linear-gradient(85deg, #1a9347 8%, #1ca04d 53%, #20b256 93%)!important;" @click="fastPaying()">
                                        <span class="pull-left">Fast Pay </span>
                                        <span class="pull-right"><img src="https://cdn.digicollect.com/cdn/posv2/fast-pay.svg" style="width: 25px;"></span>
                                        </a>
                                    </div>
                                    <div class="pt-3 mb-4" style="text-align: center; margin: 0 auto;" v-else>
                                        <a class="btn btn-secondary-pay btn-smm" style="width:42%;" @click="payBill">Pay</a>
                                        <a class="btn btn-danger btn-smm" style="width:42%;border-radius: 5px!important;"  @click="discardOrderItems">Discard Order</a>
                                        <a class="btn btn-secondary ml-3 mr-3 mt-3" style="border-radius:5px !important;width:85%;text-transform:Capitalize" @click="updateOrder">
                                        Update Order
                                        </a>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div v-if="loadData || loadData || loadData" class="full-page-loader">
				<div class="d-flex align-items-center justify-content-center">
						<div class="digi-table-loader">
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
				</div>
			</div> -->
            <div v-if="!isPopUp">
                <add-note modal_name="add-note-checkout" :note="note" @addNote="addItemNote"
                    @closeNote="AddNoteModalHide"></add-note>
            </div>
            <sweet-modal ref="success_modal" icon="success">
                {{ modal_msg }}
            </sweet-modal>
            <sweet-modal ref="warning_modal" icon="warning">
                {{ modal_msg }}
            </sweet-modal>
            <order-type @updateData="updateData" @cancelOrderType="cancelOrderType" modal_name="order_type" :order_type_id="order_type_id" :what_order_type_from_checkout="what_order_type" :order_id="order._id" v-if="show_order_type"></order-type>
            <edit-order-addon :notes="note" @update_note="update_note" @update_addon="update_addon"
                @item_summary="item_summary" @hideEditAddons="hideEditAddons" :item="item_details"
                v-if="editAddon==true" modal_name="edit-addon" :storeorderId="storeorderId"></edit-order-addon>
            <edit-order-modifier :notes="note" @update_note="update_note" @update_addon="update_addon"
                @item_summary="item_summary" @hideEditModifiers="hideEditModifiers" :item="item_details"
                v-if="editModifier==true" modal_name="edit-modifier" :storeorderId="storeorderId"></edit-order-modifier>
            <payment-method v-if="showpayment" modal_name="method_payment_new" :is_credit_card_used="order.is_credit_card_used" :summary="order"
                :grand_total_value="grand_total_value" :grand_total_amount="grand_total_amount" :pay_from_checkout="pay_from_checkout" :fastPay="fast_pay"
                @close="closePaymentMethod"></payment-method>
            <view-course modal_name="change-course" :courseChangeData="courseChangeData"
                @courseUpdated="courseUpdated()" v-if="showChangeCourse" @cancelcourse="cancelcourse()"></view-course>
            <pay-bill :for_approvals="false" v-if="showpaybill" :show_edit="order.discount.show_edit" :approval_message="order.discount.approval_message" :editOrder="editOrder" modal_name="pay-bill" :summary="order"
                @updateCheckout="updateCheckout" :isPopUp="isPopUp" @close="closePayBill" :editTableFromSummary="false"
                :storeorderId="storeorderId"></pay-bill>
            <active-promotions modal_name="promotion-active" :storeorderId="storeorderId"
                @promotionapplied="promotionapplied()"></active-promotions>
            <offer-buy modal_name="buy-offer" :source="'checkout'" :storeorderId="storeorderId"
                @updateapply="promotionapplied()"></offer-buy>
            <offer-get modal_name="get-offer" :source="'checkout'" :storeorderId="storeorderId"
                @checkoutupdate="promotionapplied()"></offer-get>
            <order-type-change-success v-if="order_type_change_success" :delivery_name="''" :delivery_image="''" @hideSuccessPopup="hideSuccessPopup" modal_name="order-type-change-success" :order_type="what_order_type"></order-type-change-success>
            <modifier-modal-edit v-if="edit_modifier_modal" modal_name="edit-modifier-modal" @updateModifierSuccess="updateModifierSuccess" @hideModifierpopup="hideModifierpopup" :order_data="order" :item_id="modifier_item_id" :product="product_data_for_secondary"> </modifier-modal-edit>
        </div>
    </div>
</template>
<script>
    import QrcodeVue from "qrcode.vue";
    import {
        SweetModal
    } from "sweet-modal-vue";
    import AddNote from "./AddNote";
    import PayBill from "./PayBill";
    import PlaceOrder from "./PlaceOrder";
    import CheckoutPopup from "./CheckoutPopup";
    const ViewCourse = () => import('./ViewCourse.vue');
    const OrderTypeChangeSuccess = () => import('./OrderTypeChangeSuccess');
    import Swal from 'sweetalert2'
    import orderServices from "./mixins/orders";
    import printers from "./mixins/printers";
    import cashierServices from "./mixins/cashier";
    import {
        EventBus
    } from "./eventBus/nav-bar-event";
    import customer from './mixins/customer';
    const EditOrderAddon = () => import('./EditOrderAddon');
    const EditOrderModifier = () => import('./EditOrderModifier');
    const PaymentMethod = () => import('./PaymentMethod')
    const ActivePromotions = () => import('./ActivePromotions')
    const OfferBuy = () => import('./OfferApplicableProducts')
    const OfferGet = () => import('./OfferApplicableGet')
    const OrderType = () => import('./OrderTypeData')
    const ModifierModalEdit = () => import('./ModifierModalEdit')
    export default {
        data() {
            return {
                loyaltyDataFromApi: [],
                loyaltyValue: 0,
                pointsdeducted: 0,
                netpoints: 0,
                amountdeducted: 0,
                netamount: 0,
                showLoyalty: false,
                showDeposit: false,
                showDepositEdit: false,
                showpayment: false,
                showpaybill: false,
                showChangeCourse: false,
                apiLock: false,
                note: {
                    courseIndex: null,
                    itemIndex: null,
                    value: "",
                    name: null
                },
                value: "https://example.com",
                size: 120,
                summary: {},
                itemDiscountType: "AMOUNT",
                roundOff: 0,
                order_charges_popover: false,
                order_charges_discount: false,
                order_round_off: false,
                modal_msg: "",
                temp_order_tables: [],
                order_charges_status: false,
                order_discount_status: false,
                responseMessage: "",
                editAddon: false,
                item_details: [],
                grand_total_value: '',
                grand_total_amount: '',
                summary: '',
                items_id: null,
                courseChangeData: '',
                temp_cindex: '',
                other_charge_amount: 0.00,
                show_order_type: false,
                order_types: [],
                order_type_id: null,
                order_type_change_success: false,
                what_order_type: '',
                show_order_type_dropdown: false,
                searchCustomer: false,
                selectedCustomer: null,
                customers: [],
                editModifier: false,
                dd_courses: [],
                dd_items: [],
                fast_pay: false,
                tables: [],
                loadTables: false,
                edit_guest_nos: false,
                tableAvailableStatus: [],
                tableStatus: false,
                loadData: false,
                order_discounted_amount: 0,
                order_discount_loading: true,
                item_discount_loading: true,
                edit_modifier_modal: false,
                modifier_item_id: '',
                product_data_for_secondary: {},
                no_of_pax_error: false,
				pay_from_checkout: false,
                deposit_amount_used: 0,
            };
        },
        mixins: [cashierServices, orderServices, printers, customer],
        props: ["order", "isPopUp", "editOrder", 'storeorderId', 'callinProgress', 'noOfPax', 'for_notification','initLoader'],
        components: {
            AddNote,
            PayBill,
            PlaceOrder,
            CheckoutPopup,
            QrcodeVue,
            SweetModal,
            ViewCourse,
            EditOrderAddon,
            PaymentMethod,
            ActivePromotions,
            OfferBuy,
            OfferGet,
            Swal,
            OrderType,
            OrderTypeChangeSuccess,
            EditOrderModifier,
            ModifierModalEdit
        },
        watch: {
            "order.discount.value"(newValue, oldValue) {
                if (newValue) {
                    this.order.discount.value = newValue
                    if (this.order.discount.value > 100 && this.order.discount.type == "PERCENT") {
                        this.$toasted.global.error(
                            "Cannot Apply Discount, Discount Percent Must be Between 0 and 100%"
                        );
                        this.order.discount.value = 100;
                    } else if (this.order.discount.value.length > 8 && this.order.discount.type == "AMOUNT") {
                        this.order.discount.value = this.order.discount.value.slice(NaN, 8)
                    }
                }
            },
            "order.discount.type"(newValue, oldValue) {
                if (newValue) {
                    this.order.discount.type = newValue
                    if (this.order.discount.type == "PERCENT" && this.order.discount.value > 100) {
                        this.$toasted.global.error(
                            "Cannot Apply Discount, Discount Percent Must be Between 0 and 100%"
                        );
                        this.order.discount.value = 100;
                    } else if (this.order.discount.value.length > 8 && this.order.discount.type == "AMOUNT") {
                        this.order.discount.value = this.order.discount.value.slice(NaN, 8)
                    }
                }
            },
            "order.items"(newValue, oldValue) {
                if (newValue.length == 0) {
                    this.$toasted.global.error("No Items In Cart.");
                    this.$emit("close", false);
                }
            },
        },
        methods: {
            noOfPaxInput(event) {
                if(event.target.value == '') {
                    this.no_of_pax_error = true;
                    this.$toasted.global.error("No Of Guests Must be Greater Than 0")
                }
                else if(event.target.value < 1) {
                    this.no_of_pax_error = false;
                    this.$toasted.global.error("No Of Guests Must be Greater Than 0")
                    this.order.no_of_pax = 1
                } else {
                    this.no_of_pax_error = false;
                    this.order.no_of_pax = event.target.value
                }
            },
            async updateNoOfPax() {
                if(this.no_of_pax_error) {
                    this.$toasted.global.error("No Of Guests Must be Greater Than 0")
                } else {
                    this.loadData = true
                    try {
                        let params = {
                            order_id: this.order._id,
                            order_type: this.order.order_type,
                            tables: this.order.tables,
                            delivery_company: "",
                            no_of_pax: this.order.no_of_pax
                        }
                        let response = await this.updateOrderTypeDetails(params)
                        this.$toasted.global.success("No Of Pax Updated Successfully")
                        setTimeout(() => {
                            this.updateData()
                            this.edit_guest_nos = false
                            this.loadData = false
                        }, 3000);
                    }
                    catch(err) {
                        this.loadData = false
                        this.modal_msg = err;
                        this.$refs.warning_modal.open();
                        setTimeout(() => this.$refs.warning_modal.close(), 2000);
                    }
                }
            },
            showModifierpopup(m_data) {
                this.product_data_for_secondary = m_data;
                this.modifier_item_id = m_data._id
                this.edit_modifier_modal = true
                setTimeout(() => {
                    this.$modal.show('edit-modifier-modal')
                }, 500);
            },
            hideModifierpopup() {
                this.edit_modifier_modal = false
                setTimeout(() => {
                    this.$modal.hide('edit-modifier-modal')
                }, 500);
            },
            updateModifierSuccess() {
                this.edit_modifier_modal = false
                setTimeout(() => {
                    this.$modal.hide('edit-modifier-modal')
                    this.updateCheckout();
                }, 500);
            },
            orderDiscountChange(order) {
                if(order.discount.value > 100) {
                    this.$toasted.global.error("Discount Percent Must not be greater than 100%");
                    order.discount.value = 100;
                }
                if(order.double_discount == true) {
                    this.order_discounted_amount = parseFloat(((order.sub_total.substring(1)) * (order.discount.value / 100)).toFixed(2));
                } else {
                    this.order_discounted_amount = parseFloat(((order.sub_total_without_discount_items.substring(1)) * (order.discount.value / 100)).toFixed(2));
                }
            },
            itemDiscountChange(item) {
                if(item.discount.value > 100) {
                    this.$toasted.global.error("Discount Percent Must not be greater than 100%");
                    item.discount.value = 100;
                }
                item.discount.discounted_amount = parseFloat((parseFloat((parseFloat(item.discount.amount.substring(1,)) + item.total_unformated)) * (item.discount.value / 100)).toFixed(2));
                this.$forceUpdate();
            },
            orderDiscountChangeAmount(order) {
                if(order.double_discount == true) {
                    if(this.order_discounted_amount > order.sub_total.substring(1)) {
                        this.$toasted.global.error("Discount amount cannot be greater than the total amount");
                        this.order_discounted_amount = order.sub_total.substring(1);
                    }
                    order.discount.value = parseFloat(((this.order_discounted_amount/order.sub_total.substring(1)) * 100).toFixed(2));
                } else {
                    if(this.order_discounted_amount > order.sub_total_without_discount_items.substring(1)) {
                        this.$toasted.global.error("Discount amount cannot be greater than the total amount");
                        this.order_discounted_amount = order.sub_total_without_discount_items.substring(1);
                    }
                    order.discount.value = parseFloat(((this.order_discounted_amount/order.sub_total_without_discount_items.substring(1)) * 100).toFixed(2));
                }
            },
            itemDiscountChangeAmount(item) {
                if(item.discount.discounted_amount > parseFloat((parseFloat(item.discount.amount.substring(1,)) + item.total_unformated))) {
                    this.$toasted.global.error("Discount amount cannot be greater than the total amount");
                    item.discount.discounted_amount = parseFloat((parseFloat(item.discount.amount.substring(1,)) + item.total_unformated));
                }
                item.discount.value = parseFloat(((item.discount.discounted_amount/parseFloat((parseFloat(item.discount.amount.substring(1,)) + item.total_unformated))) * 100).toFixed(2));
                this.$forceUpdate();
            },
            changeGuestnos(){
                this.edit_guest_nos = false
                if(this.order.no_of_pax == 0){
                    this.order.no_of_pax = 1
                    this.$forceUpdate();
                }
            },
            selectTable(table_name){
                // console.log(table_name)
                this.order.tables[0] = table_name
                this.$forceUpdate()
            },
            async getTables(){
                this.tables = []
                this.loadTables = true
                // console.log(this.loadTables)
                this.$http.post('/orders/get_all_tables', { orderId: this.storeorderId,search_key:'' }).then(res => {
                    if (res.data.status_id == 1) {
                        this.tables = res.data.message
                        this.loadTables = false
                        // console.log(this.loadTables)
                    }
                    this.tables.forEach((tab, tab_indx) => {
                        if(tab.status == 'Available'){
                            this.tableAvailableStatus.push(tab.status)
                        }
                    })
                    if(this.tableAvailableStatus.length == 0){
                        this.tableStatus = false
                    } else{
                        this.tableStatus = true
                    }
                })
            },
            openOrderDiscount(order) {
                this.order_charges_discount = true;
                this.order_discount_loading = false;
                if(order.double_discount == true) {
                    this.order_discounted_amount = parseFloat(((order.sub_total.substring(1)) * (order.discount.value / 100)).toFixed(2));
                } else {
                    this.order_discounted_amount = parseFloat(((order.sub_total_without_discount_items.substring(1)) * (order.discount.value / 100)).toFixed(2));
                }
                this.$forceUpdate();
            },
            editOrderDiscount() {
                this.order_charges_discount = true;
                this.order_discount_loading = true;
                setTimeout(() => {
                    
                    if(this.order.double_discount == true) {
                        this.order_discounted_amount = parseFloat(((this.order.sub_total.substring(1)) * (this.order.discount.value / 100)).toFixed(2));
                    } else {
                        this.order_discounted_amount = parseFloat(((this.order.sub_total_without_discount_items.substring(1)) * (this.order.discount.value / 100)).toFixed(2));
                    }
                    this.order_discount_loading = false;
                }, 1000);
                this.$forceUpdate();
            },
            openPopover(item, item_index){
                item.popover = true
                this.item_discount_loading = false;
                item.discount.discounted_amount = parseFloat((parseFloat((parseFloat(item.discount.amount.substring(1,)) + item.total_unformated)) * (item.discount.value / 100)).toFixed(2));
                this.$forceUpdate();
                // this.order.courses.forEach((course, course_index) => {
                //     course.items.forEach((it, it_index) => {
                //         if(item._id != it._id){
                //             it.popover = false
                //         }
                //     })
                // })
            },
            editPopover(item, item_index){
                item.popover = true
                this.item_discount_loading = true;
                setTimeout(() => {
                    this.order.courses.forEach((course, c_index)=>{
                        course.items.forEach((item, i_index)=>{
                            item.discount['discounted_amount'] = parseFloat((parseFloat((parseFloat(item.discount.amount.substring(1,)) + item.total_unformated)) * (item.discount.value / 100)).toFixed(2));
                        })
                    })
                    this.item_discount_loading = false;
                }, 1000);
                this.$forceUpdate();
            },
            async isCreditCardUsed(value) {
                this.loadData = true
                try {
                    let params = {
                        order_id: this.storeorderId,
                        is_credit_card_used: this.order.is_credit_card_used
                    };
                    let response = await this.creditCardUsageActive(params);
                    this.$toasted.global.success(response.message)
                    this.loadData = false
                    this.$emit("updateCheckout", true);
                }
                catch (error) {
                    this.loadData = false
                    this.$toasted.global.error(error)
                }
            },
            fetchItems(){
                // console.log(this.order)
                this.dd_courses = []
                this.order.courses.forEach((el,indx) => {
                    this.dd_courses.push(el)
                })
            },
            async getSearchCustomer(search = '') {
                try {
                    let params = {
                        properties: ['first_name', 'last_name'],
                        search: { term: search, fields: ['first_name', 'last_name'] },
                        sort: { by: 'first_name', order: 1 },
                        skip: 0,
                        limit: 5
                    }
                    let res = await this.customerGetCustomerWith(params)
                    this.customers = res.response
                } catch (reason) {
                    // TODO: Handle Error
                }
            },
            async updateOrderCustomer() {
                try {
                    if (this.selectedCustomer._id) {
                        let params = {
                            customer_id: this.selectedCustomer._id,
                            order_id: this.order._id
                        }
                        let res = await this.orderUpdateCustomer(params)
                        this.$toasted.global.success('Successfully Updated Customer')
                        this.searchCustomer = false
                        this.updateData();
                    }
                } catch (reason) {
                    this.$toasted.global.error(reason)
                }
            },
            async withdrawItem(order_id, order_item_id, type) {
                try {
                    let params = {
                        order_id: order_id,
                    };
                    if(type == 'item') {
                        params.order_item_id = order_item_id
                        params.action = 'order_item_discount'
                    } else if(type == 'order') {
                        params.action = 'order_discount'
                    }
                    let response = await this.withdrawItemAndOrder(params);
                    this.modal_msg = response.message;
                    this.$refs.success_modal.open();
                    setTimeout(() => {
                        this.$refs.success_modal.close()
                        this.updateData();
                    }, 2000);
                }
                catch(err) {
                    this.modal_msg = err;
                    this.$refs.warning_modal.open();
                    setTimeout(() => this.$refs.warning_modal.close(), 2000);
                }
            },
            updateData(){
                this.show_order_type = false;
                setTimeout(() => {
                    this.$modal.hide("order_type")
                }, 500);
                this.$emit("updateData")
            },
            hideSuccessPopup() {
                this.order_type_change_success = false;
                setTimeout(() => {
                    this.$modal.hide('order-type-change-success')
                }, 500);
            },
            async changeOrderType(value){
                this.what_order_type = value.order_type
                if(value.order_type != 'Take Away'){
                    this.order_type_id = {
                        value: value,
                        order: this.order
                    }
                    this.show_order_type = true
                    setTimeout(() => {
                        this.$modal.show("order_type")
                    }, 500);
                }
                else{
                    this.loadData = true
                    try {
                        let params = {
                            order_id: this.order._id,
                            order_type: value.order_type,
                            tables: this.order.tables,
                            delivery_company: "",
                            no_of_pax: this.order.no_of_pax
                        }
                        let response = await this.updateOrderTypeDetails(params)
                        this.order_type_change_success = true;
                        setTimeout(() => {
                            this.$modal.show('order-type-change-success')
                        }, 500);
                        setTimeout(() => {
                            this.hideSuccessPopup();
                            this.updateData()
                            this.loadOrderTypes(value.order_type)
                            this.loadData = false
                        }, 3000);
                    }
                    catch(err) {
                        this.loadData = false
                        this.modal_msg = err;
                        this.$refs.warning_modal.open();
                        setTimeout(() => this.$refs.warning_modal.close(), 2000);
                    }
                }
            },
            cancelOrderType(){
                this.show_order_type = false
                setTimeout(() => {
                    this.$modal.hide("order_type")
                }, 500);
            },
            showPopover(parent_index, index) {
                // console.log(parent_index);
                // console.log(index);
                // this.order.items[index].popover = true;
                this.order.courses[parent_index].items[index].popover = true;
            },
            showOtherCharge(index) {
                this.order.other_charges[index].show = true
                if (this.order.other_charges[index].selected_type == 'PERCENTAGE') {
                    this.other_charge_amount = this.order.other_charges[index].tax_rate
                } else {
                    let value = this.order.other_charges[index].value
                    this.other_charge_amount = parseFloat(value.substring(1).replace(',', ''))
                }
            },
            checkNumber(index) {
                this.order.other_charges.forEach((charge, chargeindex) => {
                    if (chargeindex == index) {
                        if (charge.selected_type == 'AMOUNT') {
                            if (this.other_charge_amount.length > 8) {
                                this.other_charge_amount = this.other_charge_amount.slice(NaN, 8)
                            }
                        } else if (charge.selected_type == 'PERCENTAGE') {
                            if (charge.name != 'Tips') {
                                if (this.other_charge_amount > 100) {
                                    this.$toasted.global.error(
                                        "Discount Percent Must be Between 0 and 100%"
                                    );
                                    this.other_charge_amount = 100;
                                }
                            }
                        }
                    }
                })
            },
            changeCourse(item) {
                this.showChangeCourse = true
                let data = {
                    item: item,
                    noCourses: this.order.active_courses
                }
                this.courseChangeData = data
                setTimeout(() => {
                    this.$modal.show('change-course');
                }, 200);
            },
            item_summary() {
                this.$emit("itemSummary", false);
            },
            update_addon() {
                this.editAddon = false;
                this.$emit("updateAddon");
                this.$emit("updateCheckout", true);
            },
            async update_note(note, item) {
                try {
                    let items = item._id;
                    let params = {
                        itemId: item._id,
                        orderId: this.order._id,
                        note: note
                    };
                    let res = await this.orderAddItemNote(params);
                    this.modal_msg = res.message
                    if (res.status_id == 1) {
                        this.$toasted.global.success(this.modal_msg);
                    } else {
                        this.$toasted.global.error(this.modal_msg);
                    }
                    this.$emit("updateCheckout", true);
                    this.$modal.hide("add-note-checkout");
                } catch (reason) {
                    this.$toasted.global.error(reason);
                }
            },
            async fastPaying() {
                this.fast_pay = true
                try {
                    this.$http.get("/cashier/is_cashier_session_active").then(res => {
                        this.modal_msg = res.data.reason;
                        if (res.data.status_id == 1) {
                            this.$store.commit("setCashRegister", res.data.response.cash_register_details);
                            this.fastPayingConfirm()
                        } else {
                            this.$toasted.global.error(res.data.reason);
                        }
                    });
                } catch (reason) {
                    this.$toasted.global.error(reason);
                }
            },
            async paying() {
                this.fast_pay = false
                this.pay_from_checkout = true
                try {
                    this.$http.get("/cashier/is_cashier_session_active").then(res => {
                        this.modal_msg = res.data.reason;
                        if (res.data.status_id == 1) {
                            this.$store.commit("setCashRegister", res.data.response.cash_register_details);
                            this.fastPayingConfirm()
                        } else {
                            this.$toasted.global.error(res.data.reason);
                        }
                    });
                } catch (reason) {
                    this.$toasted.global.error(reason);
                }
            },
            fastPayingConfirm() {
                this.showpayment = true
                this.summary = this.order
                this.loadData = true;
                this.$http
                    .post('orders/grand_total', {
                        order_id: this.order._id
                    })
                    .then(response => {
                        this.grand_total_value = response.data.value.toFixed(2)
                        this.grand_total_amount = response.data.amount
                        this.$modal.show('method_payment_new')
                        this.loadData = false;
                    }).catch((err) => {
                        this.loadData = false;
                    })
            },
            closePaymentMethod() {
                this.showpayment = false
                this.$modal.hide('method_payment_new')
            },
            hideEditAddons() {
                this.editAddon = false;

            },
            hideEditModifiers() {
                this.editModifier = false;
                setTimeout(() => {
                    this.$modal.hide('edit-modifier');
                }, 500);
            },
            editAddons(item) {
                this.item_details = item;
                this.editAddon = true;

                setTimeout(() => {
                    this.$modal.show('edit-addon');
                }, 500);
            },
            editModifiers(item) {
                this.item_details = item;
                this.editModifier = true;
                // console.log(item)
                setTimeout(() => {
                    this.$modal.show('edit-modifier');
                }, 500);
            },
            isNumber: function (evt, index) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    evt.preventDefault();;
                } else {
                    return true;
                }
            },
            closePopOver(item) {
                // item.discount.value = 0;
                item.popover = false;
                this.$forceUpdate();
            },
            closePayBill() {
                this.showpaybill = false
                this.$modal.hide("pay-bill");
            },
            AddNoteModal(cindex, itemIndex) {
                this.note.courseIndex = cindex;
                this.note.itemIndex = itemIndex;
                this.note.value = this.order.courses[cindex].items[itemIndex].note;
                let name = this.order.courses[cindex].items[itemIndex].product_name;
                if (this.order.courses[cindex].items[itemIndex].variant_name) {
                    name += "/" + this.order.courses[cindex].items[itemIndex].variant_name;
                }
                this.note.name = name;
                this.$modal.show("add-note-checkout");
            },
            AddNoteModalHide() {
                this.$modal.hide("add-note-checkout");
            },
            // async payBill() {
            //     this.showpayment = true
            //     try {
            //         let res = await this.isSessionActive();
            //         this.$store.commit(
            //             "setCashRegister",
            //             res.response.cash_register_details
            //         );
            //         this.$modal.show("method_payment_new");
            //     } catch (reason) {
            //         this.modal_msg = reason;
            //         this.$refs.warning_modal.open();
            //         setTimeout(() => this.$refs.warning_modal.close(), 2000);
            //     }
            // },
            back(key) {
                this.$router.replace(`/takeordernew`)
                localStorage.removeItem('order_data_for_checkout');
                this.$emit("updateCheckoutTablePax", {tableName: this.order.tables, no_of_pax: this.order.no_of_pax, order_type: this.order.order_type,})
                // setTimeout(() => {
                    this.$emit("close", false);
                    this.$emit('updateCustomerName', this.order, key)
                // }, 500);
            },
            increaseItemQuantity(cindex, itemIndex) {
                this.temp_cindex = cindex
                if (!this.apiLock) {
                    this.apiLock = true;
                    let item = JSON.parse(
                        JSON.stringify(this.order.courses[cindex].items[itemIndex])
                    );
                    item.quantity++;
                    this.updateOrderItem(item);
                } else {
                    this.$toasted.global.error("Updating Quantity in Progress");
                }
            },
            decreaseItemQuantity(cindex, itemIndex) {
                this.temp_cindex = cindex
                if (!this.apiLock) {
                    this.apiLock = true;
                    let item = JSON.parse(
                        JSON.stringify(this.order.courses[cindex].items[itemIndex])
                    );
                    item.quantity--;
                    this.updateOrderItem(item);
                } else {
                    this.$toasted.global.error("Updating Quantity in Progress");
                }
            },
            increaseModifierQuantity(cindex, itemIndex, index_mod) {
                this.temp_cindex = cindex
                if (!this.apiLock) {
                    this.apiLock = true;
                    this.order.courses[cindex].items[itemIndex].modifiers.for_secondary[index_mod].quantity++;
                    let item = JSON.parse(
                        JSON.stringify(this.order.courses[cindex].items[itemIndex])
                    );
                    // item['mod_quantity'] = this.order.courses[cindex].items[itemIndex].modifiers.for_secondary[index_mod].quantity
                    this.updateOrderItem(item);
                } else {
                    this.$toasted.global.error("Updating Quantity in Progress");
                }
            },
            decreaseModifierQuantity(cindex, itemIndex,index_mod) {
                this.temp_cindex = cindex
                if (!this.apiLock) {
                    this.apiLock = true;
                    this.order.courses[cindex].items[itemIndex].modifiers.for_secondary[index_mod].quantity--;
                    let item = JSON.parse(
                        JSON.stringify(this.order.courses[cindex].items[itemIndex])
                    );
                    // item['mod_quantity'] = this.order.courses[cindex].items[itemIndex].modifiers.for_secondary[index_mod].quantity
                    this.updateOrderItem(item);
                } else {
                    this.$toasted.global.error("Updating Quantity in Progress");
                }
            },
            increaseModifierItemQuantity(cindex, itemIndex, index_mod, index_mod_item) {
                this.temp_cindex = cindex
                if (!this.apiLock) {
                    this.apiLock = true;
                    this.order.courses[cindex].items[itemIndex].modifiers.for_primary[index_mod].modifier_items[index_mod_item].quantity++;
                    let item = JSON.parse(
                        JSON.stringify(this.order.courses[cindex].items[itemIndex])
                    );
                    // item['mod_quantity'] = this.order.courses[cindex].items[itemIndex].modifiers.for_primary[index_mod].quantity
                    this.updateOrderItem(item);
                } else {
                    this.$toasted.global.error("Updating Quantity in Progress");
                }
            },
            decreaseModifierItemQuantity(cindex, itemIndex,index_mod, index_mod_item) {
                this.temp_cindex = cindex
                if (!this.apiLock) {
                    this.apiLock = true;
                    this.order.courses[cindex].items[itemIndex].modifiers.for_primary[index_mod].modifier_items[index_mod_item].quantity--;
                    let item = JSON.parse(
                        JSON.stringify(this.order.courses[cindex].items[itemIndex])
                    );
                    // item['mod_quantity'] = this.order.courses[cindex].items[itemIndex].modifiers.for_primary[index_mod].quantity
                    this.updateOrderItem(item);
                } else {
                    this.$toasted.global.error("Updating Quantity in Progress");
                }
            },
            async updateItem(params) {
                this.loadData = true
                try {
                    let response = await this.addItemToOrderCustomize(params);
                    this.$toasted.global.success(response.message);
                    this.$emit("updateCheckout", true);
                    // if(params.is_customizable == true){
                    //   let response =  await this.addItemToOrderCustomize(params);
                    //   this.$emit("updateCheckout", true);
                    // } else {
                    //   let response = await this.addItemToOrder(params);
                    //   this.$emit("updateCheckout", true);
                    // }
                    this.loadData = false
                } catch (reason) {
                    this.loadData = false
                    this.$toasted.global.error(reason);
                }
                this.apiLock = false;
            },
            updateCheckout() {
                this.$emit("updateCheckout", true);
            },
            updateAPILock() {
                this.apiLock = true;
            },
            updateOrderItem(item) {
                let data = {};
                if (item.quantity >= 0) {
                    if (item.variant_id) data.variant_id = item.variant_id;
                    data.quantity = item.quantity;
                    data.product_id = item.product_id;
                    data.order_id = this.order._id;
                    data.item_id = item.order_item_id
                    data.modifiers = item.modifiers
                    data.is_customizable = item.is_customizable
                    data.course = item.course
                    let updated_for_primary = []
                    // console.log(data.modifiers)
                    if(data.modifiers.hasOwnProperty('for_primary')) {
                        let fo_prm = JSON.parse(JSON.stringify(data.modifiers.for_primary))
                        fo_prm.forEach(element => {
                            element.modifier_items.forEach((mod_item) => {
                                updated_for_primary.push(mod_item);
                            })
                        });
                        data.modifiers.for_primary = updated_for_primary
                    } 
                    // else {
                    //     data.modifiers.for_primary = []
                    // }
                    // if (item.vendorId) data.vendorId = item.vendorId;
                    // console.log(data)
                    this.updateItem(data);
                } else {
                    this.$toasted.global.error("Invalid Quantity");
                }
            },
            removeItem(item) {
                // let category_data = []
                // let data = JSON.parse(sessionStorage.getItem("categories_printer"))
                // if(data){
                //     data.forEach(y => {
                //         y.printers.forEach(x => {
                //             if(y._id == x.category_id){
                //                 category_data.push({category_id: x.category_id,printer_id:x.printer_id})
                //             }
                //         })
                //     })
                // }
                let data_payload = {
                    productId: item.product_id,
                    variantId: item.variant_id,
                    orderId: this.order._id,
                    itemId: item._id,
                    // printer_details: category_data
                };
                this.confirmRemoveItem(data_payload);
            },
            async confirmRemoveItem(params) {
                this.loadData = true
                try {
                    let res = await this.orderRemoveItem(params);
                    if (res.status_id == 1) {
                        if (res.message) {
                            this.$toasted.global.success(res.message);
                            this.back()
                        } else {
                            this.$toasted.global.success("Item Deleted Successfully");
                        }
                    }
                    this.$emit("updateCheckout", true);
                    this.loadData = false
                } catch (reason) {
                    this.$toasted.global.error(reason);
                }
            },
            async addItemNote(note) {
                try {
                    let item = this.order.courses[note.courseIndex].items[note.itemIndex];
                    let params = {
                        itemId: item._id,
                        orderId: this.order._id,
                        note: note.value
                    };
                    let res = await this.orderAddItemNote(params);
                    this.modal_msg = res.message
                    if (res.status_id == 1) {
                        this.$toasted.global.success(this.modal_msg);
                    } else {
                        this.$toasted.global.error(this.modal_msg);
                    }
                    this.$emit("updateCheckout", true);
                    this.$modal.hide("add-note-checkout");
                } catch (reason) {
                    this.$toasted.global.error(reason);
                }
            },
            updateAdditionalCharges() {
                this.order_charges_status = true
                let data = {
                    orderId: this.order._id,
                    type: this.order.additional_charges.type,
                    value: parseFloat(this.order.additional_charges.value)
                };
                if (this.editOrder) {
                    data.update = true;
                }
                this.additionalCharges(data);
            },
            async updateOtherCharge(index) {
                this.loadData = true
                try {
                    let params = {
                        order_id: this.order._id,
                        name: this.order.other_charges[index].name,
                        value: parseFloat(this.other_charge_amount),
                        type: this.order.other_charges[index].selected_type,
                    };
                    if (this.editOrder) {
                        params.update = true
                    }
                    let res = await this.orderOtherCharges(params);
                    this.loadData = false
                    this.$toasted.global.success(res.message);
                    this.closeOtherCharges(index)

                } catch (reason) {
                    this.loadData = false
                    this.$toasted.global.error(reason);
                }
            },
            closeOtherCharges(index) {
                this.$emit('updateCheckout', true)
                this.order.other_charges[index].show = false
            },

            async additionalCharges(params) {
                try {
                    let res = await this.orderAdditionalCharges(params);
                    this.$emit("updateCheckout", true);
                    this.$toasted.global.success(
                        "Successfully Applied Additional Charges"
                    );
                    this.order_charges_popover = false;
                } catch (reason) {
                    this.$toasted.global.error(reason);
                }
            },
            async addOrderNote() {
                try {
                    let params = {
                        orderId: this.order._id,
                        note: this.order.note
                    };
                    let res = await this.orderNote(params);
                    this.$toasted.global.success("Successfully Updated Order Note");
                } catch (reason) {
                    this.$toasted.global.error(reason);
                }
            },
            updateDiscount() {
                this.order_discount_status = true
                let data = {
                    orderId: this.order._id,
                    // type: this.order.discount.type,
                    type: 'PERCENT',
                    value: parseFloat(this.order.discount.value),
                    reason: this.order.discount.reason
                };
                if (this.editOrder) {
                    data.update = true;
                }
                this.discount(data);
            },
            async discount(params) {
                this.loadData = true
                try {
                    await this.orderDiscount(params);
                    this.$emit("updateCheckout", true);
                    this.$toasted.global.success(
                        "Successfully Applied Order Discount"
                    );
                    this.loadData = false
                    this.order_charges_discount = false;
                } catch (reason) {
                    this.loadData = false
                    // console.log(reason)
                    this.modal_msg = reason;
                    this.$refs.warning_modal.open();
                    setTimeout(() => this.$refs.warning_modal.close(), 2000);
                }
            },
            movePage(ticket_data) {
                this.$toasted.global.success(this.responseMessage);
                // if(this.loggedInUser.print_receipt_by_default) {
                    this.printTicket(ticket_data)
                    this.$emit("close", true);
                    this.loadData = false
                // } else {
                //     Swal({
                //         title: 'Print Tickets',
                //         text: 'Do You Want to Print Ticket ?',
                //         type: 'warning',
                //         showCancelButton: true,
                //         confirmButtonColor: '#00448b',
                //         cancelButtonColor: '#d33',
                //         confirmButtonText: 'Yes',
                //         cancelButtonText: 'Continue Without Printing',
                //         allowOutsideClick: false
                //     }).then(result => {
                //         if (result.value) {
                //             this.printTicket(ticket_data)
                //             this.$emit("close", true);
                //             this.loadData = false
                //         } else {
                //             this.$emit("close", true);
                //             this.loadData = false
                //         }
                //     })
                // }
            },
            async placeOrder() {
                try {
                    this.loadData = true
                    // let category_data = []
                    // let data = JSON.parse(sessionStorage.getItem("categories_printer"))
                    // if(data){
                    //     data.forEach(y => {
                    //         y.printers.forEach(x => {
                    //             if(y._id == x.category_id){
                    //                 category_data.push({category_id: x.category_id,printer_id:x.printer_id})
                    //             }
                    //         })
                    //     })
                    // }
                    let params = {
                        order_id: this.order._id,
                        note: this.order.note,
                        // printer_details: category_data
                    }
                    await this.$http.get("/cashier/is_cashier_session_active").then(res => {
                    this.modal_msg = res.data.reason;
                    if (res.data.status_id == 1) {
                        this.$store.commit("setCashRegister", res.data.response.cash_register_details);
                        this.orderMobilePlace(params).then(res => {
                            this.responseMessage = res.response.message
                            this.$toasted.global.success(this.responseMessage);
                            this.$router.push({name: 'ManageOrder'})
                            // let ticket_data
                            // if(data){
                                // data.forEach(y => {
                                    // y.printers.forEach(x => {
                                        if(res.response.ticket_details.length > 0){
                                            if(res.response.ticket_details.length == 1){
                                                res.response.ticket_details.forEach(z => {
                                                    // if(x.category_id == z.category_id){
                                                        this.store_ticket_data = z
                                                        this.movePage(z);
                                                    // }
                                                })
                                            }
                                            else{
                                                // if(this.loggedInUser.print_receipt_by_default) {
                                                    res.response.ticket_details.forEach(z => {
                                                        this.store_ticket_data = z
                                                        this.printTicket(z)
                                                    })
                                                // } else {
                                                //     Swal({
                                                //         title: 'Print Tickets',
                                                //         text: 'Do You Want to Print Tickets ?',
                                                //         type: 'warning',
                                                //         showCancelButton: true,
                                                //         confirmButtonColor: '#00448b',
                                                //         cancelButtonColor: '#d33',
                                                //         confirmButtonText: 'Yes',
                                                //         cancelButtonText: 'Continue Without Printing',
                                                //         allowOutsideClick: false
                                                //     }).then(result => {
                                                //         if (result.value) {
                                                //             res.response.ticket_details.forEach(z => {
                                                //                 this.store_ticket_data = z
                                                //                 this.printTicket(z)
                                                //             })
                                                //         } else {
                                                //             this.$emit("close", true);
                                                //             this.loadData = false
                                                //         }
                                                //     })
                                                // }
                                            }
                                        }
                                        
                                    // })
                                // })
                            // }
                            // console.log(ticket_data)
                            this.$nextTick(() => {
                                document.getElementById('bigcontainer_scroll').scrollTop = 0
                                window.scrollTo(0,0);
                            })
                            this.loadData = false;
                        }).catch((err) => {
                            this.$toasted.global.error(err);
                            this.loadData = false;
                        });
                    } else {
                        this.$toasted.global.error(res.data.reason);
                            this.loadData = false;
                    }
                    });
                } catch (reason) {
                    this.$toasted.global.error(reason);
                    this.loadData = false;
                }
            },
            discardOrder() {
                this.$swal({
                    title: "Are you sure?",
                    text: "Are you sure to Discard the Order ?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#00448b",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes"
                }).then((result) => {
                    if (result.value) {
                        this.discardOrderConfirm()
                    }
                })
            },
            async discardOrderConfirm() {
                try {
                    let params = {
                        orderId: this.order._id
                    };
                    let res = await this.orderDiscard(params);
                    this.$toasted.global.success(res.message);
                    this.$emit("discard", true);
                } catch (reason) {
                    this.$toasted.global.error(reason);
                }
            },
            async updateOrder() {
                try {
                    this.loadData = true;
                    // let category_data = []
                    // let data = JSON.parse(sessionStorage.getItem("categories_printer"))
                    // if(data){
                    //     data.forEach(y => {
                    //         y.printers.forEach(x => {
                    //             if (y._id == x.category_id) {
                    //                 category_data.push({
                    //                     category_id: x.category_id,
                    //                     printer_id: x.printer_id
                    //                 })
                    //             }
                    //         })
                    //     })
                    // }
                    let params = {
                        orderId: this.order._id,
                        note: this.order.note,
                        // printer_details: category_data
                    }
                    await this.$http.get("/cashier/is_cashier_session_active").then(res => {
                        this.modal_msg = res.data.reason;
                        if (res.data.status_id == 1) {
                            this.$store.commit("setCashRegister", res.data.response.cash_register_details);
                            this.orderUpdateOrder(params).then((res) => {
                                //this.responseMessage = res.message
                                this.$toasted.global.success(res.message)
                                this.$router.push({name: 'ManageOrder'})
                                // let ticket_data
                                // if(data){
                                    // data.forEach(y => {
                                        // y.printers.forEach(x => {
                                            if(res.ticket_details.length > 0) {
                                                if(res.ticket_details.length == 1){
                                                    res.ticket_details.forEach(z => {
                                                        // if(x.category_id == z.category_id){
                                                            this.store_ticket_data = z
                                                            this.movePage(z);
                                                        // }
                                                    })
                                                }
                                                else{
                                                    // if(this.loggedInUser.print_receipt_by_default) {
                                                        res.ticket_details.forEach(z => {
                                                            this.store_ticket_data = z
                                                            this.printTicket(z)
                                                        })
                                                    // } else {
                                                    //     Swal({
                                                    //         title: 'Print Tickets',
                                                    //         text: 'Do You Want to Print Tickets ?',
                                                    //         type: 'warning',
                                                    //         showCancelButton: true,
                                                    //         confirmButtonColor: '#00448b',
                                                    //         cancelButtonColor: '#d33',
                                                    //         confirmButtonText: 'Yes',
                                                    //         cancelButtonText: 'Continue Without Printing',
                                                    //         allowOutsideClick: false
                                                    //     }).then(result => {
                                                    //         if (result.value) {
                                                    //             res.ticket_details.forEach(z => {
                                                    //                 this.store_ticket_data = z
                                                    //                 this.printTicket(z)
                                                    //             })
                                                    //         } else {
                                                    //             this.$emit("close", true);
                                                    //             this.loadData = false
                                                    //         }
                                                    //     })
                                                    // }
                                                }
                                            }
                                        // })
                                    // })
                                // }
                                // console.log(ticket_data)
                                this.loadData = false;
                            }).catch((err) => {
                                this.$toasted.global.error(err);
                                this.loadData = false;
                            });
                        }
                    })
                } catch (reason) {
                    this.$toasted.global.error(reason);
                    this.loadData = false;
                }
            },
            async discardOrderItems() {
                try {
                    let params = {
                        orderId: this.order._id
                    };
                    let res = await this.orderDiscardItems(params);
                    this.$toasted.global.success(res.message);
                    this.$emit("close", true);
                } catch (reason) {
                    this.$toasted.global.error(reason);
                }
            },
            async applyItemDiscount(item_id, order_item_id, item) {
                if (!item.discount.value) {
                    this.$toasted.global.error("Please Enter Value");
                    return;
                }
                this.loadData = true
                try {
                    let params = {
                        order_item_id: order_item_id,
                        update: this.editOrder,
                        discount: {
                            // type: item.discount.type,
                            type: 'PERCENT',
                            value: parseFloat(item.discount.value),
                            amount: 0
                        },
                        reason: item.discount.reason
                    };
                    let res = await this.orderItemDiscount(params);
                    this.$emit("updateCheckout", true);
                    this.loadData = false
                    this.$toasted.global.success(
                        'Successfully Updated Order'
                    );
                    this.closePopOver(item);
                } catch (reason) {
                    // this.$toasted.global.error(reason);
                    this.loadData = false
                    this.modal_msg = reason
                    this.$refs.warning_modal.open()
                    setTimeout(() => {
                        this.$refs.warning_modal.close()
                    }, 2000);
                }
            },
            async applyRoundOff() {
                try {
                    let params = {
                        roundOff: parseFloat(this.roundOff),
                        orderId: this.order._id,
                        isDelivered: false,
                        update: this.editOrder
                    };
                    let res = await this.orderRoundOff(params);
                    this.$emit("updateCheckout", true);
                    this.order_round_off = false;
                    this.$toasted.global.success("Successfully Updated Order");
                } catch (reason) {
                    this.$toasted.global.error(reason);
                }
            },
            SaveOrder() {
                this.$router.push({
                    name: "ManageOrder"
                });
            },
            closeDiscount(order) {
                if (order.discount_amount.substring(1) == 0) {
                    order.discount.value = 0
                }
                this.order_charges_discount = false
            },
            closeAdditionalCharges() {
                if (!this.order_charges_status) {
                    this.order.additional_charges.value = 0
                }
                this.order_charges_popover = false
            },
            async sendAllTogetherOrder(sendallTogether) {
                this.loadData = true
                try {
                    let params = {
                        order_id: this.storeorderId,
                        target: "ORDER",
                        action: sendallTogether
                    }
                    let res = await this.sendAllTogetherApiCall(params)
                    this.loadData = false
                    this.$emit("updateCheckout", true);
                    this.$toasted.global.success(res.message);
                } catch (reason) {
                    this.loadData = false
                    this.$toasted.global.error(reason);
                }
            },
            async sendTogetherCourse(sendtogethercourse, courseno) {
                this.loadData = true
                try {
                    let params = {
                        order_id: this.storeorderId,
                        target: "COURSE",
                        action: sendtogethercourse,
                        course: courseno
                    }
                    let res = await this.sendAllTogetherApiCall(params)
                    this.$emit("updateCheckout", true);
                    this.loadData = false
                    this.$toasted.global.success(res.message);
                } catch (reason) {
                    this.$toasted.global.error(reason);
                }
            },
            async orderFire(fireOrder) {
                this.loadData = true
                try {
                    let params = {
                        order_id: this.storeorderId,
                        target: "ORDER",
                        action: fireOrder
                    }
                    let res = await this.fireApiCall(params)
                    this.$emit("updateCheckout", true);
                    this.loadData = false
                    this.$toasted.global.success(res.message);
                } catch (reason) {
                    this.loadData = false
                    this.$toasted.global.error(reason);
                }
            },
            async toggleHold(hold_order){
                this.loadData = true
                try{
                    let params = {
                        hold_order: hold_order,
                        order_id: this.storeorderId,
                    }
                    let res = await this.HoldApiCall(params)
                    this.$emit("updateCheckout", true);
                    // Eventbus.$emit('updateHoldStatus', hold_order)
                    this.loadData = false
                    this.$toasted.global.success(res.message);
                } catch(reason) {
                    this.loadData = false
                    this.$toasted.global.error(reason);
                }
            },
            async courseFire(fireCourse, courseno) {
                this.loadData = true
                try {
                    let params = {
                        order_id: this.storeorderId,
                        target: "COURSE",
                        action: fireCourse,
                        course: courseno
                    }
                    let res = await this.fireApiCall(params)
                    this.$emit("updateCheckout", true);
                    this.loadData = false
                    this.$toasted.global.success(res.message);
                } catch (reason) {
                    this.loadData = false
                    this.$toasted.global.error(reason);
                }
            },
            async itemFire(item) {
                this.loadData = true
                try {
                    let params = {
                        order_id: this.storeorderId,
                        target: "ITEM",
                        action: item.fire_selected,
                        course: item.course,
                        item_id: item._id
                    }
                    let res = await this.fireApiCall(params)
                    this.$emit("updateCheckout", true);
                    this.loadData = false
                    this.$toasted.global.success(res.message);
                } catch (reason) {
                    this.loadData = false
                    this.$toasted.global.error(reason);
                }
            },
            courseUpdated() {
                this.showChangeCourse = false

                this.$modal.hide('change-course');
                this.$emit("updateCheckout", true);
            },
            cancelcourse() {
                this.showChangeCourse = false
            },
            promotionapplied() {
                this.$emit("updateCheckout", true);
            },
            showpromotions() {
                let params = {
                    customer_id: this.order.customer_id,
                    order_id: this.order._id
                }
                if (this.order.hasOwnProperty('goto')) {
                    if (this.order.goto == 'select_buy_products') {
                        let params = {
                            order_id: this.storeorderId
                        }
                        EventBus.$emit('detailproduct', params)
                        this.$modal.show('buy-offer')
                    } else if (this.order.goto == 'select_get_products') {
                        EventBus.$emit('detailproductget')
                        this.$modal.show('get-offer')
                    } else if (this.order.goto == 'couponsListing') {
                        EventBus.$emit('showactive', params)
                        this.$modal.show('promotion-active')
                    }
                } else {
                    EventBus.$emit('showactive', params)
                    this.$modal.show('promotion-active')
                }

            },
            async removepromotion() {
                try {
                    let params = {
                        order_id: this.storeorderId
                    }
                    let res = await this.$http.post('/promotions/remove_promotion', params)
                    if (res.data.status_id == 1) {
                        this.promotionapplied()
                        this.$toasted.global.success(res.data.message);
                    } else {
                        this.$toasted.global.error(res.reason);
                    }
                } catch (reason) {

                }
            },
            async getloyaltyDetails() {
                try {
                    let params = {
                        order_id: this.storeorderId
                    }
                    let res = await this.loyaltyDetailsApi(params)
                    this.loyaltyDataFromApi = res
                } catch (reason) {
                    // this.$toasted.global.error(reason);
                }
            },
            calculateLoyalty() {
                if (this.order.loyalty_type == 'amount') {
                    this.pointsdeducted = (this.loyaltyValue / this.loyaltyDataFromApi.redemption_rate).toFixed(2)
                    this.netpoints = (this.loyaltyDataFromApi.total_loyalty_points - this.pointsdeducted).toFixed(2)
                } else if (this.order.loyalty_type == 'points') {
                    this.amountdeducted = (this.loyaltyValue * this.loyaltyDataFromApi.redemption_rate).toFixed(2)
                    this.netamount = (this.loyaltyDataFromApi.max_redeemable_amount - this.amountdeducted).toFixed(2)
                }
            },
            loyaltyTypeChange() {
                this.loyaltyValue = 0
                this.pointsdeducted = 0
                this.netpoints = 0
                this.amountdeducted = 0
                this.netamount = 0
            },
            async loyaltyapply() {
                if (this.loyaltyValue > 0) {
                    try {
                        let params = {
                            order_id: this.storeorderId,
                            value: this.loyaltyValue,
                            redeem_type: this.order.loyalty_type
                        }
                        let res = await this.applyLoyaltyApi(params)
                        this.showLoyalty = false
                        this.loyaltyValue = res.loyalty_amount
                        this.order.loyalty_type = res.loyalty_type
                        this.$toasted.global.success(res.message);
                        this.$emit("updateCheckout", true);
                    } catch (reason) {
                        this.$toasted.global.error(reason);
                    }
                } else {
                    if (this.order.loyalty_type == 'amount') {
                        this.$toasted.global.error('Redeem Amount should be greater than 0');
                    } else {
                        this.$toasted.global.error('Redeem Points should be greater than 0');
                    }
                }

            },
            async applyDepositcall() {
                this.loadData = true
                try {
                    if (this.deposit_amount_used > this.order.grand_total_unformatted) {
                        this.$toasted.global.error('Deposit Amount should be less than or equal to Grand Total')
                    } else {
                        let params = {
                            order_id: this.storeorderId,
                            amount: this.deposit_amount_used
                        }
                        let res = await this.applyDeposit(params)
                        this.showDeposit = false
                        this.showDepositEdit = false
                        this.$toasted.global.success(res.message);
                        this.loadData = false
                        this.$emit("updateCheckout", true);
                    }
                } catch (reason) {
                    this.loadData = false
                    this.$toasted.global.error(reason);
                }
            },
            async removeDepositUsed() {
                this.loadData = true
                try {
                    let params = {
                        order_id: this.storeorderId,
                        amount: 0
                    }
                    let res = await this.applyDeposit(params)
                    this.$toasted.global.success(res.message);
                    this.loadData = false
                    this.$emit("updateCheckout", true);
                } catch (reason) {

                }
            },
            async loyaltyremove() {
                try {
                    let params = {
                        order_id: this.storeorderId
                    }
                    let res = await this.removeLoyaltyApi(params)
                    this.$toasted.global.success(res.message);
                    this.$emit("updateCheckout", true);
                } catch (reason) {
                    this.$toasted.global.error(reason);
                }
            },
            async loadOrderTypes(order_type){
                this.show_order_type_dropdown = true;
                let params = {
                    search_key: "",
                    sort_key: "order_type",
                    sort_value: 1,
                    required_id: true,
                    existing_type: order_type
                }
                let response = await this.orderTypeDropdowns(params)
                this.order_types = response.response.order_types
                this.show_order_type_dropdown = false;
            },
            showAddDeposit() {
                this.showDeposit = true
                this.deposit_amount_used = parseInt(this.order.customer_deposit_used.substring(1)) || 0
                this.$forceUpdate();
            },
            showEditDeposit() {
                this.showDepositEdit = true
                this.deposit_amount_used = parseInt(this.order.customer_deposit_used.substring(1)) || 0
                this.$forceUpdate();
            },
        },
        mounted() {
            this.getloyaltyDetails()
            EventBus.$on("PaymentComplete", data => {
                // this.movePage();
                this.$router.push({name: 'ManageOrder'})
            });
            if (this.order && this.order._id) {
                this.value = this.order._id;
            }
            this.order.no_of_pax = this.noOfPax;
            // this.loadOrderTypes()
            this.getSearchCustomer('')
            // this.order_discount_loading = true;
            // this.item_discount_loading = true;
            // setTimeout(() => {
            //     console.log(this.order)
            //     this.order.courses.forEach((course, c_index)=>{
            //         course.items.forEach((item, i_index)=>{
            //             item.discount['discounted_amount'] = parseFloat((item.product_price_exclusive_unformatted * (item.discount.value / 100)).toFixed(2));
            //         })
            //     })
            //     if(this.order.double_discount == true) {
            //         this.order_discounted_amount = parseFloat(((this.order.sub_total.substring(1)) * (this.order.discount.value / 100)).toFixed(2));
            //     } else {
            //         this.order_discounted_amount = parseFloat(((this.order.sub_total_without_discount_items.substring(1)) * (this.order.discount.value / 100)).toFixed(2));
            //     }
            //     this.order_discount_loading = false;
            //     this.item_discount_loading = false;
            // }, 3000);
            EventBus.$on('emit_for_discount', ()=> {
                this.order_discount_loading = true;
                this.item_discount_loading = true;
                setTimeout(() => {
                    // console.log('emit_for_discount')
                    // console.log(this.order)
                    this.order.courses.forEach((course, c_index)=>{
                        course.items.forEach((item, i_index)=>{
                            item.discount['discounted_amount'] = parseFloat((parseFloat((parseFloat(item.discount.amount.substring(1,)) + item.total_unformated)) * (item.discount.value / 100)).toFixed(2));
                        })
                    })
                    if(this.order.double_discount == true) {
                        this.order_discounted_amount = parseFloat(((this.order.sub_total.substring(1)) * (this.order.discount.value / 100)).toFixed(2));
                    } else {
                        this.order_discounted_amount = parseFloat(((this.order.sub_total_without_discount_items.substring(1)) * (this.order.discount.value / 100)).toFixed(2));
                    }
                    this.order_discount_loading = false;
                    this.item_discount_loading = false;
                }, 3000);
            })
            EventBus.$on('emit_for_deposit_amount', ()=> {
                setTimeout(() => {
                    this.deposit_amount_used = parseInt(this.order.customer_deposit_used.substring(1)) || 0
                }, 3000);
            })

        },
        destroyed() {
            EventBus.$off("PaymentComplete");
        },
        created() {
            const dict = {
                custom: {
                    no_of_pax: {
                        min: () => "No of Guests should be greater than 0",
                    },
                }
            } 
            this.$validator.localize("en", dict);       
        },
        computed: {
            loggedInUser() {
                return this.$store.getters.loggedInUser;
            },
            addon_names() {
                let addon = []
                this.order.items.forEach(item => {
                    addon.push(...item.addons);
                });
                return addon
            }
        }
    };
</script>
<style scoped>
    .b-r-7 {
        border-radius: 7px;
    }

    .qr-logo1 {
        position: absolute;
        margin-top: 30px !important;
        margin-left: 30px !important;
    }

    .qrsection {
        padding-left: 15px;
        padding-right: 15px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .courses_dotted {
        border-bottom: 1px dotted #00448b;
        border-top: 1px dotted #00448b;
    }

    .el-button.el-button-edit {
        border: 0px solid #dcdfe6 !important;
        padding: 0px !important;
        background-color: transparent;
    }

    .el-button.btncustom {
        background-color: transparent;
    }

    .right-cust-name {
        float: right;
        text-align: right;
        vertical-align: middle;
        line-height: 54px;
    }

    .chekout-customer {
        letter-spacing: 0.3px;
        text-align: left;
        color: #4e5764;
    }

    .checkoutdiv .table td {
        padding: 0px 12px !important;
    }

    .checkoutdiv .el-switch.is-checked .el-switch__core {
        border-color: #1a9347 !important;
        background-color: #1a9347 !important;
    }

    .checkoutdiv .el-switch__core {
        border: 1px solid transparent !important;
    }

    .checkout-customer-name {
        line-height: 1.21;
        letter-spacing: 0.3px;
        text-align: left;
        color: #00448b;
        font-weight: 600;
    }

    .btn-yellow-checkout {
        border-radius: 23px;
        background-image: linear-gradient(to bottom, #ffb333, #e29005);
        color: #303031 !important;
        text-transform: capitalize;
        height: 2.9rem !important;
        line-height: 2.9rem !important;
    }

    .btn-yellow-checkout:focus {
        border-radius: 23px;
        background-image: linear-gradient(to bottom, #ffb333, #e29005);
        color: #303031 !important;
        text-transform: capitalize;
        height: 2.9rem !important;
        line-height: 2.9rem !important;
    }

    .btn-yellow-checkout i {
        color: #303031;
        font-size: 16px;
        padding-left: 20px;
    }

    .btn-yellow-checkout.btn:not(:disabled):not(.disabled):active:focus {
        background-image: linear-gradient(to bottom, #ffb333, #e29005) !important;
    }

    .qty-input-checkout {
        width: 30% !important;
        text-align: center !important;
        color: #00448b;
        font-weight: 600;
        background-color: transparent;
        border: solid 0.5px #bcb8b8;
        border-radius: 5px;
    }

    .order-summary-checkout {
        padding-top: 15px;
        color: #303031;
        font-weight: 600;
        font-size: 15px;
        padding-left: 10px;
    }

    .form-control.note-checkout {
        height: 100px !important;
        background-color: #fff;
        padding-left: 6px !important;
        border-bottom-left-radius: 12px !important;
        border-top-left-radius: 12px !important;
    }

    .note-checkouttextarea .form-control {
        border-bottom: none !important;
        border-right: 1px solid #cdd2da !important;
    }

    .btn-yellow-place {
        border-radius: 5px !important;
        background-image: -webkit-gradient(linear,
                left top,
                left bottom,
                from(#ffb333),
                to(#e29005));
        background-image: linear-gradient(to bottom, #ffb333, #e29005);
        color: #fff !important;
        text-transform: capitalize;
    }

    .btn-secondary-pay {
        color: #fff !important;
        background-color: #00448b;
        border-color: #00448b;
        border-radius: 5px !important;
        box-shadow: none !important;
        font-weight: 600;
    }

    .defaultBlue {
        color: #00448b !important;
    }

    .additionalCharges {
        width: 100%;
        position: relative;
        margin-top: 10px;
    }

    .enterCharges {
        float: left;
        border: solid 1px #cdd2da !important;
        -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
        padding: 5px;
        width: 75%;
        height: 27px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        outline: none;
    }

    .applyCharges {
        position: absolute;
        width: 65px;
        outline: 0 !important;
        height: 27px;
        background: #f5a623;
        text-align: center;
        color: #fff;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        cursor: pointer;
        font-size: 12px !important;
    }

    .total-checkout-img {
        font-weight: bold !important;
        letter-spacing: 0.4px !important;
        color: #00448b !important;
    }

    .value-checkout-img {
        font-weight: bold !important;
        letter-spacing: 0.4px !important;
        color: #4e5764 !important;
    }

    .discard-text {
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.21;
        letter-spacing: 0.3px;
        text-align: center;
        color: #94bef0;
        word-break: break-all;
        cursor: pointer;
    }

    tr.subtotal {
        height: 34px !important;
    }

    .subtotal td {
        padding: 1px 13px !important;
        font-weight: 600;
        color: #4e5764;
    }

    .fire {
        color: #1a9347;
        border: 1px solid #1a9347;
        padding: 2px 6px;
        border-radius: 100%;
        cursor: pointer;
        font-weight: bold;
    }

    .firecourse {
        color: #fff;
        background: #1a9347;
        padding: 2px 6px;
        border-radius: 100%;
        cursor: pointer;
        font-weight: bold;
    }

    .textTransform {
        text-transform: none !important;
    }

    .courses {
        float: left;
        display: block;
        color: #00448b;
        border: 1px solid #00448b;
        border-radius: 50% !important;
        padding: 3px 5px;
        margin-left: 10px;
        font-weight: bold;
        font-size: 15px;
        margin-top: 4px;
        cursor: pointer;
    }

    .hideCourse {
        background: transparent !important;
    }

    .hideCourse i {
        color: #00448b !important;
        cursor: pointer !important;
    }

    .borderTop {
        border-top: 1px dotted #00448b !important;
    }

    .checkout-manage-info {
        font-size: 14px;
        color: rgb(48, 48, 49);
        font-weight: 600;
        padding: 2px 12px 2px 12px;
        text-align: center;
        border: 2px solid rgb(13, 132, 242);
        border-radius: 18px;
        background-color: rgb(255, 255, 255);
        top: 50px;
        left: 0px;
        right: 0px;
        margin-left: auto;
        margin-right: auto;
        width: 130px;
        position: absolute;
        z-index: 9;
    }

    .btn-secondary-custom {
        margin-top: 10px;
        background-color: #00448b;
        color: #fff;
        text-transform: none !important;
        font-weight: 600;
        border-radius: 6px;
        padding: 0 20px;
        width: 160px;
    }

    .btn-secondary-custom-loyalty {
        margin-top: 10px;
        background-color: #f2880d;
        color: #303031;
        text-transform: none !important;
        font-weight: 600;
        border-radius: 6px;
        padding: 0 20px;
        width: 160px;
    }

    .btn-secondary-custom-loyalty span:hover {
        color: #303031 !important;
    }

    .checkout-screen {
        padding: 13px 13px 13px 23px !important;
    }

    @media(max-width:980px) {
        .qrsection {
            padding-left: 0px !important;
            padding-right: 0px !important;
            margin-left: 0px !important;
            margin-right: 0px !important;
        }

        .btn-secondary-custom {
            width: auto;
        }

        .btn-secondary-custom-loyalty {
            width: auto;
        }

        .checkout-screen {
            padding: 13px 13px 13px 13px !important;
        }
    }

    .applyinput input {
        width: 300px !important;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        padding-left: 10px;
        border: 1px solid rgba(0, 0, 0, 0.16) !important;
        font-weight: 600;
    }

    .applyinput input ::placeholder {
        font-weight: 500;
        color: #303031 !important;
    }

    .btn-apply {
        background: #f5a623 !important;
        color: #303031;
        font-weight: 600;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    .w-150 {
        width: 150px;
    }

    .content-left.specmeter {
        color: #fff !important;
        flex: 0 0 130px !important;
    }

    .left-flex-summary.content-left.specmeter {
        color: #fff !important;
        flex: 0 0 170px !important;
    }

    .content-right {
        color: #fff !important;
    }

    .content-right .right-div {
        color: #fff !important;
    }

    .div-content {
        flex: 0 0 293px;
    }

    .card.deposit-card {
        width: 300px !important;
        margin: 0 auto !important;
        border-radius: 12px;
        margin-top: 12px !important;
        position: absolute;
        right: 27%;
        bottom: 54px;
        z-index: 1;
    }

    .card.deposit-card .card-body {
        padding: 1rem !important;
        border-left: 0px solid #A1CAFF !important;
        margin-bottom: 5px !important;
        border-radius: 0px !important;
    }

    .card.deposit-card i {
        font-size: 17px !important;
        color: #00448b !important;
    }

    .deposit-details {
        border-radius: 8px;
        box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
        background-image: linear-gradient(to bottom, #005dae, #004fe3);
    }

    .pointerEventsNone {
        pointer-events: none !important;
    }
    .table-dd{
        height: 122px !important;
        overflow: auto !important;
        width: 150px
    }
    .copies-field {
        width: 50px;
        height:40px;
        border: 1px solid #c6c7c9 !important;
        text-align: center;
    }

    .copies-field::placeholder {
        color: #4d4f5c !important;
    }
    .inverse div{
        position: absolute;
        top: 27px;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background: #fff;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    .l-1{
        height:52px !important;
    }
    .l-2{
        height: 86px !important;
    }
    .percent-input-field,
    .amount-input-field {
        padding: 4px 10.9px 4px 10.9px;
        border-radius: 2px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border: solid 1px #abaaac; 
        box-shadow: none;
        background-color: #fff;
    }
    .percent-input-field-box,
    .amount-input-field-box {
        padding: 4px 5.3px 4px 5px;
        background-color: #656565;
        border-radius: 2px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        width: 27px;
        height: 27px;
        text-align: center;
    }
    .amount-input-field {
        border-radius: 2px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .amount-input-field-box {
        border-radius: 2px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
</style>