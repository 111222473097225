<template>
    <modal :name="modal_name" transition="nice-modal-fade" class="final-modal popup-modal-all-width-height-height-370 start-session-popup">
        <div class="v-modal-content">
            <div class="v-modal-header">
                <span class="v-modal-dialog-title v-modal-title">START CASHIER SESSION</span>
            </div>
            <div class="general-section" style="padding: 15px 0px;">
            <div class="">
                <div class="">
                    <div class="form-group mt-2 contactGroup pl-5 pr-5">
                        <label style="font-size: 14px;color: #00448b;margin-bottom: 0px!important;">
                            Cash Register ID
                            <sup>★</sup>
                        </label>
                        <v-select class="clearNone" :options="cashRegisters" v-model="selectedCashRegister.drawer_uuid" label="id" name="cash_register" v-validate="'required'">
                            <template slot="option" slot-scope="option">{{option.id}}</template>
                        </v-select>
                        <span class="text-danger fs-13" v-show="errors.has('cash_register')">{{ errors.first('cash_register') }}</span>
                        <!-- <input class="inputContact" type="text"  name="brandName" autofocus>
                        <span class="highlightContact"></span>
                        <span class="barContact"></span>-->
                    </div>
                    <div class="form-group mt-2 contactGroup pl-5 pr-5">
                        <input
                            class="inputContact"
                            type="text"
                            name="draw_in_amount"
                            v-validate="'required|numeric'"
                            v-model="selectedCashRegister.drawer_in_amount"
                            @input="drawerAmount = false"
                        />
                        <span class="highlightContact"></span>
                        <span class="barContact"></span>
                        <label class="labelContact">
                            Drawer In Amount
                            <sup>★</sup>
                        </label>
                        <span class="text-danger fs-13" v-show="errors.has('draw_in_amount')">{{ errors.first('draw_in_amount') }}</span>
                    </div>
                </div>
            </div>
            </div>
            <div class="v-modal-dialog-actions modal-bg text-center pt-5">
                <button class="btn btn-white btn-smm btn-no-border-blue text-uppercase mr-3" @click="hideSession()" :disabled="start_cashier_session_api_inprogress" style="border:1px solid #00448b;">CANCEL</button>
                <button class="btn btn-secondary btn-smm text-uppercase" :disabled="start_cashier_session_api_inprogress" @click="createCashierSession()">SUBMIT
                    <half-circle-spinner :animation-duration="1000" :size="20" v-if="start_cashier_session_api_inprogress" color="#00448b" style="position: absolute;top:6px;right:2px" />
                </button>
            </div>
        </div>
        <sweet-modal ref="success_modal" icon="success">{{modal_msg}}</sweet-modal>
        <sweet-modal ref="warning_modal" icon="warning">{{modal_msg}}</sweet-modal>
    </modal>
</template>
<script>
import { SweetModal } from 'sweet-modal-vue'

import cashierServices from './mixins/cashier'
import userServices from './mixins/user'
import { EventBus } from './eventBus/nav-bar-event'
import { HalfCircleSpinner } from "epic-spinners";
export default {
    props: ['modal_name'],
    data() {
        return {
            cashRegisters: [],
            selectedCashRegister: {
                drawer_uuid: '',
                drawer_in_amount: ''
            },
            modal_msg: '',
            start_cashier_session_api_inprogress: false
        }
    },
    mixins: [cashierServices, userServices],
    components: {
        SweetModal,
        HalfCircleSpinner
    },
    methods: {
        hideSession() {
            this.selectedCashRegister = {
                drawer_uuid: '',
                drawer_in_amount: ''
            }
            this.$modal.hide(this.modal_name)
        },
        getCashRegisters() {
            this.$http
                .post('/outlet/get_all_cash_registers_mobile', {
                    session: true
                })
                .then(res => {
                    if (res.data.status_id == 1) {
                        this.cashRegisters = res.data.response
                    }
                })
        },
        async createCashierSession() {
            try {
                this.start_cashier_session_api_inprogress = true;
                let result = await this.$validator.validateAll()
                if (result == true) {
                    let params = {
                        drawer_in_amount: this.selectedCashRegister
                            .drawer_in_amount,
                        drawer_uuid: this.selectedCashRegister.drawer_uuid.uuid
                    }
                    let startSessionResponse = await this.startSession(params)
                    this.modal_msg = 'Successfully Started Cashier Session.'
                    this.$refs.success_modal.open()
                    EventBus.$emit('updateBills')
                    EventBus.$emit('updateHomeScreendata')
                    setTimeout(async () => {
                        let response = await this.validateUser()
                        let userDetails = response.user_details
                        this.$store.commit('setLoggedInUser', {
                            access_change_discount: userDetails.access_change_discount,
                            access_remove_item: userDetails.access_remove_item,
                            auto_logout: userDetails.auto_logout,
                            auto_logout_mins: userDetails.auto_logout_mins,
                            hide_row_with_value_zero: userDetails.hide_row_with_value_zero || false,
                            show_place_order_in_checkout: userDetails.show_place_order_in_checkout || false,
                            print_receipt_by_default: userDetails.print_receipt_by_default || false,
                            uid: userDetails.user_id,
                            full_name: userDetails.full_name,
                            email: userDetails.email,
                            profile_pic: userDetails.profile_pic,
                            actions: userDetails.actions,
                            is_setup_done: userDetails.is_setup_done,
                            isAdmin: userDetails.user_role === 'default_admin',
                            is_authorized: userDetails.is_authorized,
                            is_loaded: true,
                            has_order: userDetails.has_order,
                            outlet: userDetails.outlet,
                            business_day_start_time:
                            userDetails.business_day_start_time,
                            role: userDetails.user_role,
                            outlet_type: userDetails.outlet_type,
                            pin: userDetails.pin,
                            is_cashier: userDetails.is_cashier,
                            cashier_session: userDetails.cashier_session || null,
                            service_type: userDetails.service_type || null,
                            enable_fast_checkout: userDetails.enable_fast_checkout,
                            enable_double_receipts: userDetails.enable_double_receipts,
                            enable_course_firing: userDetails.enable_course_firing,
                            default_deliver_together: userDetails.default_deliver_together,
                            outlet_id: userDetails.outlet_id,
                            configure_payment_device:userDetails.configure_payment_device,
                        })
                        this.$refs.success_modal.close()
                        this.hideSession()
                        this.$emit('showcashierstart', 1)
                        this.start_cashier_session_api_inprogress = false;
                    }, 2000)
                }
            } catch (reason) {
                this.modal_msg = reason
                this.$refs.warning_modal.open()
                this.start_cashier_session_api_inprogress = false;
            }
        }
    },
    created() {
        const dict = {
            custom: {
                draw_in_amount: {
                    required: () => 'Draw in Amount is Required',
                    numeric: () => 'Enter a Valid Drawer in Amount'
                },
                cash_register: {
                    required: () => 'Select a Cash Register'
                }
            }
        }
        this.$validator.localize('en', dict)
    },
    destroyed() {
        // important to clean up!!
        document.removeEventListener('click', this.documentClick)
    },

    mounted() {
        if (this.$cookie.get('Token')) {
            this.getCashRegisters()
        }
    },
    computed: {
        loggedInUser() {
            return this.$store.getters.loggedInUser
        }
    }
}
</script>
