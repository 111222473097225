export default {
    methods: {
        async createSetting(param) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post("/outlet/store_settings", param)
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.message)
                }
            })
        },
        async updateSetting(param) {            
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post("/outlet/settings_update/"+param._id, param)
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {                   
                    reject(response.data.response)
                }
            })
        },
    }
}